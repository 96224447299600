import './favicon';
import React, { Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import routes from 'data/routes.json';

import { fetchLoginState } from './redux/actions';
import { GET_CONSENT } from './redux/actions/GDPRTypes';
import LoadingIndicatorAtom from 'components/atoms/LoadingIndicator/Default';
import ErrorBoundaryOrganism from 'components/organisms/Common/ErrorBoundary';

import NotificationsOrganism from 'components/organisms/Common/Notifications/View';
import OutdatedBrowserAtom from './components/atoms/Notifications/OutdatedBrowser';

import PublicRoute from './components/_routes/PublicRoute';
import RestrictedRoute from 'components/_routes/RestrictedRoute';
import PrivateRoute from 'components/_routes/PrivateRoute';
import AdminRoute from 'components/_routes/AdminRoute';
import AdminRoleRoute from 'components/_routes/AdminRoleRoute';
import ContributorRoleRoute from 'components/_routes/ContributorRoleRoute';
import CookieConsent from './components/atoms/CookieConsent';
import {
    AccountSelection,
    AddKYCDocument,
    AddDocument,
    EditDocument,
    AddRequest,
    AddPortalAccount,
    AssetMix,
    AssetMixNew,
    Dashboard,
    DashboardAdmin,
    Disclaimer,
    DocumentInformation,
    DocumentManagement,
    DocumentStore,
    ForgotPassword,
    ResetPassword,
    FundsList,
    FundDetails,
    LdiTool,
    Login,
    NoClients,
    NotFound,
    NotificationManagement,
    Process,
    Performance,
    PortalAccountDetail,
    PortalAccounts,
    // PreferenceCentre,
    SamlLogin,
    Security,
    Settings,
    StatementOfChanges,
    Transactions,
    UserDetail,
    UserManagement,
    CoverageRatio,
    DownloadPage,
    CollateralData,
    WholesaleMasterlist,
    RequestBulkKYC,
    AltCreditDashboard,
    ICDemoDashboard,
    PasswordUpdate,

} from 'components/pages';
import redirection from './components/_helpers/redirection';
import createPageName from './components/_helpers/analyticsHelperFunctions';
import { AdobeAnalyticsConfig } from './components/atoms/AdobeAnalytics/AdobeAnalyticsConfigure';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
const propTypes = {
    history: PropTypes.object,
    auth: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
};


class App extends React.Component {
    constructor() {
        super();

        this.state = { isOutdatedBrowser: window.sessionStorage.getItem('isIE11'), currentLocation: '', locationChanged: false };
    }

    componentDidMount() {
        redirection();
        const { pathname } = document.location;
        this.props.dispatch(fetchLoginState({ pathname }));
        this.props.dispatch(GET_CONSENT());
    }

    render() {
        const { auth, gdprconsent } = this.props;
        const urlSplit = window.location.href.replace(/\?.*$/g, "").split('/').filter(Boolean);
        const isLoginPage = urlSplit.length === 2 || (urlSplit.length > 2 && urlSplit[2].toLowerCase() === 'login');
        const isUserLoggedIn = !!auth?.user?.sub

        if (!auth.isPrepared) {
            return (
                <div className="wrapper">
                    <LoadingIndicatorAtom />
                </div>
            );
        }

        const hrefSplit = window.location.href.split("/")[3]?.replace(/\?.*$/g, "") //to remove query params

        const data = {
            siteInfo: {
                siteCountry: 'int',
                siteAudience: 'institutions',
                siteLanguage: 'en',
                siteName: 'mygsam',
            },
            pageInfo: {
                pageName: createPageName(window.location.href),
                sectionLevelOne: isUserLoggedIn ? hrefSplit.toLowerCase() : urlSplit.length === 2 ? "login" : hrefSplit.toLowerCase(),
                pageType: isUserLoggedIn ? hrefSplit.toLowerCase() : urlSplit.length === 2 ? "login" : hrefSplit.toLowerCase(), //populate same as pageTitle
                pageSlug: isUserLoggedIn ? hrefSplit.toLowerCase() : urlSplit.length === 2 ? "login" : hrefSplit.toLowerCase(),
                pageOverline: 'not applicable',// what should be the value for us
                isErrorPage: false
            },
            userInfo: {
                loginStatus: isUserLoggedIn ? 'logged in' : 'not logged in', // 'logged in' or 'logged off'
                ipAddress: "", // How do we get this?
                guid: auth?.user?.sub || '1234567891011121314',
                internalOrExternal: auth.user?.preferred_username?.toLowerCase().includes('@insim.biz') ? 'internal' : isUserLoggedIn ? "external" : "",
                munchkinCookie: ""
            }
        };

      

        console.log("apppzz", auth)
        return (
            <div className={`wrapper ${isLoginPage && 'pl-0'}`}>
                <ErrorBoundaryOrganism>
                    <AdobeAnalyticsConfig data={data} locationChanged={this.state.locationChanged} />
                    <Suspense fallback={<LoadingIndicatorAtom />}>
                        <Switch>
                            <Route auth={auth} path={routes.saml.path} component={SamlLogin} />
                            <PublicRoute {...routes.disclaimer} component={Disclaimer} />
                            <PublicRoute {...routes.security} component={Security} />
                            <PublicRoute {...routes.download} component={DownloadPage} />

                            <RestrictedRoute exact auth={auth} {...routes.login} path="/" component={Login} />
                            <RestrictedRoute auth={auth} {...routes.login} component={Login} />
                            <PublicRoute auth={auth} {...routes.forgotPassword} component={ForgotPassword} />
                            <PublicRoute auth={auth}  {...routes.passwordUpdate} component={PasswordUpdate} />
                            <RestrictedRoute auth={auth} {...routes.resetPassword} component={ResetPassword} />
                            <AdminRoute auth={auth} {...routes.admin} component={DashboardAdmin} />

                            <AdminRoleRoute auth={auth} {...routes.userDetail} component={UserDetail} />
                            <AdminRoleRoute auth={auth} {...routes.portalAccounts} component={PortalAccounts} />
                            <AdminRoleRoute auth={auth} {...routes.userManagement} component={UserManagement} />
                            <AdminRoleRoute auth={auth} {...routes.addPortalAccount} component={AddPortalAccount} />
                            <AdminRoleRoute
                                auth={auth}
                                {...routes.portalAccountDetail}
                                component={PortalAccountDetail}
                            />

                            <ContributorRoleRoute auth={auth} {...routes.addDocument} component={AddDocument} />
                            <ContributorRoleRoute auth={auth} {...routes.editDocument} component={EditDocument} />
                            <ContributorRoleRoute auth={auth} {...routes.addRequest} component={AddRequest} />

                            <AdminRoleRoute auth={auth} {...routes.addKYCDocument} component={AddKYCDocument} />
                            <AdminRoleRoute auth={auth} {...routes.bulkKYCRequest} component={RequestBulkKYC} />
                            <ContributorRoleRoute
                                auth={auth}
                                {...routes.documentManagementInfo}
                                component={DocumentInformation}
                            />
                            <ContributorRoleRoute
                                auth={auth}
                                {...routes.documentManagement}
                                component={DocumentManagement}
                            />

                            <PrivateRoute auth={auth} {...routes.clientSettings} component={Settings} />
                            <PrivateRoute
                                auth={auth}
                                {...routes.clientNotifications}
                                component={NotificationManagement}
                            />
                            {/* <PrivateRoute auth={auth} {...routes.clientPreferenceCentre} component={PreferenceCentre} /> */}

                            <AdminRoute auth={auth} {...routes.settings} component={Settings} />
                            <AdminRoute auth={auth} {...routes.notifications} component={NotificationManagement} />
                            {/* <AdminRoute auth={auth} {...routes.preferenceCentre} component={PreferenceCentre} /> */}

                            <PrivateRoute auth={auth} {...routes.security} component={Security} />
                            <PrivateRoute auth={auth} {...routes.disclaimer} component={Disclaimer} />

                            <PrivateRoute auth={auth} {...routes.noClients} component={NoClients} />
                            <PrivateRoute auth={auth} {...routes.accountSelection} component={AccountSelection} />
                            <PrivateRoute auth={auth} {...routes.dashboard} component={Dashboard} />
                            <PrivateRoute auth={auth} {...routes.placeholder} component={AssetMix} />
                            <PrivateRoute auth={auth} {...routes.AltCreditDashboard} component={AltCreditDashboard} />
                            <PrivateRoute auth={auth} {...routes.IcDemoDashboard} component={ICDemoDashboard} />
                            <PrivateRoute auth={auth} {...routes.assetMix} component={AssetMixNew} />
                            <PrivateRoute auth={auth} {...routes.fundDetails} component={FundDetails} />
                            <PrivateRoute auth={auth} {...routes.myFunds} component={FundsList} />
                            <PrivateRoute auth={auth} {...routes.ldiTool} private component={LdiTool} />
                            <PrivateRoute auth={auth} {...routes.documentStore} exact component={DocumentStore} />
                            <PrivateRoute auth={auth} {...routes.documentInfo} component={DocumentInformation} />
                            <PrivateRoute auth={auth} {...routes.transactions} component={Transactions} />
                            <PrivateRoute auth={auth} {...routes.performance} component={Performance} />
                            <PrivateRoute auth={auth} {...routes.statementOfChanges} component={StatementOfChanges} />
                            <PrivateRoute auth={auth} {...routes.coverageRatio} component={CoverageRatio} />
                            <PrivateRoute auth={auth} {...routes.bulkKYCRequest} component={RequestBulkKYC} />
                            <PrivateRoute auth={auth} {...routes.collateralData} component={CollateralData} />
                            <PrivateRoute auth={auth} {...routes.wholesaleMasterlist} component={WholesaleMasterlist} />
                            <PrivateRoute auth={auth} {...routes.process} component={Process} />

                            <PublicRoute {...routes.notFound} component={NotFound} />
                        </Switch>
                    </Suspense>
                    <NotificationsOrganism />
                    {this.state.isOutdatedBrowser && <OutdatedBrowserAtom />}
                </ErrorBoundaryOrganism>
            </div>
        );
    }
}

App.propTypes = propTypes;

function select({ auth, gdpr }) {
    return {
        auth,
        gdprconsent: gdpr.gaveGDPRConsent,
    };
}

export default withRouter(connect(select)(App));
