// Base
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import menuItems from 'data/menubottom.json';

// Utils
import isInternal from 'components/_helpers/isInternal';

// Style
import style from 'assets/stylesheets/molecules/Menus/Bottom';

const propTypes = {
    clientId: PropTypes.string,
    menuItems: PropTypes.array,
};

class BottomMenu extends React.Component {
    render() {
        const { clientId } = this.props;

        let bottommenu = menuItems.map((value, index) => {
            let linkPath = clientId ? '/' + value.url + '/' + clientId : '/' + value.url,
                currentPath = window.location.pathname;

            return (
                <li className={style.bottommenu__item} key={index}>
                    {isInternal(value.url) ? (
                        <Link
                            to={linkPath}
                            className={`${style.bottommenu__item__link}${
                                linkPath === currentPath ? ' ' + style.bottommenu__item__link__active : ''
                            }`}>
                            {value.label}
                        </Link>
                    ) : (
                        <a
                            href={value.url}
                            className={`${style.bottommenu__item__link}`}
                            target="_blank"
                            rel="noreferrer">
                            {value.label}
                        </a>
                    )}
                </li>
            );
        });

        return (
            <nav className={style.bottommenu}>
                <ul>{bottommenu}</ul>
            </nav>
        );
    }
}

BottomMenu.propTypes = propTypes;

export default BottomMenu;
