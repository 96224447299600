
import dictionary from 'data/dictionary.json';
const { funds } = dictionary;
const { sortingMsg } = funds;

const defaultFundSorting = [
    {
        'direction': true,
        'selected': true,
        'label': sortingMsg.assetClass,
        'sortBy': 'assetClass'
    },
    {
        'direction': false,
        'selected': false,
        'label': sortingMsg.fundName,
        'sortBy': 'fundName'
    },
    // {
    //     'direction': false,
    //     'selected': false,
    //     'label': sortingMsg.ytd,
    //     'sortBy': 'apytd'
    // }
];

export default defaultFundSorting;