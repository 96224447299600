import http from './httpService';
import settings from '@/settings';

const notificationsApiUrl = settings.DOMAIN + '/csp-notification-service-api/v1/notification';
const fundsNotificationsApiUrl = settings.DOMAIN + '/csp-fund-documents-api/v1/notifications-config';

export const retrieveActiveWidgetNotifications = (userId) => http.post(notificationsApiUrl, { userId: userId }).then(response => response.data);
export const updateSelectedWidgetNotifications = (userId, body) => http.patch(notificationsApiUrl, { ...body, userId: userId }).then(response => response.data);

export const retrieveFundsNotifications = (userId) => http.post(fundsNotificationsApiUrl, { userId: userId }).then(response => response.data);
export const updateFundsNotifications = (userId, body) => http.patch(fundsNotificationsApiUrl, { ...body, userId: userId }).then(response => response.data);
