import classNames from 'classnames';
import React, { createContext, useContext, useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import { GIVE_CONSENT } from '../../../redux/actions/GDPRTypes';
import { useGeoLocation, useGetIsCrawler } from 'src/components/_helpers/hooks/CookiConsent';

const CookieConsentContext = createContext();

const useCookieConsentContext = () => {
    const context = useContext(CookieConsentContext);
    if (!context) {
        throw new Error('CookieConsent(.Script|.Banner|.Modal) components cannot be rendered outside the Cookie Consent component');
    }
    return context;
};

const CookieConsent = ({
    children,
    bannerBarId = 'consent_blackbar',
    consentId = 'teconsent',
    gaveGDPRConsent,
    handleGiveConsent,
    hasFunctionalGDPRConsent,
    hasFullGDPRConsent,
    containerType = 'section',
}) => {
    const ref = useRef(null);
    const buttonIds = ['truste-consent-button', 'truste-consent-required', 'truste-show-consent'];
    const isCrawler = useGetIsCrawler();
    const showBanner = !hasFunctionalGDPRConsent && !hasFullGDPRConsent && !isCrawler;
    const { country = 'NL', language = 'nl' } = useGeoLocation();
    const ComponentContainer = `${containerType}`;

    useEffect(() => {
        const buttons = ref?.current;
        buttons?.addEventListener('click', ({ target: { id = '' } }) => {
            const hasButtonIds = buttonIds.some((buttonId) => {
                return buttonId === id;
            });
            if (!hasButtonIds) {
                return;
            }
            const payload = { hasFullConsent: Boolean(id === 'truste-consent-button') };
            handleGiveConsent(payload);
        });
        return () => {
            buttons?.removeEventListener('click', null);
        };
    }, []);

    const contextValues = useMemo(() => {
        return {
            country,
            language,
            bannerBarId,
            consentId,
            showBanner,
        };
    }, [hasFunctionalGDPRConsent, hasFullGDPRConsent, country, language, bannerBarId, consentId, gaveGDPRConsent, showBanner]);

    return (
        <ComponentContainer
            ref={ref}
            className={classNames({ 'd-none': false }, { 'linkList__item navlink h6': containerType === 'li' })}
        >
            <CookieConsentContext.Provider value={contextValues}>{children}</CookieConsentContext.Provider>
        </ComponentContainer>
    );
};

const CookieBanner = () => {
    const { bannerBarId, showBanner = true } = useCookieConsentContext();
    return showBanner ? <div id={bannerBarId} /> : null;
};

const CookieModal = () => {
    const { consentId } = useCookieConsentContext();

    return <div id={consentId} />;
};

CookieConsent.Banner = CookieBanner;
CookieConsent.Modal = CookieModal;

const mapDispatchToProps = (dispatch) => {
    return {
        handleGiveConsent: (payload) => {
            return dispatch({
                type: GIVE_CONSENT,
                payload,
            });
        },
    };
};

const mapStateToProps = ({ gdpr: { gaveGDPRConsent, hasFullGDPRConsent, hasFunctionalGDPRConsent } }) => {
    return {
        gaveGDPRConsent,
        hasFullGDPRConsent,
        hasFunctionalGDPRConsent,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CookieConsent);
