import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import HelmetHead from '../molecules/Head/HelmetHead';

const RestrictedRoute = ({ component: Component, auth, title, ...rest }) => {
    const {
        isLoggedIn,
        user: { email, clientId, multipleClients, isAdmin },
    } = auth;
    const isNewPasswordSet = sessionStorage.getItem("newpasswordset");

    // logic to check for maximum number of days
    return (
        <Route
            {...rest}
            render={(props) => {
                const isInternalUserEmail = auth.user?.preferred_username?.toLowerCase().includes('@insim.biz')
                const forceReset = (auth.user.maxLoginDays > 90 || auth.user.maxLoginDays === undefined || auth.user.maxLoginDays === null || isNaN(auth.user.maxLoginDays)) && auth?.isLoggedIn && !isInternalUserEmail && !isNewPasswordSet;
                if (forceReset) {
                    return <Redirect to="/force-reset" />;
                }
                else if (isLoggedIn) {
                    if (isAdmin) {
                        return <Redirect to="/admin" />;
                    }

                    if (multipleClients) {
                        return <Redirect to="/select-client" />;
                    }

                    if (clientId) {
                        return <Redirect to={`/dashboard/${clientId}`} />;
                    }

                    return <Redirect to="/no-clients" />;
                }

                return (
                    <>
                        <HelmetHead title={title} />
                        <Component {...props} />
                    </>
                );
            }}
        />
    );
};

export default RestrictedRoute;
