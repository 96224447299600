/* eslint-disable no-unused-vars */
/* eslint-disable no-console */

import http from './httpService';
import settings from '@/settings';

const clientApiUrl = settings.DOMAIN + '/csp-accounts-api/v1/client-accounts';
const clientAdminApiUrl = settings.DOMAIN + '/management/csp-accounts-api/v1/client-accounts';
const clientMgmtAPIUrl = settings.DOMAIN + '/management/csp-accounts-api/v1/client-accounts';

export const retrieveClient = (id) => http.post(`${clientApiUrl}/getClientAccount`, { clientAccountId: id }).then(response => response.data);
export const updateWidgetEnabled = (id, widgetCode, enabled, widgetProperty) => http.patch(`${clientAdminApiUrl}/widgets/widgetCode`, { enabled, clientAccountId: id, widgetProperty, widgetCode: widgetCode });
export const updateWidgetInternalOnly = (id, widgetCode, internalOnly) => http.patch(`${clientAdminApiUrl}/widgets/widgetCode`, { 'updateForInternalUsers': '', internalOnly, clientAccountId: id, widgetCode: widgetCode });
//export const retrieveEnabledWidgets = (id) => http.get(`${clientApiUrl}/${id}/widgets`);

//below function is to retrieve widget info in dashboard page (for external user)
// once the BED implments the POST method just change the method to post and use the Body as is as parameter
export const retrieveEnabledWidgets = (body) => {
    return http.post(`${clientApiUrl}/getClientAccountWidgets`, body);
};

//below function is to retrieve widget info in portal accounts page (for internal user)
export const retrievePortalAccountWidgets = (id) =>
    http.post(`${clientAdminApiUrl}/widgets`, { clientAccountId: id });

