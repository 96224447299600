export function sortByKey(data, keyName) {
    if(!Array.isArray(data)) return [];
    return data.sort((itemA, itemB) => {
        const nameA = itemA[keyName].toUpperCase();
        const nameB = itemB[keyName].toUpperCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }

        return 0;
    });
}
