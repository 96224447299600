/* eslint-disable no-unused-vars */
/* eslint-disable no-console */

import axios from 'axios';
import http from './httpService';
import settings from '../settings';

const retrieveUploadUrlsApiUrl = settings.DOMAIN + '/csp-doc-management-api/v1/upload/generate-links';
const uploadMetadataApiUrl = settings.DOMAIN + '/csp-doc-management-api/v1/upload/single-document';
const uploadKYCMetadataApiUrl = settings.DOMAIN + '/csp-doc-management-api/v1/upload/kyc-template';
const uploadEditedKYCMetadataApiUrl = settings.DOMAIN + '/csp-doc-management-api/v1/upload/kyc-template/';
const uploadFileStatusApiUrl = settings.DOMAIN + '/csp-doc-management-api/v1/upload/file-status';
export const retrieveUploadUrls = (body) => http.post(retrieveUploadUrlsApiUrl, body).then(response => response.data);
export const uploadDocument = (body, url) => axios.put(
    url,
    body,
    { headers: { 'Content-Type': 'application/octet-stream', 'x-amz-server-side-encryption': 'AES256' } });

export const uploadMetadata = (body, clientId) => http.post(uploadMetadataApiUrl, { ...body, portalAccountId: clientId });
export const uploadKYCMetadata = (body, clientId) => http.post(`${uploadKYCMetadataApiUrl}${clientId ? `?clientId=${clientId}` : ''}`, body);
export const uploadEditedKYCMetadataApi = (body, documentID) => http.patch(`${uploadEditedKYCMetadataApiUrl}${documentID}`, body);
export const scanDocument = (body) => http.post(uploadFileStatusApiUrl, body).then(response => response).catch(error => 'error');



