export default function partitionArray(arr, condition) {
    return [
        arr.filter(function (item) {
            return condition(item);
        }),
        arr.filter(function (item) {
            return !condition(item);
        }),
    ];
}
