import { createAction } from 'redux-act';

// Manage display of notifications in toast messages
export const displayNotification = createAction('Display notification');
export const dismissNotification = createAction('Dismiss notification');
export const fadeoutNotification = createAction('Fadeout notification');


// Manage notifications settings for a user
export const receiveActiveWidgetNotifications = createAction('Get active widget notifications');
export const updateActiveWidgetNotifications = createAction('Set active widget notifications');

export const receiveFundNotifications = createAction('Get fund notifications');
export const updateFundNotifications = createAction('Set fund notifications');
