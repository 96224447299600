import { createAction } from 'redux-act';

// User actions
export const fetchUserDetails = createAction('Fetch user details');
export const fetchUserAvatar = createAction('Fetch user avatar');
export const updateUserAvatar = createAction('Update user avatar');
export const generateUploadAvatarLinks = createAction('Generate avatar upload links')

// User management actions
export const fetchAllUsers = createAction('Fetch all users details');
export const fetchInternalUsers = createAction('Fetch internal users details');
export const handlePatchUserStatus = createAction('Handle patch user status');

// Preferences
export const fetchPreferenceData = createAction('Fetch preference categories and user preferences');
export const saveUserPreferences = createAction('Save user preferences');

// Portal account actions
export const fetchPortalAccounts = createAction('Fetch portal accounts');
export const fetchPortalAccountWidgets = createAction('Fetch portal account widgets');
export const fetchEnabledWidgets = createAction('Fetch enabled widgets');
export const updateWidgetEnabled = createAction('Update widget enabled');
export const updateWidgetInternalOnly = createAction('Update widget for internal use');
export const fetchCurrentClient = createAction('fetch Current client based on clientId');

// add new portal account actions
export const fetchSalesforceAccountDetailsClient = createAction('Fetch salesforce account data for client');
export const fetchSalesforceAccountDetailsProspect = createAction('Fetch salesforce account data for prospect');

// Document actions
export const fetchDocuments = createAction('Fetch documents');
export const fetchKYCDocuments = createAction('Fetch KYC documents');
export const fetchDocumentsWithCategories = createAction('Fetch documents with categories');
export const fetchDocumentsCategories = createAction('Fetch categories');
export const downloadDocuments = createAction('Get download link for all files in a document or a group of documents');
export const downloadFiles = createAction('Get download link for a file or a group of files in a document');
export const deleteDocuments = createAction('Delete documents');
export const revokeDocumentAccess = createAction('Revoke document access');
export const sendManualEmailReminder = createAction('Send manual email reminder or summary');
export const reorderedRequests = createAction('Submit new order for document requestes');

// Document information and request sagas actions
export const fetchDocumentInformation = createAction('Fetch document information details');
// export const fetchDocumentDisplayURL = createAction('Fetch document display information details');
// export const fetchRequestedDocURL = createAction('Fetch requested document display information details');
export const fetchFullDocumentInformation = createAction('Fetch full document information details and access list');
export const editDocumentInformation = createAction('Save and update document information');
export const uploadDocumentRevision = createAction('Upload document request revision');
export const generateRequestUploadLinks = createAction('Generate upload links');
export const uploadRequestedDoc = createAction('Upload documents to request');
export const deleteUploadedFile = createAction('Delete uploaded files from request');

// Notifications actions
export const fetchActiveWidgetNotifications = createAction('Fetch active widget notifications');
export const updateWidgetNotifications = createAction('Set widget notifications status');
export const fetchFundsNotifications = createAction('Fetch fund notifications settings');
export const updateFundsNotifications = createAction('Set widget notifications status');

// Contentful actions
// TODO: Why are there actions in two different locations?
export const getVideoContent = createAction('Get video card content');
export const getAllVideosIds = createAction('Get all videos Ids');
export const getOnePagerContent = createAction('Get one pager details');
export const getfetchGetInTouch = createAction('Get contact details details');

// Process
export const fetchProcessDocuments = createAction('Fetch process documents');
export const fetchProcessDetails = createAction('Fetch process details');
export const fetchActiveProcessDetails = createAction('Fetch process details');
export const uploadProcess = createAction('Save process or transition details');

// Funds
export const fetchMyFunds = createAction('Fetch my funds');
export const fetchAllFunds = createAction('Fetch all client funds');
export const fetchMyWatchlist = createAction('Fetch my watchlist');
export const addFundToWatchlist = createAction('Add fund to watchlist');
export const removeFundFromWatchlist = createAction('Remove fund from watchlist');
export const grantMyFundsAccess = createAction('Update my funds list');
export const fetchFundDetails = createAction('Fetch fund details');
export const fetchAltisFundDetails = createAction('Fetch Altis fund details');
export const fetchFundFilters = createAction('Fetch fund filters');
export const fetchWholesaleMasterlist = createAction('Fetch wholesale masterlist');
