// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".notifications-wrapper--awTNtDUv{padding:.5rem;position:fixed;right:0;bottom:3rem;left:0;z-index:850}", "",{"version":3,"sources":["webpack://./src/assets/stylesheets/atoms/Notifications/NotificationsWrapper.scss"],"names":[],"mappings":"AAEA,iCACI,aAAA,CACA,cAAA,CACA,OAAA,CACA,WAAA,CACA,MAAA,CACA,WAAA","sourcesContent":["@import '../../_helpers/variables';\n\n.notifications-wrapper {\n    padding: 0.5rem;\n    position: fixed;\n    right: 0;\n    bottom: 3rem;\n    left: 0;\n    z-index: 850;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notifications-wrapper": "notifications-wrapper--awTNtDUv"
};
export default ___CSS_LOADER_EXPORT___;
