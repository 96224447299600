import { useEffect } from 'react';
import createPageName, { findPageTitle } from '../../_helpers/analyticsHelperFunctions';


export const AdobeAnalyticsConfig = (props) => {
    
    const { data } = props;
    let newPageInfo = data?.pageInfo;

    //to avoid event to get trigger for nnip domain
    if(window.location.host.includes('nnip.com')) return null

    //to avoid multiple event trigger for same page upto some extend
    if(window?.adobeDataLayer?.[0]?.pageInfo?.pageName === createPageName(window.location.href)) {
        return null
    }

    newPageInfo.pageTitle = findPageTitle(window.location.href);

    if(window?.adobeDataLayer?.length) window.adobeDataLayer.length = 0;

    if(window.location.href.split("/")?.[3].replace(/\?.*$/g, "") === 'reset-password'){
        setTimeout(() =>{
            window?.adobeDataLayer?.push({
                event: 'pageView',
                siteInfo: data?.siteInfo,
                pageInfo: newPageInfo,
                userInfo: data?.userInfo
            })
        },1000)
    } else {
        window?.adobeDataLayer?.push({
            event: 'pageView',
            siteInfo: data?.siteInfo,
            pageInfo: newPageInfo,
            userInfo: data?.userInfo
        })
    }
    

    return null;
};
