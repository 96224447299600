import { createReducer } from 'redux-act';
import states from 'data/reduxstates';
import { fetchFundDetails } from '../sagas/api/actions';

import {
    receiveFundDetails,
} from '@/redux/actions/index';

const documentMapping = {
    PRIIPSKID: 'PRIIPS KID',
    PD: 'Periodic Disclosure',
    WD: 'Website Disclosure',
};

const fundDetailsReducer = createReducer(
    {
        [fetchFundDetails]: (state) => ({ ...state, loadingStatus: true }),
        [receiveFundDetails]: (state, payload) => {
            const { fund, fundDocuments: { list = [], totalCount = 0 } } = payload;
            const docOrder = ['fact sheet', 'strategy brief', 'annual report', 'commentary', 'prospectus', 'kiid', 'priipskid', 'pcr', 'pcd', 'wd', 'pd'];
            const docList = list.map(doc => {
                if (documentMapping[doc.documentType])
                    doc.categoryName = documentMapping[doc.documentType];
                else
                    doc.categoryName = doc.documentType.replace(/_/g, ' ');
                return doc;
            });
            const sortFilters = (unsorted) => {
                const sorted = unsorted.sort(
                    (a, b) =>
                        docOrder.indexOf(a.documentType.toLowerCase()) -
                        docOrder.indexOf(b.documentType.toLowerCase())
                );
                return sorted;
            };
            return { ...state, fund, fundDocuments: sortFilters(docList), totalCount, loadingStatus: false };
        },

    },
    states.fundDetails
);

export default fundDetailsReducer;
