import { createReducer } from 'redux-act';
import states from 'data/reduxstates';
import { receiveUserDetails, receiveUserAvatar, scannedAvatar, clearScannedAvatarDataAfterUpload, avatarUploadFailed } from '../actions/user';
import { assoc } from 'ramda';

const userReducer = createReducer({
    [receiveUserDetails]: (state, userDetails) => assoc('details', userDetails, state),
    [receiveUserAvatar]: (state, avatar) => assoc('avatar', avatar, state),
    [scannedAvatar]: (state, payload) => {
        return {
            ...state,
            correctFileData: payload.correctFileData,
            correctFileWithKey: payload.correctFileWithKey,
            malwareData: payload.malwareData.map(obj => obj.filename),
            apiError: payload.apiErrorData.length > 0 ? true : false,
            timeoutError: payload.timeoutError.length > 0 ? true : false
        };
    },
    [clearScannedAvatarDataAfterUpload]: (state) => {
        return {
            ...state,
            correctFileData: [],
            correctFileWithKey: [],
            malwareData: [],
            apiError: false,
            timeoutError: false
        }
    },
    [avatarUploadFailed] : (state) => {
        return {
            ...state,
            correctFileData: [],
            correctFileWithKey: [],
            malwareData: [],
            apiError: true,
            timeoutError: false
        }
    }
}, states.user);

export default userReducer;