// Base
import React from 'react';
import PropTypes from 'prop-types';

// Style
import style from 'assets/stylesheets/templates/Article';

// Components
import SimpleHeaderAtom from 'components/atoms/Headers/SimpleHeader';
import HeadingAtom from 'components/atoms/Headings/Default';
import FooterMolecule from 'components/molecules/Scaffolding/Footer';

const propTypes = {
    pageType: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    pageTitle: PropTypes.string,
    subPageTitle: PropTypes.string,
    authObject: PropTypes.object,
};

class Article extends React.Component {
    render() {
        const { pageType, pageTitle, subTitle, subPageTitle, variant, subVarient } = this.props;

        return (
            <div className={style.article}>
                <div id="article__container">
                    <SimpleHeaderAtom />
                    <main className={style.article__main}>
                        <HeadingAtom tag="h1" variant={variant} subVarient={subVarient}>
                            {pageTitle}
                        </HeadingAtom>
                        {subTitle && (
                            <HeadingAtom tag="h1" variant={variant} subVarient={subVarient} customClass="purple">
                                {subTitle}
                            </HeadingAtom>
                        )}
                        {subPageTitle && <h2>{subPageTitle}</h2>}
                        {this.props.children}
                    </main>
                </div>
                <FooterMolecule variant="selector" />
            </div>
        );
    }
}

Article.propTypes = propTypes;

export default Article;
