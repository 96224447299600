import { createReducer } from 'redux-act';
import states from 'data/reduxstates';
import {
    displayNotification,
    dismissNotification,
    fadeoutNotification
} from '../actions/notifications';

const notificationReducer = createReducer({
    [displayNotification]: (state, payload) => {
        return { ...state,activeNotifications: [...state.activeNotifications, payload] };
    },
    [dismissNotification]: (state, payload) => {
        const active = state.activeNotifications.filter(n => n.id !== payload.id);
        return { ...state, activeNotifications: active };
    },
    [fadeoutNotification]: (state, payload) => {
        const active = state.activeNotifications.map(n => {
            if(n.id === payload.id) n.remove = true;
            return n;
        });
        return { ...state, activeNotifications: active };
    }
}, states.notifications);

export default notificationReducer;