import { fork, all } from 'redux-saga/effects';
import {
    handleGenerateUploadLinks,
    handleAddDocumentUpload,
    handleKYCGenerateUploadLinks,
    handleAddKYCDocumentUpload,
} from './AddDocument';
import { handleAddRequestUpload, handleSubmitBulkKYCRequest, handleUploadFiles } from './AddRequest';
import { putNewPortalAccount, handleGenerateUploadPortalAvatarLinks } from './AddPortalAccount';
import apiSagas from './Api';
import authSagas from './Auth';
import gdprSagas from './gdpr';
import commonSagas from './common';
// import analyticsSagas from './AnalyticsSagas';

export default function* rootSaga() {
    yield all([
        fork(authSagas),
        fork(gdprSagas),
        fork(handleGenerateUploadLinks),
        fork(handleKYCGenerateUploadLinks),
        fork(handleUploadFiles),
        fork(handleAddKYCDocumentUpload),
        fork(handleSubmitBulkKYCRequest),
        fork(handleAddDocumentUpload),
        fork(handleAddRequestUpload),
        fork(putNewPortalAccount),
        fork(apiSagas),
        fork(commonSagas),
        fork(handleGenerateUploadPortalAvatarLinks)
        // fork(analyticsSagas),
    ]);
}
