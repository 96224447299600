import { createReducer } from 'redux-act';
import states from 'data/reduxstates';
import { fetchAltisFundDetails } from '../sagas/api/actions';

import {
    receiveAltisFundDetails,
} from '@/redux/actions/index';

const fundDetailsAltisReducer = createReducer(
    {
        [fetchAltisFundDetails]: (state) => ({ ...state, loadingStatus: true }),
        [receiveAltisFundDetails]: (state, payload) => {
            const { fund } = payload;
            return { ...state, fund, loadingStatus: false };
        },

    },
    states.fundDetailsAltis
);

export default fundDetailsAltisReducer;
