import { useMemo, useState, useEffect } from 'react';

const useGeoLocation = () => {
    const [geoLocale, setGeoLocale] = useState('en-INT');
    const [language = '', country = ''] = geoLocale?.split('-') || [];

    const fetchGeolocation = async () => {
        try {
            const pattern = /[a-z]{2}-[A-Z]{2}/;
            const locale = 'en-GB';
            if (pattern.test(locale)) {
                setGeoLocale('en-GB');
            }
        } catch ({ message }) {
            console.warn(message);
        }
    };

    useMemo(() => {
        // const { geoLocationEndpoint = 'https://web-api.nnip.com/geolocation' } = window?.config || {};
        fetchGeolocation();
    }, []);

    return { locale: geoLocale, country, language };
};

const useGetIsCrawler = () => {
    const [isCrawler, setIsCrawler] = useState(false);

    useEffect(() => {
        const userIsCrawler =
            /bot|googlebot|Facebot|facebookexternalhit|Speed Insights|Chrome-Lighthouse|crawler|spider|robot|crawling|Prerender/i.test(
                navigator.userAgent,
            );
        setIsCrawler(userIsCrawler);

        return () => {
            return setIsCrawler(false);
        };
    }, []);

    return isCrawler;
};

export { useGeoLocation, useGetIsCrawler };