import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PlaceHolder from 'assets/images/avatar-placeholder.png';

import style from 'assets/stylesheets/molecules/Widgets/Relation';
import { generateInitial } from '../../_helpers/generateInitials';

const propTypes = {
    dataLoaded: PropTypes.bool,
    handleClick: PropTypes.func,
    relationData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    handleMouseOver: PropTypes.func,
};

class RelationWidget extends React.Component {
    render() {
        const { relationData, handleClick, handleMouseOver, isAdmin, t } = this.props;
        return (
            <aside
                id="relation"
                className={`${style.relation} ${isAdmin ? style['relation--admin'] : ''}`}
                onMouseEnter={() => {
                    handleClick();
                    handleMouseOver();
                }}>
                <div className={style.relation__container}>
                    <figure className={style.relation__image}>
                        {relationData.base64Avatar ? (
                            <img
                                alt=""
                                src={'data:image/jpeg;base64,' + relationData.base64Avatar}
                                width="60"
                                height="60"
                                loading='lazy'
                            />
                        ) : (
                            <>
                                <p className={style.relation__initials}>
                                    {generateInitial(relationData.familyName).toUpperCase()}
                                </p>
                            </>
                        )}
                    </figure>
                    <summary className={style.relation__summary}>
                        <small>{t('csp.relationshipManager')}</small>
                        <h4>
                            {relationData.familyName}, {relationData.abbreviation} ({relationData.givenName})
                        </h4>
                        <a href={`tel:${relationData.phone}`} target="_blank" rel="noopener noreferrer" data-analytics-notrack="telephone number">
                            <span className={`${style.relation__iconPhone}`}></span>
                            {relationData.phone}
                        </a>
                        <a href={`mailto:${relationData.email}`} target="_blank" rel="noopener noreferrer" data-analytics-notrack="email">
                            <span className={`${style.relation__iconEmail}`}></span>
                            {relationData.email}
                        </a>
                    </summary>
                </div>
            </aside>
        );
    }
}

RelationWidget.propTypes = propTypes;

const select = ({ auth }) => ({
    isAdmin: auth?.user?.adminChoice,
});

export default connect(select)(withTranslation()(RelationWidget));
