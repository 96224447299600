import { createReducer } from 'redux-act';
import {
    receiveActiveWidgetNotifications,
    updateActiveWidgetNotifications,
    receiveFundNotifications,
    updateFundNotifications,
} from '../actions/notifications';
import states from 'data/reduxstates';
import { fetchActiveWidgetNotifications, fetchFundsNotifications } from '../sagas/api/actions';

const widgetNotificationsReducer = createReducer(
    {
        [fetchActiveWidgetNotifications]: (state) => ({ ...state, activeWidgetsNotificationsStatus: 'loading' }),
        [fetchFundsNotifications]: (state) => ({ ...state, fundNotificationsStatus: 'loading' }),
        [receiveActiveWidgetNotifications]: (state, { response = {}, status = 'ok' }) => {
            return {
                ...state,
                activeWidgetsNotifications: response,
                activeWidgetsNotificationsStatus: status,
            };
        },
        [updateActiveWidgetNotifications]: (state, updatedValue) => {
            return {
                ...state,
                activeWidgetsNotifications: updatedValue,
            };
        },
        [receiveFundNotifications]: (state, { response = [], status = 'ok' }) => {
            return {
                ...state,
                fundNotifications: response,
                fundNotificationsStatus: status,
            };
        },
        [updateFundNotifications]: (state, updatedValue) => {
            const updatedNotifications = state.fundNotifications.map((item) =>
                item.type === updatedValue.type ? updatedValue : item
            );
            return {
                ...state,
                fundNotifications: updatedNotifications,
            };
        },
    },
    states.widgetNotifications
);

export default widgetNotificationsReducer;
