import { useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCurrentClient, fetchEnabledWidgets } from '@/redux/sagas/api/actions';
import hasCurrentClient from 'components/_helpers/hasCurrentClient';

// @TODO: a better name probably is "useCurrentClient"
export default function useRequiresClientId() {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const clientAccounts = useSelector((state) => state.clientAccounts);
    const clientWidgets = useSelector((state) => state.clientWidgets);
    const { clientId } = useParams();

    const loading = false;

    // Fetch the client info when needed
    useEffect(() => {
        // Always ignore the /select-client url to prevent redirect loop
        if (location.pathname === '/select-client') {
            return;
        }

        if (
            // When clientId is present but no currentClient is defined
            (clientId && !hasCurrentClient(clientAccounts)) ||
            // Or when clientId changes to a new one
            (hasCurrentClient(clientAccounts) && clientId !== clientAccounts.currentClient?.id)
        ) {
            dispatch(fetchCurrentClient(clientId));
        }
    }, [clientId]);

    useEffect(() => {
        // Ignore if not on client dashboard and the client data is not there
        if (location.pathname === '/select-client') {
            return;
        }

        // Get widgets that are enabled for a client
        if (hasCurrentClient(clientAccounts) && !clientWidgets.enabledWidgets) {
            dispatch(fetchEnabledWidgets({ clientAccountId: clientId, clientName: clientAccounts.currentClient.name }));
        }
    }, [clientId, clientAccounts.currentClient]);

    // everything fine, currentClient is present in global state
    if (clientAccounts && clientAccounts.currentClient && clientWidgets.enabledWidgets) {
        return { loading, clientId, currentClient: clientAccounts.currentClient };
    }

    // ClientId is in URL but not in store, will fetch it.
    if (clientId) {
        return { loading: true, clientId };
    }

    // if admin is uploading a document, don't redirect
    if (clientAccounts.currentClient === null && location.pathname === '/add-document') {
        return { loading, clientId };
    }

    // Redirect to select page when client info cannot be selected
    if (location.pathname !== '/select-client') {
        history.push('/select-client');
    }

    return { loading };
}
