import { createReducer } from 'redux-act';
import states from 'data/reduxstates';
import { receiveAllUsers, receiveInternalUsers, receivePatchedUser } from '../actions/index';
import { contactName } from '../../components/_helpers/contactName';

const usersManagementReducer = createReducer({
    [receiveAllUsers]: (state, { allUsers, loadingStatus }) => {
        const data = allUsers.map(user => ({
            ...user,
            contactName: contactName(user)
        }));
        return { ...state, allUsers: data, loadingStatus };
    },
    [receiveInternalUsers]: (state, { internalUsers, loadingStatus }) => {
        const data = internalUsers.map(user => ({
            ...user,
            contactName: contactName(user)
        }));
        return { ...state, internalUsers: data, loadingStatus };
    },
    [receivePatchedUser]: (state, { userId, isEnabled }) => {
        const updatedUser = state.allUsers.find((user) => userId === user.userId);
        updatedUser.enabled = isEnabled;
        updatedUser.contactName =  contactName(updatedUser);

        return {
            ...state,
            allUsers: [
                ...state.allUsers,
                updatedUser
            ]
        };
    }
}, states.usersManagement);

export default usersManagementReducer;