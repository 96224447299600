import { all, put, call, takeEvery, takeLatest, select } from 'redux-saga/effects';
import * as actions from './actions';
import { setPreferenceData, setUserPreferences } from '../../actions/index';
import {
    retrievePreferenceCategories,
    retrieveUserPreferences,
    patchUserPreferences,
} from 'services/preferencesApiService';
import { genericSuccessMessage, genericErrorMessage } from './util';

function* fetchPreferenceData() {
    try {
        const stateCategories = yield select((state) => state.preferences.preferenceCategories);
        let categories = stateCategories;
        if (!stateCategories.length) {
            categories = yield call(retrievePreferenceCategories);
        }
        const userPreferences = yield call(retrieveUserPreferences);
        yield put(setPreferenceData({ categories, userPreferences }));
    } catch (e) {
        yield genericErrorMessage(e);
    }
}

function* fetchUserPreferences() {
    try {
        const userPreferences = yield call(retrieveUserPreferences);
        yield put(setUserPreferences({ userPreferences }));
    } catch (e) {
        yield genericErrorMessage(e);
    }
}

function* saveUserPreferences(action) {
    const { selectedTags } = action.payload;
    try {
        yield call(patchUserPreferences, selectedTags);
        yield genericSuccessMessage('Preferences are successfully updated');
    } catch (e) {
        yield genericErrorMessage(e);
    }
}

export default function* preferencesApiSagas() {
    yield all([takeEvery(`${actions.fetchPreferenceData}`, fetchPreferenceData)]);
    yield all([takeEvery(`${actions.fetchUserPreferences}`, fetchUserPreferences)]);
    yield all([takeLatest(`${actions.saveUserPreferences}`, saveUserPreferences)]);
}
