import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// translations
import en from './locales/en-GB/translation.json';
//import nl from './locales/nl-NL/translation.json';

i18n
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    .init({
        debug: true,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
            format: function(value, format) {
                if (format === 'uppercase') return value.toUpperCase();
                if (format === 'lowercase') return value.toLowerCase();
                return value;
            }
        },
        lng: 'en-GB',
        fallbackLng: ['en-GB'],
        resources: {
            'en-GB': en,
            //'nl-NL': nl
        }
    });

export default i18n;
