import http from './httpService';
import settings from '@/settings';
import { sortWith, prop, descend } from 'ramda';

const documentsApiURL = settings.DOMAIN + '/csp-doc-management-api/v1';
const documentsURL = `${documentsApiURL}/documents`;
const documentsTotalURl = `${documentsApiURL}/document-request-total`;
const downloadFilesURL = `${documentsApiURL}/files/download`;
const categoriesURL = `${documentsApiURL}/category-list`;
const clientsCategoriesURL = `${documentsApiURL}/get-category-list`;
const downloadURL = `${documentsURL}/download`;
const downloadFacsheetProspectusApiUrl = 'https://api.nnip.com/DocumentsApi/v2/';
const emailReminderURL = `${documentsApiURL}/document-requests-reminder`;
const requestOrder = `${documentsApiURL}/document-request-order`;
const documentsPreviewURL = `${documentsApiURL}/files/preview`;
const singleDocumentsPreviewURL = `${documentsApiURL}/documents/preview`;

const publicationDateSort = sortWith([descend(prop('publicationDate'))]);
const buildQueryString = (params) => {
    let queryString = '';
    if (params?.search) {
        queryString = `search=${params.search}`;
    }
    if (params?.maxItems) {
        queryString += `&maxItems=${params.maxItems}`;
    }
    if (params?.categoryId) {
        queryString += `&categoryId=${params.categoryId}`;
    }
    if (params?.dateFrom) {
        queryString += `&dateFrom=${params.dateFrom}`;
    }
    if (params?.dateTo) {
        queryString += `&dateTo=${params.dateTo}`;
    }
    if (params?.startFrom) {
        queryString += `&startFrom=${params.startFrom}`;
    }
    if (params?.externalRole) {
        queryString += `&externalRole=${params.externalRole}`;
    }
    return queryString;
};
export const retrieveDocuments = (params) => {
    const queryString = buildQueryString(params);
    return http
        .post(`${documentsURL}?${queryString}`, { clientAccountId: params.clientAccountId })
        // .get(documentsURL, { params })
        .then((response) => response.data)
        .catch((error) => error);
};
export const retrieveKYCDocuments = (params) => {
    let queryString = '';

    if (params?.type) {
        queryString = `type=${params.type}`;
    }
    if (params?.search) {
        queryString += `&search=${params.search}`;
    }
    return http
        .post(`${documentsURL}?${queryString}`, params)
        // .get(documentsURL, { params })
        .then((response) => response.data)
        .catch((error) => error);
};
export const retrieveDocumentsTotal = (body) =>
    http
        .post(`${documentsTotalURl}`, body)
        .then((response) => response.data)
        .catch((error) => error);
export const retrieveCategories = (params) => {
    const { internalUser, clientAccountId } = params;
    if (internalUser)
        return http
            .post(clientsCategoriesURL)
            .then((response) => response.data)
            .catch((error) => 'error');

    return http
        .post(clientsCategoriesURL, { clientAccountId: clientAccountId })
        .then((response) => response.data)
        .catch((error) => 'error');
};

export const patchCategories = (documentId, params) => http.patch(`${clientsCategoriesURL}/${documentId}`, params);
export const downloadDocument = (body) => http.post(`${downloadURL}`, { ...body, portalAccountId: body.clientId }).then((response) => response.data.url);
export const downloadFile = (body, clientId) => http.post(`${downloadFilesURL}${clientId ? `?clientId=${clientId}` : ''}`, body).then((response) => response.data.url);
export const deleteDocuments = (body, clientId) => http.post(`${documentsURL}/delete-documents`, { ...body, clientId: clientId, portalAccountId: clientId }).then((response) => response.data);
export const downloadFactsheetProspectus = (params) =>
    http
        .get(downloadFacsheetProspectusApiUrl + params)
        .then((response) =>
            response.data.list.length > 0 ? publicationDateSort(response.data.list)[0] : 'no_items_for_download'
        )
        .catch((error) => 'error');
export const retrieveAccessList = (documentId) =>
    http.get(`${documentsApiURL}/access-list/${encodeURIComponent(documentId)}`);
export const putDocumentAccess = (documentId, body) =>
    http.patch(`${documentsApiURL}/access-list/${encodeURIComponent(documentId)}`, body);
export const removeDocumentAccess = (documentId, body) =>
    http.delete(`${documentsApiURL}/access-list/${encodeURIComponent(documentId)}`, { data: body });

//Document request
export const retrieveDocumentInformation = (body) =>
    http.post(`${documentsURL}/get-document-details`, { ...body, portalAccountId: body.clientId }).then((response) => response.data);
export const retrieveDocumentDisplayInformation = (body) =>
    http.post(`${documentsPreviewURL}`, body).then((response) => response.data);
export const retrieveSingleDocumentDisplayInformation = (body) =>
    http.post(`${singleDocumentsPreviewURL}`, body).then((response) => response.data);
export const createDocumentRevision = (documentId, body, clientId) =>
    http
        .patch(`${documentsURL}/${documentId}`, { clientId, ...body })
        .then((response) => response)
        .catch((error) => error);
export const uploadDocumentToRequest = (documentId, type = 'submit', body) =>
    http
        .post(`${documentsURL}/${documentId}?type=${type}`, { ...body, portalAccountId: body.clientId })
        .then((response) => response)
        .catch((error) => error);
export const submitReorderedRequests = (body, clientId) => http.post(requestOrder, { clientId: clientId, documentList: body }).then((response) => response);
export const deleteUploadedFiles = (documentId, body, clientId) =>
    http.post(`${documentsURL}/${documentId}/savedFiles`, { clientId: clientId, fileIds: body }).then((response) => response.data);

//Manual E-mails
export const sendManualEmailReminder = (clientId, body) =>
    http
        .post(`${emailReminderURL}`, { ...body, clientId: clientId })
        .then((response) => response)
        .catch((error) => error);
