import { createAction } from 'redux-act';

// Base
export const backToLogin = createAction('Back to login');
export const appReset = createAction('Reset');
export const clientReset = createAction('Client data reset');

// Authorization
export const fetchLoginState = createAction('Fetch login state');
export const failFetchingLoginState = createAction('Fetch login state failed!');

export const fetchUser = createAction('Fetch user');
export const failFetchingUser = createAction('Fetch user failed!');

export const newPasswordRequired = createAction('New password required!');

export const changePassword = createAction('Request change password');

export const login = createAction('Login');
export const activeDirectoryLogin = createAction('Active Directory Login');
export const setOauthToken = createAction('Set OAuth token');
export const logout = createAction('Logout');
export const clickLogout = createAction('Logout via click');

export const forgotPassword = createAction('Forgot password?');
export const resetPassword = createAction('Request reset password');
export const confirmResetPassword = createAction('Confirm reset password');
export const completeResetPassword = createAction('Complete reset password');
export const resetPasswordCompleted = createAction('Reset password completed!');

export const changePasswordStatus = createAction('Change password status');

// Preferences
export const setPreferenceData = createAction('Set preference categories and user preferences');

// Add Portal Account (Wizard)
export const addPortalAccountStep1 = createAction('Company information');
export const addPortalAccountStep2 = createAction('Add Mandate');
export const addPortalAccountStep3 = createAction('Assign Portal Account users');
export const addPortalAccountStep4 = createAction('Summary');
export const addPortalAccountFinish = createAction('Add Portal Account');
export const addPortalAccountFailed = createAction('Add Portal Account failed!');
export const addPortalAccountCompleted = createAction('Add Portal Account completed!');
export const receiveSaleforceClientData = createAction('update salesforce account data for client');
export const receiveSaleforceProspectData = createAction('Update salesforce account data for prospect');
export const scanPortalAvatarAction = createAction('Scan portal avatar action');
export const scannedPortalAvatar = createAction('Scanned portal avatar');
export const portalAvatarUploadFailed = createAction('Portal avatar upload failed');
export const clearScannedPortalAvatarDataAfterUpload = createAction('Clear scanned portal avatar data after upload');
// Add KYC Document (Wizard)
export const addKYCDocumentStepClientAccounts = createAction('Add KYC Document');
// Add Document (Wizard)
export const addDocumentStepDocSecurity = createAction('Select document security type');
export const addDocumentStepClientAccounts = createAction('Connect Portal Accounts');
export const selectAccountChange = createAction('Portal Account Changed');
export const addDocumentUploader = createAction('Select file');
export const addDocumentStepMetadata = createAction('Add Metadata');
export const scanDocumentAction= createAction('Scan documents');
export const scanKYCDocumentAction = createAction('Scan KYC document');
export const scannedDocuments = createAction('Scanned Documents');
export const scannedKYCDocuments = createAction('Scanned KYC Documents');
export const UpdateDocumentPostDelete = createAction('Update Document Post Delete');
export const UpdateKYCDocumentPostDelete = createAction('Update KYC Document Post Delete')
export const addDocumentStepSummary = createAction('Summary');
export const addDocumentUpload = createAction('Upload documents');
export const addKYCDocumentUpload = createAction('Upload KYC templates');
export const addKYCDocumentCompleted = createAction('Adding KYC documents completed!');
export const addKYCDocumentFailed = createAction('Failure while adding KYC documents!');
export const addDocumentFailed = createAction('Add documents failed!');
export const addDocumentCompleted = createAction('Add documents completed!');
export const updateDocumentWizardState = createAction('Update document wizard state');
//export const generateUploadLinks = createAction('Generate upload links');
export const moveToMetaDataStep = createAction('move to meta data step');
export const generateKYCUploadLinks = createAction('Generate kyc documents upload links');
export const addKYCDocumentData = createAction('Add kyc documents data');
export const clearKYCDocumentData = createAction('Clear kyc documents data');

// Add new request (Wizard)
export const updateRequestWizardState = createAction('Update document request wizard state');
export const startRequestWizard = createAction('Start request wizard with passed client account');
export const addRequestMetadata = createAction('Add metadata to request');
export const addClientDocumentMetadata = createAction('Add metadata for client document');
export const addRequestSummary = createAction('Add summary to request');
export const addRequestUpload = createAction('Upload request');
export const addRequestUploadFailed = createAction('Upload request failed');
export const addRequestUploadCompleted = createAction('Upload request completed');
export const scanRequestDocumentAction = createAction('Request Document Action');
export const scannedRequestDocuments = createAction('Scanned Request Documents');
export const UpdateScanFilePostDelete = createAction('Update Scan File Post Delete');
export const clearRequestUploadLinks = createAction('Clear request upload links');
// Add new KYC Request (bulk)

export const startKYCRequestWizard = createAction('Start kyc request wizard with passed client account');
export const KYCBulkRequest = createAction('Submit Bulk KYC Request');

// Document information and request redux actions
export const receiveDocumentInformation = createAction('Receive document information details');
// export const fetchDocumentDisplayURL = createAction('Receive document preview information details');  //for document preview
// export const fetchRequestedDocURL = createAction('Receive requested document preview information details');
export const setStatusToLoaded = createAction('Set loading status to loaded');
export const setDocumentPreviewDetail = createAction('Set document preview information details');
export const setSingleDocumentPreviewDetail = createAction('Set single document preview information details');
export const receiveDocumentAccessList = createAction('Receive document information access list');
export const createDocumentRevision = createAction('Create document revision');
export const setDocumentRevisionStatus = createAction('Create document revision');
export const reviseSubmission = createAction('Revise submission');
export const receiveUploadLinks = createAction('Receive upload links');
export const clearUploadLinks = createAction('Clear upload links');
export const scannedDocumentsInfo = createAction('Scanned Documents Info');

//export const documentRequestUploadFailed = createAction('Upload documents to request failed');
export const requestedDocUploadCompleted = createAction('Upload documents to request success');
export const updateDocumentDetails = createAction('Update document details state');
export const documentEditCompleted = createAction('Edit of document information completed');
export const documentEditFailed = createAction('Edit of document information failed');

// Set documents Filters for Doc Store and Doc Management
export const setAppliedDocFilters = createAction('Set applied filters for documents page');
export const setPagination = createAction('Set pagination details');

// Process
export const updateProcessState = createAction('Update process state');
export const receiveProcessDocuments = createAction('Receive process documents from API');
export const receiveActiveProcessDetails = createAction('Receive active process details');
export const receiveEmptyProcessDetails = createAction('Receive empty process details');
export const receiveProcessDocumentsTotal = createAction('Receive total of requested documents for ongoing process');

//EditProcess
export const startEditWizard = createAction('Open edit ongoing process wizard');
export const updateEditProcess = createAction('Update ongoing process data');
export const addNewProcess = createAction('Add new ongoing process');
export const receiveProcess = createAction('Receive ongoing process details');
export const addProcessUploadCompleted = createAction('Ongoing process upload completed');
export const addProcessUploadFailed = createAction('Ongoing process upload failed');

// Analytics
// export const addAnalyticsEvent = createAction('Send an analytics event with data in datalayer');
// export const setAnalyticsUserInfo = createAction('Set user info in data layer');

// GDPR
export const setConsent = createAction('Set consent');
export const getConsent = createAction('Get consent');
export const giveConsent = createAction('Give consent');
export const removeConsent = createAction('Remove consent');

// Funds
export const receiveMyFunds = createAction('Receive my funds');
export const receiveAllFunds = createAction('Receive all funds');
export const updateAllFundsIds = createAction('Show only a number of funds in all funds section');
export const receiveFundDetails = createAction('Receive all fund details');
export const receiveAltisFundDetails = createAction('Receive all Altis fund details');
export const receiveMyWatchlist = createAction('Receive my watchlist');
export const addToWatchlist = createAction('Add fund to watchlist');
export const removeFromWatchlist = createAction('Remove fund from watchlist');
export const addToFundsData = createAction('Add my funds data to array with all ids');
export const setFundsSorters = createAction('Set sorters for funds');
export const receiveFundFilters = createAction('Receive fund filters');
export const setFundsError = createAction('Receive funds API error');
export const receiveWholesaleMasterlist = createAction('Fetch wholesale masterlist');

// User management
export const receiveAllUsers = createAction('Receive all users details');
export const receiveInternalUsers = createAction('Receive internal users details');
export const receivePatchedUser = createAction('Receive patched user status');
