import { createReducer } from 'redux-act';
import states from 'data/reduxstates';

import {
    addPortalAccountStep1,
    addPortalAccountStep2,
    addPortalAccountStep3,
    addPortalAccountStep4,
    addPortalAccountFinish,
    addPortalAccountFailed,
    addPortalAccountCompleted,
    receiveSaleforceClientData,
    receiveSaleforceProspectData,
    scannedPortalAvatar,
    portalAvatarUploadFailed,
    clearScannedPortalAvatarDataAfterUpload
} from '../actions';

const addPortalAccountReducer = createReducer(
    {
        [addPortalAccountStep1]: (state) => {
            return Object.assign({}, state, {
                step: 1,
                accountsAddedFailed: false,
                accountsAddedCompleted: false,
            });
        },
        [addPortalAccountStep2]: (state, payload) => {
            return Object.assign({}, state, {
                step: 2,
                data: payload,
            });
        },
        [addPortalAccountStep3]: (state, payload) => {
            return Object.assign({}, state, {
                step: 3,
                data: payload,
            });
        },
        [addPortalAccountStep4]: (state, payload) => {
            return Object.assign({}, state, {
                step: 4,
                data: payload,
            });
        },
        [addPortalAccountFinish]: (state, payload) => {
            return Object.assign({}, state, {
                step: 5,
                data: payload,
            });
        },
        [addPortalAccountFailed]: (state) => {
            return Object.assign({}, state, {
                accountsAddedFailed: true,
            });
        },
        [addPortalAccountCompleted]: (state) => {
            return Object.assign({}, state, {
                accountsAddedCompleted: true,
            });
        },
        [receiveSaleforceClientData]: (state, payload) => ({
            ...state,
            client: payload,
        }),
        [receiveSaleforceProspectData]: (state, payload) => {
            return { ...state, prospect: payload };
        },
        [scannedPortalAvatar] : (state, payload) => {
            return {
                ...state,
                correctFileData: payload.correctFileData,
                correctFileWithKey: payload.correctFileWithKey,
                malwareData: payload.malwareData.map(obj => obj.filename),
                apiError: payload.apiErrorData.length > 0 ? true : false,
                timeoutError: payload.timeoutError.length > 0 ? true : false
            }
        },
        [portalAvatarUploadFailed] : (state) => {
            return {
                ...state,
                correctFileData: [],
                correctFileWithKey: [],
                malwareData: [],
                apiError: true,
                timeoutError: false
            }
        },
        [clearScannedPortalAvatarDataAfterUpload] : (state) => {
            return {
                ...state,
                correctFileData: [],
                correctFileWithKey: [],
                malwareData: [],
                apiError: false,
                timeoutError: false
            }
        }
    },
    states.addportalaccount
);

export default addPortalAccountReducer;
