import { all, put, takeLatest } from 'redux-saga/effects';
import BlueConicHelper from '../utils/BlueConic';
import { genericErrorMessage } from './api/util';
import {
    getConsent,
    setConsent,
    giveConsent,
    removeConsent
} from '../actions';
import {
    SET_CONSENT,
    GET_CONSENT,
    GIVE_CONSENT,
} from '../actions/GDPRTypes';

const FUNCTIONAL_CONSENT = 0;
const FULL_CONSENT = [1, 2, 3, 4];
const GTM_COOKIE_CONSENT_NAME = 'consent';

const getConsentInfo = () => {
    try {
        const consentStorage = JSON.parse(localStorage.getItem('truste.eu.cookie.notice_preferences'));
        const consentValue = Number(consentStorage?.value?.replace(':', '') || -1);
        const hasFunctionalGDPRConsent = consentValue === FUNCTIONAL_CONSENT;
        const hasFullGDPRConsent = FULL_CONSENT.includes(consentValue);

        return {
            hasFullGDPRConsent,
            hasFunctionalGDPRConsent,
        };
    } catch (error) {
        console.log('getConsentInfo ~ error', error);
    }

    return null;
};

function* getGDPRConsent() {
    const { hasFullGDPRConsent, hasFunctionalGDPRConsent } = getConsentInfo();
    yield put(SET_CONSENT({
        type: SET_CONSENT,
        gaveGDPRConsent: hasFullGDPRConsent || hasFunctionalGDPRConsent,
        hasFunctionalGDPRConsent,
        hasFullGDPRConsent,
    }));
}

function* giveGDPRConsent({ payload: { hasFullConsent } }) {
    try {
        const gtmDataLayer = window?.dataLayer || [];

        if (hasFullConsent) {
            yield window.truste?.bn?.twoStepConfirmed();

            yield gtmDataLayer.push({
                event: GTM_COOKIE_CONSENT_NAME,
                consented_objectives: 'non-functional_cookies',
            });

            yield put({
                type: SET_CONSENT,
                gaveGDPRConsent: true,
                hasFunctionalGDPRConsent: false,
                hasFullGDPRConsent: true,
            });

            return;
        }

        yield gtmDataLayer.push({
            event: GTM_COOKIE_CONSENT_NAME,
            consented_objectives: '',
        });

        yield put({
            type: SET_CONSENT,
            gaveGDPRConsent: false,
            hasFunctionalGDPRConsent: true,
            hasFullGDPRConsent: false,
        });
    } catch (error) {
        console.log('giveGDPRConsent', error);
    }
}

function* handleGetConsent() {
    const helper = BlueConicHelper();
    const gaveGDPRConsent = yield helper.gaveGDPRConsent();

    if (gaveGDPRConsent.error) {
        yield genericErrorMessage(gaveGDPRConsent);
        return;
    }

    yield put(setConsent({ gaveGDPRConsent }));
}
function* handleGiveConsent() {
    const helper = BlueConicHelper();
    const result = yield helper.giveGDPRConsent();

    if (result.error) {
        yield genericErrorMessage(result);
        return;
    }

    yield put(setConsent({ gaveGDPRConsent: true }));
}

function* handleRemoveConsent(objective) {
    const helper = BlueConicHelper();
    const result = yield helper.removeConsent(objective);

    if (result.error) {
        yield genericErrorMessage(result);
        return;
    }

    yield put(setConsent({ gaveGDPRConsent: false }));
}

export default function* authSagas() {
    yield all([
        takeLatest(`${GET_CONSENT}`, getGDPRConsent),
        takeLatest(`${GIVE_CONSENT}`, giveGDPRConsent),
        // takeLatest(`${removeConsent}`, handleRemoveConsent),
    ]);
}
