export default function redirection() {
    if (window.location.host === 'dev.my.nnip.com') {
        window.location.replace('https://dev-my.gsam.com/');
    } else if (window.location.host === 'tst.my.nnip.com') {
        window.location.replace('https://qa-my.gsam.com/');
    } else if (window.location.host === 'acc.my.nnip.com') {
        window.location.replace('https://uat-my.gsam.com');
    } else if (window.location.host === 'my.nnip.com') {
        window.location.replace('https://my.gsam.com');
    }
}