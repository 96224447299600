/* eslint-disable no-unused-vars */
/* eslint-disable no-console */

import axios from 'axios';
import http from './httpService';
import settings from '../settings';

const generateUploadLinksApiUrl = settings.DOMAIN + '/csp-accounts-api/v1/generate-links';
const contactsApiUrl = settings.DOMAIN + '/management/csp-accounts-api/v1/salesforce-accounts/';
const portalAccountsApiUrl = settings.DOMAIN + '/management/csp-accounts-api/v1/client-accounts';
const salesForceAccountsApiUrl = settings.DOMAIN + '/management/csp-accounts-api/v1/salesforce-accounts';
const apiServer = settings.DOMAIN + '/management/csp-accounts-api/v1/user-actions';

const uploadFileStatusApiUrl = settings.DOMAIN + '/csp-accounts-api/v1/file-status';

export const addPortalAccount = (body) => http.post(portalAccountsApiUrl, body).catch(error => 'error');
export const retrievePortalAccounts = () => http.get(portalAccountsApiUrl).then(response => response.data).catch(error => 'error');
//export const retrievePortalAccount = (id) => http.get(portalAccountsApiUrl + '/' + id).then(response => response.data);
export const retrieveSalesForceAccounts = (params) => http.get(salesForceAccountsApiUrl, { params }).then(response => response.data).catch(error => 'error');
//export const retrievePortalAccountUsers = (id) => http.get(portalAccountsApiUrl + '/' + id + '/users').then(response => response.data).catch(error => 'error');
export const editPortalAccountInformation = (id, body) => http.patch(portalAccountsApiUrl, { ...body, clientAccountId: id }).then(response => response.data).catch(error => 'error');
export const generateUploadLink = (amount) => http.post(generateUploadLinksApiUrl, { 'numberOfUploads': amount }).then(response => response.data).catch(error => 'error');
export const refreshPortalAccountInformation = (id, body) => http.post(portalAccountsApiUrl + '/refresh', { clientAccountId: id }).then(response => response.data);
export const uploadAvatarMetadata = (id, key) => http.post(portalAccountsApiUrl + '/logo',
    {
        'uploadKey': key, 'crop': { 'width': 240, 'height': 240, 'x': 0, 'y': 0 },
        'clientAccountId': id
    }).then(response => response.data).catch(error => 'error');
export const uploadAvatar = (url, body) => axios.put(url, body, { headers: { 'Content-Type': 'application/octet-stream', 'x-amz-server-side-encryption': 'AES256' } }).then(response => response.data).catch(error => 'error');

export const retrieveUserActions = (body) => http.post(apiServer, body).then(response => response.data).catch(error => 'error');

export const retrievePortalAccount = (body) => http.post(portalAccountsApiUrl + '/admin-client-accounts', body).then(response => response.data);
export const retrievePortalAccountUsers = (body) => http.post(portalAccountsApiUrl + '/get-users-for-client-account', body).then(response => response.data).catch(error => 'error');
export const editPortalAccountUserStatus = (body) => http.patch(portalAccountsApiUrl + '/users', body).then(response => response).catch(error => error.message);

export const deletePortalAccount = (id) => http.post(portalAccountsApiUrl + '/deleteAccount', { clientAccountId: id, deleteAccount: true }).then(response => ({ status: response.status })).catch(error => ({ message: error.message }));
export const retrieveContacts = (id) => http.post(contactsApiUrl + '/contacts', { clientAccountId: id }).then(response => response.data).catch(error => 'error');
export const scanPortalAvatar = (body) => http.post(uploadFileStatusApiUrl, body).then(response => response).catch(error => 'error');
