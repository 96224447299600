/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-debugger */

// Base
import React from 'react';
import { splitEvery } from 'ramda';

// Style
import style from 'assets/stylesheets/atoms/Headings/Default';

export default class HeadingAtom extends React.Component {
    static getTagType(tag) {
        const tagList = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];

        return tagList.includes(tag) ? tag : 'h1';
    }

    static transformHeading(label, tag, customClass) {
        let TagType = HeadingAtom.getTagType(tag),
            words = label?.split(' '),
            parts = words?.length > 12 ? 3 : words?.length >= 6 ? 2 : 1;
            
            let splited = []
            if(words?.length){
                splited 
                = splitEvery(parts, words)
            }
            let compiledText = splited?.map((item, i) => {
            return <span key={i}>{item.join(' ')}</span>;
        
        });
    

        return <TagType className={`nnip-heading ${style['nnip-heading']} ${customClass}`}>{compiledText}</TagType>;
    }

    static plainHeading(label, tag, customClass) {
        let TagType = HeadingAtom.getTagType(tag);

        return (
            <TagType className={`nnip-heading--plain ${style['nnip-heading--plain']} ${customClass}`}>{label}</TagType>
        );
    }

    static capitalHeading(label, tag, customClass, subVarient) {
        let TagType = HeadingAtom.getTagType(tag);
        return (
            <TagType
                className={`nnip-heading  ${style[subVarient]} ${style['nnip-heading']} ${style[customClass]} ${customClass}`}>
                {label.toUpperCase()}
            </TagType>
        );
    }

    static renderVariant(variant, label, tag, customClass, subVarient) {
        switch (variant) {
            case 'plain':
                return HeadingAtom.plainHeading(label, tag, customClass);
            case 'capital':
                return HeadingAtom.capitalHeading(label, tag, customClass, subVarient);

            default:
                return HeadingAtom.transformHeading(label, tag, customClass);
        }
    }

    render() {
        const { tag, variant, subVarient, children, customClass = '' } = this.props;

        return HeadingAtom.renderVariant(variant, children, tag, customClass, subVarient);
    }
}
