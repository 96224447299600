import React from 'react';

// Style
import style from 'assets/stylesheets/atoms/Notifications/NotificationsWrapper';

const NotificationsWrapper = ({ children }) => {

    return (
        <div role="status" aria-live="polite" className={ `notifications-wrapper ${style['notifications-wrapper']}` }>
            { children }
        </div>
    );
};

export default NotificationsWrapper;
