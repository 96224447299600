import React from 'react';
import RoleBasedRoute from 'components/_routes/RoleBasedRoute';

const ContributorRoleRoute = ({ component: Component, auth, ...rest }) => {
    const { isLoggedIn, user: { isContributor, clientId, multipleClients } } = auth;

    return <RoleBasedRoute
        hasRole={ isContributor }
        isLoggedIn={ isLoggedIn }
        clientId={ clientId }
        multipleClients={ multipleClients }
        component={ Component }
        {...rest}
    />;
};

export default ContributorRoleRoute;
