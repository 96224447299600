/* eslint-disable no-console */
/* eslint-disable no-unused-vars */


/** @class */
export default class SessionStorage  {

    /**
     * This is used to set a specific item in storage
     * @param {string} key - the key for the item
     * @param {object} value - the value
     * @returns {string} value that was set
     */
    static setItem(key, value) {
        sessionStorage.setItem(key, value);
    }

    /**
     * This is used to get a specific key from storage
     * @param {string} key - the key for the item
     * This is used to clear the storage
     * @returns {string} the data item
     */
    static getItem(key) {
        return sessionStorage.getItem(key);
    }

    /**
     * This is used to remove an item from storage
     * @param {string} key - the key being set
     * @returns {string} value - value that was deleted
     */
    static removeItem(key) {
        sessionStorage.removeItem(key);
        SessionStorage._generateSyncEvent('removeItemEvent', { key: key });
    }

    /**
     * This is used to clear the storage
     * @returns {string} nothing
     */
    static clear() {
        sessionStorage.clear();
        SessionStorage._generateSyncEvent('clearEvent', {});
    }

    static _generateSyncEvent(event, data) {
        localStorage.setItem(event, JSON.stringify(data));
        // the other tab should now have it, so we're done with it.
        localStorage.removeItem(event);
    }
}
