import { combineReducers } from 'redux';
import authReducer from './reducers/Auth';
import gdprReducer from './reducers/gdpr';
import addDocumentReducer from './reducers/AddDocument';
import addRequestReducer from './reducers/AddRequest';
import documentInformationReducer from './reducers/documentInformation';
import addPortalAccountReducer from './reducers/AddPortalAccount';
import clientAccounts from './reducers/clientAccounts';
import clientWidgets from './reducers/clientWidgets';
import documentManagement from './reducers/documentManagement';
import notifications from './reducers/notifications';
import userReducer from './reducers/user';
import usersManagementReducer from './reducers/usersManagement';
import widgetNotificationsReducer from './reducers/widgetNotifications';
import contentfulReducer from './reducers/contentful';
import documentsFilters from './reducers/documentsFilters';
import processReducer from './reducers/Process';
import editProcessReducer from './reducers/editProcess';
import fundsReducer from './reducers/Funds';
import fundDetailsReducer from './reducers/FundDetails';
import fundDetailsAltisReducer from './reducers/FundDetailsAltis';
import preferencesReducer from './reducers/Preferences';
import addKYCDocumentReducer from './reducers/AddKYCDocument';
import addRequestForKYCReducer from './reducers/AddKYCDocument';
import { appReset, clientReset } from './actions';
import initialState from 'data/reduxstates';

const allReducers = combineReducers({
    auth: authReducer,
    gdpr: gdprReducer,
    adddocument: addDocumentReducer,
    addkycdocument: addKYCDocumentReducer,
    bulkKYCrequest: addRequestForKYCReducer,
    addrequest: addRequestReducer,
    documentInformation: documentInformationReducer,
    addportalaccount: addPortalAccountReducer,
    clientAccounts,
    clientWidgets,
    documentManagement,
    notifications,
    user: userReducer,
    usersManagement: usersManagementReducer,
    widgetNotifications: widgetNotificationsReducer,
    contentful: contentfulReducer,
    documentsFilters: documentsFilters,
    process: processReducer,
    editProcess: editProcessReducer,
    funds: fundsReducer,
    fundDetails: fundDetailsReducer,
    fundDetailsAltis: fundDetailsAltisReducer,
    preferences: preferencesReducer,
});

const rootReducer = (state, action) => {
    if (`${action.type}` === `${appReset}`) {
        sessionStorage.clear();
        return initialState;
    }

    if (`${action.type}` === `${clientReset}`) {
        const { auth, clientAccounts, gdpr, notifications, usersManagement, preferences } = state;
        const clearedState = {
            ...initialState,
            clientAccounts: { ...clientAccounts, currentClient: null },
            usersManagement,
            auth,
            gdpr,
            notifications,
            preferences,
        };
        sessionStorage.removeItem('currentClient');
        return clearedState;
    }

    return allReducers(state, action);
};

export default rootReducer;
