const regionMapping = ['ES', 'FR', 'DE', 'IT', 'LU', 'BE', 'NL', 'ASIA', 'LATAM', 'NORDICS', 'OTHER'];
export default regionMapping;

const countryMapping = {
    NETHERLANDS: {
        locales: 'nl-NL',
        region: 'NL',
        audienceURL: 'professional',
    },
    FRANCE: {
        locales: 'fr-FR',
        region: 'FR',
        audienceURL: 'professional',
    },
    BELGIUM: {
        locales: 'nl-BE',
        locales2: 'fr-BE',
        region: 'BE',
        audienceURL: 'professional',
    },
    ITALY: {
        locales: 'it-IT',
        region: 'IT',
        audienceURL: 'institutional',
    },
    GERMANY: {
        locales: 'de-DE',
        region: 'DE',
        audienceURL: 'professional',
    },
    LUXEMBOURG: {
        locales: 'en-LU',
        region: 'LU',
        audienceURL: 'professional',
    },
    SPAIN: {
        locales: 'es-ES',
        region: 'ES',
        audienceURL: 'professional',
    },
    NORWAY: {
        locales: 'en-NORDICS',
        region: 'NORDICS',
        audienceURL: 'professional',
    },
    SWEDEN: {
        locales: 'en-NORDICS',
        region: 'NORDICS',
        audienceURL: 'professional',
    },
    DENMARK: {
        locales: 'en-NORDICS',
        region: 'NORDICS',
        audienceURL: 'professional',
    },
    CHILE: {
        locales: 'es-LATAM',
        locales2: 'en-LATAM',
        region: 'LATAM',
        audienceURL: 'professional',
    },
    PERU: {
        locales: 'es-LATAM',
        locales2: 'en-LATAM',
        region: 'LATAM',
        audienceURL: 'professional',
    },
    URUGUAY: {
        locales: 'es-LATAM',
        locales2: 'en-LATAM',
        region: 'LATAM',
        audienceURL: 'professional',
    },
    COLOMBIA: {
        locales: 'es-LATAM',
        locales2: 'en-LATAM',
        region: 'LATAM',
        audienceURL: 'professional',
    },
    'UNITED STATES': {
        locales: 'en-LATAM',
        locales2: 'es-LATAM',
        region: 'LATAM',
        audienceURL: 'professional',
    },
    SINGAPORE: {
        locales: 'en-SG',
        region: 'ASIA',
        audienceURL: 'professional',
    },
    CHINA: {
        locales: 'en-INT',
        region: 'ASIA',
        audienceURL: 'professional',
    },
    'TAIWAN, PROVINCE OF CHINA': {
        locales: 'en-INT',
        region: 'ASIA',
        audienceURL: 'professional',
    },
    'KOREA, REPUBLIC OF': {
        locales: 'en-INT',
        region: 'ASIA',
        audienceURL: 'professional',
    },
    JAPAN: {
        locales: 'ja-JP',
        region: 'ASIA',
        audienceURL: 'professional',
    },
    ROMANIA: {
        locales: 'en-INT',
        region: 'OTHER',
        audienceURL: 'professional',
    },
    SLOVAKIA: {
        locales: 'sk-SK',
        region: 'OTHER',
        audienceURL: 'professional',
    },
    SWITZERLAND: {
        locales: 'en-CH',
        region: 'OTHER',
        audienceURL: 'professional',
    },
    'UNITED KINGDOM': {
        locales: 'en-GB',
        region: 'OTHER',
        audienceURL: 'professional',
    },
    'CZECH REPUBLIC': {
        locales: 'cs-CZ',
        region: 'OTHER',
        audienceURL: 'professional',
    },
    DEFAULT_INTL: {
        locales: 'en-INT',
        region: 'OTHER',
        audienceURL: 'professional',
    },
};

export function getLocales(country = 'DEFAULT_INTL') {
    const { locales } = countryMapping[country] || countryMapping['DEFAULT_INTL'];
    return locales;
}

export function getAudienceURL(country = 'DEFAULT_INTL') {
    const { audienceURL } = countryMapping[country] || countryMapping['DEFAULT_INTL'];
    return audienceURL;
}
