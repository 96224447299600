import { all, takeEvery, takeLatest, call, put, delay, select } from 'redux-saga/effects';
import * as actions from './actions';
import * as docActions from '../../actions/documents';
// import { addAnalyticsEvent } from '../../actions/index';
import { genericErrorMessage, genericSuccessMessage, genericMessage } from './util';

import * as documentApiService from 'services/documentsApiService';
import download from 'downloadjs';

function* fetchDocuments(action) {
    const externalRole = yield select((state) => state.clientWidgets.externalRole.role);
    // debounce the call for 500ms
    yield delay(500);
    const result = yield call(documentApiService.retrieveDocuments, { ...action.payload, externalRole });
    yield put(docActions.receiveDocuments(result));
}

function* fetchKYCDocuments(action) {
    const externalRole = yield select((state) => state.clientWidgets.externalRole.role);
    // debounce the call for 500ms
    yield delay(500);
    const result = yield call(documentApiService.retrieveKYCDocuments, { ...action.payload, externalRole });
    yield put(docActions.receiveKYCDocuments(result));
}

function* fetchDocumentsWithCategories(action) {
    // debounce the call for 500ms
    yield delay(500);
    const { dateFrom, dateTo, search, clientAccountId, internalUser = false } = action.payload;
    const externalRole = yield select((state) => state.clientWidgets.externalRole.role);
    const categoriesResult = yield call(documentApiService.retrieveCategories, {
        dateFrom,
        dateTo,
        search,
        clientAccountId,
        internalUser
    });
    const result = yield call(documentApiService.retrieveDocuments, { ...action.payload, externalRole });
    yield put(docActions.receiveDocumentsCategories({ categories: categoriesResult.data, externalRole }));
    yield put(docActions.receiveDocuments(result));
}

function* fetchDocumentsCategories(action) {
    if (action.payload.length <= 1) {
        const externalRole = yield select((state) => state.clientWidgets.externalRole.role);
        const result = yield call(documentApiService.retrieveCategories, action.payload);
        yield put(docActions.receiveDocumentsCategories({ categories: result.data, externalRole }));
    }
}

function* downloadFiles(action) {
    const { fileIds, categoryName, eventSource, clientId } = action.payload;
    try {
        const url = yield call(documentApiService.downloadFile, fileIds, clientId);
        download(url);
        // yield put(
        //     addAnalyticsEvent({
        //         eventName: 'documentDownload',
        //         data: {
        //             categoryName,
        //             eventSource,
        //         },
        //     })
        // );
    } catch (error) {
        yield genericErrorMessage(error);
    }
}

function* downloadDocuments(action) {
    const { documentIds, categoryName, eventSource, clientId } = action.payload;
    yield put(docActions.addDownloadingDocument(documentIds));
    try {
        const url = yield call(documentApiService.downloadDocument, { documentIds, clientId });
        download(url);
        // yield put(
        //     addAnalyticsEvent({
        //         eventName: 'documentDownload',
        //         data: {
        //             categoryName,
        //             eventSource,
        //         },
        //     })
        // );
        yield delay(1000);
        yield put(docActions.removeDownloadingDocument(documentIds));
    } catch (error) {
        if (error.response.data.message === 'No document ids in request') {
            yield genericMessage('Only submitted documents can be downloaded.', 'info');
        } else {
            yield genericErrorMessage(error);
        }
        yield put(docActions.removeDownloadingDocument(documentIds));
    }
}

function* deleteDocuments(action) {
    const { documentIds, params, KYCParams, clientId } = action.payload;
    yield put(docActions.addDeletingDocument(documentIds));
    try {
        yield call(documentApiService.deleteDocuments, { documentIds: [...documentIds], clientId });
        yield put(docActions.setDocumentDeleted(documentIds));
        yield genericSuccessMessage('Documents successfully deleted');
        yield fetchDocuments({ payload: params });
        yield fetchKYCDocuments({ payload: KYCParams });
    } catch (error) {
        yield genericErrorMessage(error);
    }
}

function* reorderedRequests(action) {
    const { mappedOrderIds, clientId } = action.payload;

    try {
        yield call(documentApiService.submitReorderedRequests, mappedOrderIds, clientId);
    } catch (error) {
        yield genericErrorMessage(error);
    }
}

function* sendManualEmailReminder(action) {
    const { clientId, isReminderText } = action.payload;

    const response = yield call(documentApiService.sendManualEmailReminder, clientId, { isReminderText });
    if (response.status === 200) {
        yield genericSuccessMessage('Summary has been emailed to client');
    } else {
        yield genericErrorMessage('Your summary email couldn’t be sent. Try sending again');
    }
}

export default function* clientApiSagas() {
    yield all([
        takeLatest(`${actions.fetchDocuments}`, fetchDocuments),
        takeLatest(`${actions.fetchKYCDocuments}`, fetchKYCDocuments),
        takeLatest(`${actions.fetchDocumentsWithCategories}`, fetchDocumentsWithCategories),
        takeLatest(`${actions.fetchDocumentsCategories}`, fetchDocumentsCategories),
        takeEvery(`${actions.downloadDocuments}`, downloadDocuments),
        takeEvery(`${actions.downloadFiles}`, downloadFiles),
        takeEvery(`${actions.deleteDocuments}`, deleteDocuments),
        takeEvery(`${actions.reorderedRequests}`, reorderedRequests),
        takeLatest(`${actions.sendManualEmailReminder}`, sendManualEmailReminder),
    ]);
}
