/* eslint-disable no-unused-vars */
/* eslint-disable no-console */

export const generateInitial = (text) => {
    let n = text.split(/ +/);
    let j = '';
    n.map((el, index) => {
        if (index <= 1) {
            let p = el.charAt(0);
            j += p;
        }
    });
    return j;
};
