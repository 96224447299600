import React from 'react';
import { Helmet } from 'react-helmet';

const HelmetHead = ({ title = 'Portal' }) => {
    return (
        <Helmet>
            <title>{`${title} | My GSAM`}</title>
        </Helmet>
    );
};

//not in use

export default HelmetHead;
