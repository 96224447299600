import { all, takeEvery, call, put, select } from 'redux-saga/effects';
import * as actions from './actions';
import { receiveAllUsers, receiveInternalUsers, receivePatchedUser } from '../../actions/index';
import { genericErrorMessage } from './util';
import { retrieveUsers, retrieveInternalUsers, patchUserStatus } from 'services/userManagementApiService';

function* fetchAllUsers() {
    const currentAllUsers = yield select(state => state.usersManagement.allUsers);
    if (!currentAllUsers.length) {
        try {
            const { data } = yield call(retrieveUsers);
            yield put(receiveAllUsers({ allUsers: data, loadingStatus: 'loaded' }));
        } catch (error) {
            yield genericErrorMessage(error);
        }
    }
}

function* fetchInternalUsers() {
    const currentUsers = yield select(state => state.usersManagement.internalUsers);
    if (!currentUsers.length) {
        try {
            const { data } = yield call(retrieveInternalUsers);
            yield put(receiveInternalUsers({ internalUsers: data, loadingStatus: 'loaded' }));
        } catch (error) {
            yield genericErrorMessage(error);
        }
    }
}

function* handlePatchUserStatus(action) {
    const { user } = action.payload;
    try {
        const response = yield call(patchUserStatus, user.userId, { enabled: !user.enabled });
        yield put(receivePatchedUser({ userId: user.userId, isEnabled: !user.enabled }));
    } catch (error) {
        yield genericErrorMessage(error);
    }
}

export default function* usersManagementApiSagas() {
    yield all([
        takeEvery(`${actions.fetchAllUsers}`, fetchAllUsers),
        takeEvery(`${actions.fetchInternalUsers}`, fetchInternalUsers),
        takeEvery(`${actions.handlePatchUserStatus}`, handlePatchUserStatus),
    ]);
}
