import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import LinkDefaultAtom from 'components/atoms/Links/Default';
import LoadingIndicatorAtom from 'components/atoms/LoadingIndicator/Default';
import StackAtom from 'components/atoms/Stack/Stack';
import ButtonAtom from 'components/atoms/Buttons/Button';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { clickLogout } from '../../../redux/actions';
import { useDispatch } from 'react-redux';

const propTypes = {
    clientId: PropTypes.string,
    dataLoaded: PropTypes.bool,
};

/** This component is shown when there is an error thrown in the application or the page is not found */
const ErrorInfo = ({ children, dataLoaded }) => {
    return <section>{dataLoaded ? children : <LoadingIndicatorAtom />}</section>;
};

/** error message with instraction how to recover from the error */
const ErrorBoundary = ({ errorInfo }) => {
    const { t } = useTranslation();

    return (
        <>
            <StackAtom justify="flex-start" alignItems="flex-start">
                <div className={'csp-spacing-s'}>
                    <p>{t('csp.errors.loadingPageError')}</p>
                    <ButtonAtom
                        onClick={() => {
                            window.location.reload();
                        }}>
                        {t('csp.reloadPage')}
                    </ButtonAtom>
                </div>
            </StackAtom>
            <details>
                <summary>{t('csp.errors.clickForDetails')}</summary>
                {errorInfo && errorInfo.componentStack.toString()}
            </details>
        </>
    );
};

/** page not found message with an instruction on how to recover */
const NotFound = ({ clientId }) => {
    const { t } = useTranslation();

    return (
        <StackAtom justify="flex-start" alignItems="flex-start">
            <div>
                <h2>{t('csp.errors.notFoundHeading')}</h2>
                <p>{t('csp.errors.notFoundSubheading')}</p>
                {clientId ? (
                    <LinkDefaultAtom to={`/dashboard/${clientId}`} back="true">
                        {t('csp.selectClient.goToDashboard')}
                    </LinkDefaultAtom>
                ) : (
                    <LinkDefaultAtom to="/select-client" back="true">
                        {t('csp.tryAgain')}
                    </LinkDefaultAtom>
                )}
            </div>
        </StackAtom>
    );
};


const PasswordUpdate = ({ clientId }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();

    return (
        <StackAtom justify="flex-start" alignItems="flex-start">
            <div>
                <p>{t('csp.errors.passwordUpdate')}</p>
                <ButtonAtom
                        onClick={() => {
                         dispatch(clickLogout());
                            history.push('/forgot-password') ;
                        }}>
                        {"Reset password"}
                    </ButtonAtom>
             
            </div>
        </StackAtom>
    );
};

ErrorInfo.ErrorBoundary = ErrorBoundary;
ErrorInfo.NotFound = NotFound;
ErrorInfo.PasswordUpdate = PasswordUpdate

ErrorInfo.propTypes = propTypes;

export default ErrorInfo;
