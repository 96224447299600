import { createReducer } from 'redux-act';
import states from 'data/reduxstates';
import {
    fetchDocumentInformation,
    fetchFullDocumentInformation,
    uploadDocumentRevision,
    uploadRequestedDoc,
    deleteUploadedFile,
} from '../sagas/api/actions';
import { updateDocumentDetails, documentEditCompleted, documentEditFailed } from '../actions/index';
import setFileTitle from 'components/_helpers/setFileTitle';

import {
    receiveDocumentInformation,
    setDocumentRevisionStatus,
    reviseSubmission,
    receiveUploadLinks,
    clearUploadLinks,
    receiveDocumentAccessList,
    setDocumentPreviewDetail,
    setSingleDocumentPreviewDetail,
    setStatusToLoaded,
    scannedDocumentsInfo
} from '../actions';

// Content
import dictionary from 'data/dictionary.json';
const {
    labels: { updatedDate },
    addRequest: { labels },
    docInfo: { details },
} = dictionary;

const documentInformationReducer = createReducer(
    {
        [fetchDocumentInformation]: (state) => ({ ...state, loadingStatus: 'loading' }),
        [setDocumentPreviewDetail]: (state, payload) => {
            return { ...state, previewURL: payload };
        },
        [setSingleDocumentPreviewDetail]: (state, payload) => {
            return { ...state, previewURL: payload };
        },
        [deleteUploadedFile]: (state) => ({ ...state, loadingStatus: 'loading' }),
        [fetchFullDocumentInformation]: (state) => ({ ...state, loadingStatus: 'loading' }),
        [uploadDocumentRevision]: (state) => ({ ...state, loadingStatus: 'loading' }),
        [uploadRequestedDoc]: (state, payload) => {
            return { ...state, loadingStatus: 'loading' };
        },
        [setStatusToLoaded]: (state) => ({ ...state, loadingStatus: 'loaded' }),
        [receiveUploadLinks]: (state, payload) => {
            const arrFiles = [];
            const arrDocuments = [];
            const { files, keys } = payload;
            files.forEach((value, i) => {
                arrFiles.push(value);
                arrDocuments.push({
                    key: keys[i],
                    filename: value.name,
                    title: setFileTitle(value.name),
                });
            });

            return {
                ...state,
                uploadedDocuments: {
                    documentId: state.documentData.documentId,
                    files: arrFiles,
                    documents: arrDocuments,
                },
            };
        },
        [clearUploadLinks]: (state) => {
            return {
                ...state,
                uploadedDocuments: { files: null, documents: [] },
                correctFileData: [],
                correctFileWithKey: [],
                malwareData: [],
                apiError: false,
                timeoutError: false
            };
        },
        [receiveDocumentInformation]: (state, payload) => {
            // last available revision
            const lastRevision = payload.revisions[payload.revisions.length - 1];
            // check if it is a document request or a single document
            const isRequestedDoc = payload.type === 'DOCUMENT_REQUEST';
            const displayStatus = isRequestedDoc ? lastRevision.state : payload.type;
            const revisionStatus = state.isRevisingSubmission ? 'NNIP_REQUESTED' : displayStatus;
            const category = payload.categories[0] || '';
            const { categoryName = 'Other' } = category;
            const documentData = payload;
            // File added with the last revision
            const lastRevisionFile = { documentId: documentData.documentId, ...lastRevision.files[0] };
            documentData.revisionDescription = lastRevision.revisionDescription;
            // File submitted with the last revision
            documentData.clientUploadedFiles = payload.revisions
                .reverse()
                .find(
                    (revision) =>
                        revision.state === 'CLIENT_SUBMITTED' || revision.state === 'CLIENT_SUBMISSION_IN_PROGRESS'
                );
            const attachedFile = payload.requestFileId
                ? {
                    label: labels.attachedFile,
                    value: {
                        title: payload.requestFileTitle,
                        documentId: payload.requestFileId,
                    },
                }
                : null;

            const requestDetails = {
                descriptionWhatAndHow: {
                    label: labels.description,
                    value: payload.descriptionWhatAndHow,
                },
                descriptionWhy: {
                    label: labels.importance,
                    value: payload.descriptionWhy || null,
                },
                updatedDate: {
                    label: updatedDate,
                    value: payload.updatedDate || null,
                },
                ...(attachedFile && { attachedFile }),
            };

            const singleDocDetails = {
                lastRevisionFile: {
                    label: details.uploadedFile,
                    value: lastRevisionFile,
                },
                updatedDate: {
                    label: details.updatedDate,
                    value: payload.updatedDate || null,
                },
                expirationDate: {
                    label: details.expirationDate,
                    value: payload.expirationDate || null,
                },
            };

            return {
                ...state,
                documentData: documentData,
                requestDetails: requestDetails,
                singleDocDetails: singleDocDetails,
                isRequestedDoc: isRequestedDoc,
                displayStatus: displayStatus,
                revisionStatus: revisionStatus,
                category: categoryName,
            };
        },
        [receiveDocumentAccessList]: (state, { data, totalAccessOutsideRegion }) => {
            return {
                ...state,
                accessList: data,
                totalAccessOutsideRegion,
                grantCompleted: false,
                grantFailed: false,
            };
        },
        [updateDocumentDetails]: (state, payload) => {
            return { ...state, ...payload };
        },
        [documentEditCompleted]: (state) => {
            return { ...state, grantCompleted: true, loadingStatus: 'loading' };
        },
        [documentEditFailed]: (state) => {
            return { ...state, loadingStatus: 'error' };
        },
        [setDocumentRevisionStatus]: (state, payload) => {
            return { ...state, isRequestingRevision: payload };
        },
        [reviseSubmission]: (state, payload) => {
            return { ...state, revisionStatus: 'NNIP_REQUESTED' };
        },
        // [documentRequestUploadFailed]: (state) => {
        //     return { ...state, requestUploadFailed: true };
        // },
        // [requestedDocUploadCompleted]: (state) => {
        //     return { ...state, requestUploadCompleted: true };
        // }
        [scannedDocumentsInfo]: (state, payload) => {
            return {
                ...state,
                correctFileData: payload.correctFileData,
                correctFileWithKey: payload.correctFileWithKey,
                malwareData: payload.malwareData.map(obj => obj.name),
                apiError: payload.apiErrorData.length > 0 ? true : false,
                timeoutError: payload.timeoutError.length > 0 ? true : false
            };
        }
    },
    states.documentInformation
);

export default documentInformationReducer;
