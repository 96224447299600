import React from 'react';

const NotificationIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <g fill="none" fillRule="evenodd">
            {(() => {
                switch (props.type) {
                    case 'info':
                        return (
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M15.4999 2.58337C8.36992 2.58337 2.58325 8.37004 2.58325 15.5C2.58325 22.63 8.36992 28.4167 15.4999 28.4167C22.6299 28.4167 28.4166 22.63 28.4166 15.5C28.4166 8.37004 22.6299 2.58337 15.4999 2.58337ZM14.2083 9.04171V11.625H16.7916V9.04171H14.2083ZM14.2083 14.2084V21.9584H16.7916V14.2084H14.2083ZM5.16659 15.5C5.16659 21.1963 9.80367 25.8334 15.4999 25.8334C21.1962 25.8334 25.8333 21.1963 25.8333 15.5C25.8333 9.80379 21.1962 5.16671 15.4999 5.16671C9.80367 5.16671 5.16659 9.80379 5.16659 15.5Z"
                                fill="#186ADE"
                            />
                        );
                    case 'success':
                    case 'undo':
                        return (
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M15.4999 2.58337C8.36992 2.58337 2.58325 8.37004 2.58325 15.5C2.58325 22.63 8.36992 28.4167 15.4999 28.4167C22.6299 28.4167 28.4166 22.63 28.4166 15.5C28.4166 8.37004 22.6299 2.58337 15.4999 2.58337ZM15.4999 25.8334C9.80367 25.8334 5.16659 21.1963 5.16659 15.5C5.16659 9.80379 9.80367 5.16671 15.4999 5.16671C21.1962 5.16671 25.8333 9.80379 25.8333 15.5C25.8333 21.1963 21.1962 25.8334 15.4999 25.8334ZM12.9166 18.8455L21.4287 10.3334L23.2499 12.1675L12.9166 22.5009L7.74992 17.3342L9.57117 15.513L12.9166 18.8455Z"
                                    fill="#077D55"
                                />
                            </g>
                        );
                    case 'warning':
                        return (
                            <path
                                d="M15.5001 7.73712L25.2263 24.5417H5.77383L15.5001 7.73712ZM15.5001 2.58337L1.29175 27.125H29.7084L15.5001 2.58337ZM16.7917 20.6667H14.2084V23.25H16.7917V20.6667ZM16.7917 12.9167H14.2084V18.0834H16.7917V12.9167Z"
                                fill="#F5C518"
                            />
                        );
                    case 'error':
                        return (
                            <path
                                d="M14.2083 19.375H16.7916V21.9584H14.2083V19.375ZM14.2083 9.04171H16.7916V16.7917H14.2083V9.04171ZM15.487 2.58337C8.357 2.58337 2.58325 8.37004 2.58325 15.5C2.58325 22.63 8.357 28.4167 15.487 28.4167C22.6299 28.4167 28.4166 22.63 28.4166 15.5C28.4166 8.37004 22.6299 2.58337 15.487 2.58337ZM15.4999 25.8334C9.79075 25.8334 5.16659 21.2092 5.16659 15.5C5.16659 9.79087 9.79075 5.16671 15.4999 5.16671C21.2091 5.16671 25.8333 9.79087 25.8333 15.5C25.8333 21.2092 21.2091 25.8334 15.4999 25.8334Z"
                                fill="#D91F11"
                            />
                        );
                    default:
                        return null;
                }
            }).call(this)}
        </g>
    </svg>
);
export default NotificationIcon;
