export default function removeUniqueElements(payload, totalDocuments) {

    let newObj = [];
    totalDocuments.forEach(val => {
        payload.forEach(obj => {
            if (obj.name === val.filename) {
                newObj.push(val);
            }
        });
    });
    return newObj;
}