import React, { useState } from 'react';
import { Route, Redirect, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setIsUnauthorised } from '@/redux/actions/clients';
import { createNotification } from '@/redux/sagas/common/actions';
import routes from 'data/routes.json';

import HelmetHead from '../molecules/Head/HelmetHead';
import LoadingIndicatorAtom from 'components/atoms/LoadingIndicator/Default';
import useRequiresClientId from 'components/_helpers/hooks/useRequiresClientId';
import useOnboardingRedirect from 'components/_helpers/hooks/useOnboardingRedirect';

const PrivateRouteComponent = ({ auth, component: Component }) => {
    const history = useHistory();
    const location = useLocation();
    const match = useRouteMatch();
    const [redirect, setRedirect] = useState(null);
    const [isOnboardingChecked] = useOnboardingRedirect();
    const { loading } = useRequiresClientId();

    if (auth.isLoggedIn === true) {
        // wait for portal account data, enabled widgets and check if onboarding isn't enabled
        if (loading || !isOnboardingChecked) {
            return <LoadingIndicatorAtom />;
        }

        return (
            <Component
                {...{ location, history, match }}
                {...{
                    redirect: redirect && match.path === routes.dashboard.path,
                }}
            />
        );
    }

    return <Redirect to={{ pathname: routes.login.path, state: { from: location } }} />;
};

const PrivateRoute = ({
    component: Component,
    auth,
    path,
    title,
    widgetCode,
    disabledWidgets,
    isUnauthorised,
    unauthorisedReason,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // Handle unauthorised users
    if (isUnauthorised) {
        if (unauthorisedReason === 'financialRole') {
            dispatch(createNotification({ message: t('csp.unauthorisedRole'), type: 'error' }));
        } else {
            dispatch(createNotification({ message: t('csp.unauthorised'), type: 'error' }));
        }
        dispatch(setIsUnauthorised({ unauthorised: false }));
        return <Redirect to={{ pathname: routes.accountSelection.path }} />;
    }

    // Check for widgets that are disabled (for financial roles)
    if (disabledWidgets.includes(widgetCode)) {
        dispatch(setIsUnauthorised({ unauthorised: true, reason: 'financialRole' }));
        return null;
    }

    return (
        <Route
            path={path}
            render={(props) => {
                return (
                    <>
                        <HelmetHead title={title} />
                        <PrivateRouteComponent auth={auth} component={Component} {...props} />
                    </>
                );
            }}
        />
    );
};

function select({ clientWidgets, clientAccounts: { isUnauthorised, unauthorisedReason } }) {
    return {
        isUnauthorised,
        unauthorisedReason,
        disabledWidgets: clientWidgets.disabledWidgets,
    };
}

export default connect(select)(PrivateRoute);
