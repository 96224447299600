import React from 'react';
import screen from 'data/responsive.json';
import Responsive from 'react-responsive';
import { Link } from 'react-router-dom';

import Logo from 'assets/images/logo.svg';

import style from 'assets/stylesheets/templates/Login';

const SimpleHeader = (props) => {
    const { newLogin = false } = props;
    return (
        <header className={`${style.login__header}`}>
            <Link role="button" to="/" className={`logo ${style.logo}`}>
                <Responsive query={`(min-width: ${screen.sm + 1}px)`} >
                    <img alt="" src={Logo} width="215" height="62" loading='lazy' />
                </Responsive>
                <Responsive query={`(max-width: ${screen.sm}px)`}>
                    <img alt="" src={Logo} width="140" height="40" loading='lazy' />
                </Responsive>
            </Link>
        </header>
    );
};

export default SimpleHeader;
