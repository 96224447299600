import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setIsUnauthorised } from '@/redux/actions/clients';
import { createNotification } from '@/redux/sagas/common/actions';
import routes from 'data/routes.json';

import HelmetHead from '../molecules/Head/HelmetHead';

const RoleBasedRoute = ({
    component: Component,
    isLoggedIn,
    hasRole,
    multipleClients,
    clientId,
    isUnauthorised,
    title,
    ...rest
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    if (isUnauthorised) {
        dispatch(setIsUnauthorised({ unauthorised: false }));
        dispatch(createNotification({ message: t('csp.unauthorised'), type: 'error' }));
        return <Redirect to={{ pathname: routes.admin.path }} />;
    }

    return (
        <Route
            {...rest}
            render={(props) => {
                if (isLoggedIn && hasRole) {
                    return (
                        <>
                            <HelmetHead title={title} />
                            <Component {...props} />
                        </>
                    );
                }

                if (isLoggedIn && !hasRole) {
                    dispatch(createNotification({ message: t('csp.unauthorised'), type: 'error' }));
                    if (multipleClients) {
                        return <Redirect to="/select-client" />;
                    }

                    if (clientId) {
                        return <Redirect to={`/dashboard/${clientId}`} />;
                    }

                    return <Redirect to="/no-clients" />;
                }

                return <Redirect to="/login" />;
            }}
        />
    );
};

function select({ clientAccounts: { isUnauthorised } }) {
    return {
        isUnauthorised,
    };
}

export default connect(select)(RoleBasedRoute);
