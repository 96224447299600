/* eslint-disable no-console */
import { all, takeEvery, call, put, select } from 'redux-saga/effects';
import * as actions from './actions';
import * as userActions from '../../actions/user';
import { genericErrorMessage } from './util';

import * as userApiService from 'services/userApiService';
import { generateUploadLink, scanAvatar, uploadAvatar } from '../../../services/userManagementApiService';

function* fetchUserDetails() {
    const currentUser = yield select(state => state.user.details.userId);
    if (!currentUser) {
        try {
            const user = yield call(userApiService.retrieveCurrentUser);
            yield put(userActions.receiveUserDetails(user));
        } catch (error) {
            yield genericErrorMessage(error);
        }
    }
}

function* fetchUserAvatar() {
    const currentAvatar = yield select(state => state.user.avatar);
    if (!currentAvatar) {
        try {
            const avatar = yield call(userApiService.retrieveUserAvatar);
            yield put(userActions.receiveUserAvatar(avatar));
        } catch (error) {
            yield genericErrorMessage(error);
        }
    }
}

const readDataURL = (blob) => new Promise((resolve) => {
    const fr = new FileReader();
    fr.addEventListener('load', () => resolve(fr.result), false);
    fr.readAsDataURL(blob);
});

function* updateUserAvatar(action) {
    const imageBlob = action.payload;
    const dataURL = yield readDataURL(imageBlob);
    const i = dataURL.indexOf('base64,');
    yield put(userActions.receiveUserAvatar(dataURL.slice(i + 7))); // Remove starting data:*/*; base64, from string
}

export const scanUploadedAvatar = async (avatars) => {
    let maxApiCall = 4;
    let payload = [];

    for (let current of avatars) {
        const result = await scanAvatar({ key: current.key });
        if (result !== 'error') {
            if (result?.data?.status === 'INPROGRESS') {
                for (let i = 0; i < maxApiCall; i++) {
                    const result = await new Promise(function (resolve) {
                        setTimeout(async function () {
                            let output = await scanAvatar({ key: current.key });
                            resolve(output);
                        }, 2000);
                    });
                    if (result?.data?.status !== 'INPROGRESS') {
                        payload.push({
                            filename: current.filename,
                            status: result?.data ? result.data.status : 'API_ERROR',
                            key: current.key
                        });
                        break;
                    } else if (i === maxApiCall - 1) {
                        payload.push({ filename: current.filename, status: 'TIMEOUT', key: current.key });
                    }
                }
            } else {
                payload.push({ filename: current.filename, status: result?.data?.status, key: current.key });
            }
        }
        else {
            payload.push({ filename: current.filename, status: 'API_ERROR', key: current.key });
        }
    }

    return payload;
};

export function* handleGenerateUploadAvatarLinks(action) {
    try {
        const file = action.payload
        const avatar = file[0];
        const uploadFile = new Blob([avatar], { type: avatar.type });

        const getUploadLinks = yield generateUploadLink(1);
        const uploadKey = getUploadLinks[0].key;
        const uploadUrl = getUploadLinks[0].url;
        const avatarUpload = yield uploadAvatar(uploadUrl,uploadFile);
        const avatarFile = [{ 
            key: uploadKey,
            filename: file[0]?.name 
        }]

        if (avatarUpload !== 'error') {
            const scanAvatarResponse = yield call(scanUploadedAvatar,avatarFile);
            const correctFileData = [];
            const correctFileWithKey = [];
            scanAvatarResponse.forEach(val => {
                if (val.status === 'SUCCESS') {
                    file.forEach(obj => {
                        if (obj.name === val.filename) {
                            correctFileData.push(obj);
                            correctFileWithKey.push({
                                ...obj,
                                key: uploadKey
                            })
                        }
                    });
                }
            }
            );

            const malwareData = scanAvatarResponse.filter(obj => obj.status === 'FAILED');
            const apiErrorData = scanAvatarResponse.filter(obj => obj.status === 'API_ERROR');
            const timeoutError = scanAvatarResponse.filter(obj => obj.status === 'TIMEOUT');
            yield put(userActions.scannedAvatar({ correctFileData, correctFileWithKey, malwareData, apiErrorData, timeoutError }));
        }  else {
            yield put(userActions.avatarUploadFailed());
        }
    } catch {
        yield put(userActions.avatarUploadFailed());
    }
}


export default function* userApiSagas() {
    yield all([
        takeEvery(`${actions.fetchUserDetails}`, fetchUserDetails),
        takeEvery(`${actions.fetchUserAvatar}`, fetchUserAvatar),
        takeEvery(`${actions.updateUserAvatar}`, updateUserAvatar),
        takeEvery(`${actions.generateUploadAvatarLinks}`, handleGenerateUploadAvatarLinks)
    ]);
}
