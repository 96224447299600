import { all, put, call, takeEvery } from 'redux-saga/effects';
import * as actions from './actions';
import * as widgetNotificationsActions from '../../actions/notifications';
import * as notificationsApiService from 'services/notificationsApiService';
import { genericErrorMessage } from './util';

function* fetchActiveWidgetNotifications(action) {
    const { userId, showDocStoreNotifications } = action.payload;
    try {
        if (showDocStoreNotifications) {
            const response = yield call(notificationsApiService.retrieveActiveWidgetNotifications, userId);
            yield put(widgetNotificationsActions.receiveActiveWidgetNotifications({ response, status: 'ok' }));
        } else {
            yield put(widgetNotificationsActions.receiveActiveWidgetNotifications({}));
        }
    } catch (e) {
        yield put(widgetNotificationsActions.receiveActiveWidgetNotifications({ status: 'error' }));
    }
}

function* updateWidgetNotifications(action) {
    const { type, value, userId } = action.payload;
    try {
        yield put(widgetNotificationsActions.updateActiveWidgetNotifications({ [type]: value }));
        yield call(notificationsApiService.updateSelectedWidgetNotifications, userId, { [type]: value });
    } catch (error) {
        yield put(widgetNotificationsActions.updateActiveWidgetNotifications({ [type]: !value }));
        yield genericErrorMessage(error);
    }
}

function* fetchFundsNotifications(action) {
    const { userId, isExternal } = action.payload;

    try {
        if (isExternal) {
            const response = yield call(notificationsApiService.retrieveFundsNotifications, userId);
            yield put(widgetNotificationsActions.receiveFundNotifications({ response, status: 'ok' }));
        } else {
            yield put(widgetNotificationsActions.receiveFundNotifications({}));
        }
    } catch (e) {
        yield put(widgetNotificationsActions.receiveFundNotifications({ status: 'error' }));
    }
}

function* updateFundsNotifications(action) {
    const { userId, object } = action.payload;

    try {
        yield put(widgetNotificationsActions.updateFundNotifications(object));
        const response = yield call(notificationsApiService.updateFundsNotifications, userId, object);
    } catch (error) {
        yield genericErrorMessage(error);
    }
}

export default function* notificationsApiSagas() {
    yield all([
        takeEvery(`${actions.updateWidgetNotifications}`, updateWidgetNotifications),
        takeEvery(`${actions.fetchActiveWidgetNotifications}`, fetchActiveWidgetNotifications),
        takeEvery(`${actions.fetchFundsNotifications}`, fetchFundsNotifications),
        takeEvery(`${actions.updateFundsNotifications}`, updateFundsNotifications),
    ]);
}
