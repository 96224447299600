export default function normalize(data, idKey) {
    const byId = data.reduce((acc, item) => ({
        ...acc,
        [item[idKey]]: item
    }), {});

    const allIds = data.map(item => item[idKey]);

    return {
        byId,
        allIds
    };
}
