import * as authService from './authenticationService';
import axios from 'axios';
import { assocPath, path } from 'ramda';

const request = async (config) => {
    const jwt = await authService.jwtToken();
    const updatedConfig = path(['headers', 'Authorization'], config) ? config : assocPath(['headers', 'Authorization'], jwt, config);
    return axios.request(updatedConfig);
};

const get = (url, config) => request(Object.assign({}, config, { method: 'get', url }));
const del = (url, config) => request(Object.assign({}, config, { method: 'delete', url }));
const head = (url, config) => request(Object.assign({}, config, { method: 'head', url }));
const options = (url, config) => request(Object.assign({}, config, { method: 'options', url }));
const post = (url, data, config) => request(Object.assign({}, config, { method: 'post', url, data }));
const put = (url, data, config) => request(Object.assign({}, config, { method: 'put', url, data }));
const patch = (url, data, config) => request(Object.assign({}, config, { method: 'patch', url, data }));

const service = {
    get, delete: del, head, options, post, put, patch
};
export default service;