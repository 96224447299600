import defaultFundSorting from 'data/defaultFundSorting.js';

const state = {
    auth: {
        code: null,
        error: null,
        username: '',
        password: '',
        pathname: null,
        emailForgot: '',
        isFetching: false,
        isPrepared: false,
        isLoggedIn: false,
        resetPassword: false,
        user: {
            project: '',
            multipleClients: true,
            isAdmin: false,
            isContributor: false,
            isAltisAuthorized: false,
        },
        newPasswordRequired: false,
        resetPasswordConfirm: false,
    },
    preferences: {
        preferenceCategories: [],
        userPreferences: [],
        loadingStatus: 'loading',
    },
    gdpr: {
        gaveGDPRConsent: false,
        hasFunctionalGDPRConsent: false,
        hasFullGDPRConsent: false,
    },
    addportalaccount: {
        step: 1,
        data: {},
        totalSteps: 4,
        accountsAddedFailed: false,
        accountsAddedCompleted: false,
        client: [],
        prospect: [],
        correctFileData: [],
        correctFileWithKey: [],
        malwareData: [],
        apiError: false,
        timeoutError: false
    },
    adddocument: {
        stepId: 0,
        data: {},
        documentUploadFailed: false,
        documentUploadCompleted: false,
        selectedClientIds: new Set(),
        categoriesList: [],
        malwareData : [],
        apiError : false,
        correctFileData: [],
        correctDocumentData: {
            documents : []
        },
        timeoutError: false
    },
    addkycdocument: {
        stepId: 0,
        kycData: {},
        kycdocumentUploadFailed: false,
        kycdocumentUploadCompleted: false,
        selectedClientIds: new Set(),
        categoriesList: [],
        correctKYCFileData: [],
        correctKYCDocumentData: {
            documents : []
        },
        malwareKYCData: [],
        apiError: false,
        timeoutError: false
    },
    addrequest: {
        isEditMode: false,
        step: 1,
        metadata: {
            title: '',
            descriptionWhatAndHow: '',
            descriptionWhy: '',
            // isSensitive: false,
            isUrgent: false,
            files: [],
        },
        sendNotifications: false,
        requestParams: {},
        requestUploadFailed: false,
        requestUploadCompleted: false,
        requestType: '',
        totalSteps: 2,
        correctRequestFileData: [],
        correctRequestFileWithKey: [],
        malwareData: [],
        apiError: false,
        timeoutError: false
    },
    clientAccounts: {
        loading: false,
        state: null,
        data: [],
        currentClient: null,
        isUnauthorised: false,
        unauthorisedReason: '',
    },
    clientWidgets: {
        widgetSettings: null,
        enabledWidgets: null,
        disabledWidgets: [],
        enabledWidgetsLoaded: false,
        externalRole: {},
    },
    widgetNotifications: {
        activeWidgetsNotifications: {},
        activeWidgetsNotificationsStatus: 'loading',
        fundNotifications: [],
        fundNotificationsStatus: 'loading',
    },
    documentManagement: {
        status: null,
        documents: [],
        KYCdocument: [],
        documentsKYCTotal: 0,
        deleting: new Set(),
        downloading: new Set(),
        documentsTotal: 0,
        categoriesList: [],
    },
    documentInformation: {
        documentData: {
            revisions: [{}],
            categories: [],
        },
        requestDetails: [],
        singleDocDetails: [],
        category: '',
        documentType: '',
        displayStatus: '',
        revisionStatus: '',
        isRequestingRevision: false,
        isRevisingSubmission: false,
        accessList: [],
        totalAccessOutsideRegion: null,
        grantCompleted: false,
        loadingStatus: 'loading',
        uploadedDocuments: { files: null, documents: [] },
        previewURL: {},
        correctFileWithKey: [],
        correctFileData: [],
        malwareData: [],
        apiError: false,
        timeoutError: false
    },
    documentsFilters: {
        appliedFilters: {
            endDateObj: null,
            startDateObj: null,
            categoryId: null,
            startFrom: 0,
            currentPageNumber: 1,
            searchQuery: '',
        },
    },
    notifications: {
        activeNotifications: [],
    },
    user: {
        details: {},
        avatar: null,
        correctFileData: [],
        correctFileWithKey: [],
        malwareData: [],
        apiError: false,
        timeoutError: false
    },
    usersManagement: {
        allUsers: [],
        internalUsers: [],
        loadingStatus: 'loading',
    },
    contentful: {
        videoInDashboard: null,
        videosIds: [],
        onePager: [],
        getInTouch: [],
    },
    process: {
        isNewProcess: false,
        loadingStatus: 'loading',
        data: null,
        submitted: [],
        requested: [],
        totalRequested: 0,
    },
    editProcess: {
        loadingStatus: 'loading',
        processUploadCompleted: false,
        data: {
            type: 'ONBOARDING',
            description: '',
            workstreams: [
                {
                    type: 'legal',
                    active: true,
                    startDate: null,
                    endDate: null,
                    description: '',
                },
                {
                    type: 'operational',
                    active: true,
                    startDate: null,
                    endDate: null,
                    description: '',
                },
            ],
            dealCaptains: [],
        },
    },
    funds: {
        myFundsLoading: true,
        myWatchlistLoading: true,
        allFundsLoading: true,
        errors: {
            myFundsError: null,
            myWatchlistError: null,
            allFundsError: null,
        },
        myFundsLocation: null, // DATABASE or API
        myFundsIds: [], // ids of funds that are displayed in My Funds section, data comes from allFundsData.byId
        myWatchlistIds: [], // ids of funds that are displayed in My Watchlist section, data comes from allFundsData.byId
        allFundsData: {
            allIds: [], // ids of funds that are displayed in All Funds section
            byId: {}, // combination on all data from All Funds, My Watchlist and My Funds sections
            allFundsTotal: 0, // Total of all funds available for a user
        },
        myFundsSorting: JSON.parse(window.localStorage.getItem('myFundsSorting')) || defaultFundSorting,
        myWatchlistSorting: JSON.parse(window.localStorage.getItem('myWatchlistSorting')) || defaultFundSorting,
        allFundsSorting: JSON.parse(window.localStorage.getItem('allFundsSorting')) || defaultFundSorting,
        filters: {
            assetClass: [],
            shareClass: [],
        },
        wholesaleMasterlistLoading: true,
        wholesaleMasterlist: [],
    },
    fundDetails: {
        loadingStatus: true,
        fund: null,
        fundDocuments: [],
        totalCount: null,
    },
    fundDetailsAltis: {
        loadingStatus: true,
        fund: null,
    },
};

export default state;
