import React from 'react';
import RoleBasedRoute from 'components/_routes/RoleBasedRoute';

const AdminRoute = ({ component: Component, auth, ...rest }) => {
    const { isLoggedIn, user: { isAdmin, isContributor, clientId, multipleClients  } } = auth;

    return (
        <RoleBasedRoute
            hasRole={ isAdmin || isContributor }
            isLoggedIn={ isLoggedIn }
            clientId={ clientId }
            multipleClients={ multipleClients }
            component={ Component }
            {...rest}
        />
    );
};

export default AdminRoute;
