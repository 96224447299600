import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import Article from 'components/templates/Article';
import ErrorInfoMolecule from 'components/molecules/Overview/ErrorInfo';

/** Error boundry component to catch any errors and display a message to the user */
class ErrorBoundary extends Component {
    state = {
        error: '',
        errorInfo: '',
        hasError: false,
    };

    /** Receives the error and updates the state variables */
    static getDerivedStateFromError(error) {
        return { hasError: true, error };
    }

    /**  receives the error, and errorInfo which is an object with a componentStack key containing information about which component threw the error */
    componentDidCatch(error, errorInfo) {
        // eslint-disable-next-line no-console
        console.log({ error, errorInfo });
        this.setState({ errorInfo });
    }

    render() {
        const { hasError, errorInfo } = this.state;
        if (hasError) {
            const { t } = this.props;
            return (
                <Article pageTitle={t('csp.errors.somethingWentWrong')}>
                    <ErrorInfoMolecule dataLoaded={true}>
                        <ErrorInfoMolecule.ErrorBoundary errorInfo={errorInfo} />
                    </ErrorInfoMolecule>
                </Article>
            );
        }

        return this.props.children;
    }
}
ErrorBoundary.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

export default withTranslation()(ErrorBoundary);
