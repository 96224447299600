import { createReducer } from 'redux-act';
import states from 'data/reduxstates';
import { fetchProcessDocuments, fetchActiveProcessDetails } from '../sagas/api/actions';

import partitionArray from 'components/_helpers/partitionArray';

import {
    updateProcessState,
    receiveProcessDocuments,
    receiveEmptyProcessDetails,
    receiveActiveProcessDetails,
    receiveProcessDocumentsTotal,
} from '../actions';

// Content
import dictionary from 'data/dictionary.json';

const processReducer = createReducer(
    {
        [fetchProcessDocuments]: (state) => ({ ...state, loadingStatus: 'loading' }),
        [fetchActiveProcessDetails]: (state) => ({ ...state, loadingStatus: 'loading' }),
        [receiveEmptyProcessDetails]: (state, payload) => ({
            ...state,
            isNewProcess: true,
            data: null,
            loadingStatus: 'emptyLoaded',
        }),
        [updateProcessState]: (state, payload) => ({ ...state, ...payload }),
        [receiveActiveProcessDetails]: (state, payload) => {
            payload.workstreams = payload.workstreams.map((stream) => {
                stream.title = dictionary.process.editWizard[stream.type.toLowerCase()].label;
                stream.startDate = new Date(stream.startDate);
                stream.endDate = new Date(stream.endDate);
                return stream;
            });
            return { ...state, data: payload, isNewProcess: false, loadingStatus: 'loading' };
        },
        [receiveProcessDocuments]: (state, payload) => {
            const [requested, submitted] = partitionArray(
                payload,
                (item) =>
                    item.state === 'NNIP_REQUESTED' ||
                    item.state === 'REVISION_REQUIRED' ||
                    (item.state === 'CLIENT_SUBMISSION_IN_PROGRESS' && item.isClientInitiated === false)
            );
            return { ...state, requested: requested, submitted: submitted, loadingStatus: 'loaded' };
        },
        [receiveProcessDocumentsTotal]: (state, payload) => {
            return { ...state, totalRequested: payload, loadingStatus: 'loaded' };
        },
    },
    states.process
);

export default processReducer;
