import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import './content/i18n';

import configureStore from 'src/redux/configureStore';
import CookieConsent from './components/atoms/CookieConsent';
import App from './App';
import 'src/assets/stylesheets/Default';
import { adobeScriptIntegration } from './components/atoms/AdobeAnalytics/adobeScriptIntegration';


let store = configureStore();
const app = document.getElementById('app');

adobeScriptIntegration();

render(
    <Provider store={store}>
        <CookieConsent>
            <CookieConsent.Banner />
        </CookieConsent>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>,
    app
);
