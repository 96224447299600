/*********************************************************************/
/*                                                                   */
/*             SWAGGER DOCUMENTATION ON USER ENDPOINTS               */
/*        https://nn-ip.docs.aws.insim.biz/csp-user-accounts/        */
/*                                                                   */
/*********************************************************************/

import axios from 'axios';
import http from './httpService';
import settings from '../settings';

const retrieveUsersApiUrl = settings.DOMAIN + '/management/csp-accounts-api/v1/users';
const generateLinksApi = settings.DOMAIN + '/csp-accounts-api/v1/generate-links';

const externalUserRole = settings.DOMAIN + '/csp-accounts-api/v1/external-user-role';
const getUser = settings.DOMAIN + '/management/csp-accounts-api/v1/users/getUser';

const uploadFileStatusApiUrl = settings.DOMAIN + '/csp-accounts-api/v1/file-status';

export const retrieveUsers = (body) => http.get(retrieveUsersApiUrl, body).then(response => response.data).catch(error => error);
export const retrieveInternalUsers = (body) => http.get(retrieveUsersApiUrl + '?filterBy=INTERNAL', body).then(response => response.data);
//export const retrieveUser = (id) => http.get(retrieveUsersApiUrl + '/' + id).then(response => response.data);


export const patchUserStatus = (body) => http.patch(retrieveUsersApiUrl, body).then(response => response.data);

export const generateUploadLink = (amount) => http.post(generateLinksApi, { 'numberOfUploads': amount }).then(response => response.data).catch(error => error);
export const retrieveUserPaAccessBlock = (id, body) => http.patch(retrieveUsersApiUrl + '/client-accounts', { ...body, userId: id }).then(response => response.data).catch(error => error);
export const uploadAvatar = (url, body) => axios.put(url, body, { headers: { 'Content-Type': 'application/octet-stream', 'x-amz-server-side-encryption': 'AES256' } }).then(response => response.data).catch(error => 'error');
//export const uploadAvatarMetadata = (id, key) => http.post(retrieveUsersApiUrl + '/' + id + '/avatar', { 'uploadKey': key, 'crop': { 'width': 240, 'height': 240, 'x': 0, 'y': 0 } }).then(response => response.data).catch(error => 'error');
export const refreshUserInformation = (id, body) => http.post(retrieveUsersApiUrl + '/refresh', { body, userId: id }).then(response => response).catch(error => error.response || { status: 500 });
export const deleteUser = (id) => http.post(retrieveUsersApiUrl + '/deleteUser', { userId: id }).then(response => response.data).catch(error => 'error');

// External user role
//export const retrieveExternalUserRole = (params) => http.get(externalUserRole, { params }).then(response => response.data);
export const patchExternalUserRole = (body) => http.patch(externalUserRole, body).then(response => response.data);

export const retrieveExternalUserRole = (body) => http.post(externalUserRole, body).then(response => response.data);
export const retrieveUser = (body) => http.post(getUser, body).then(response => response.data);
export const retrieveUserAvatar = (body) => http.post(retrieveUsersApiUrl + '/getAvatar', body).then(response => response.data).catch(error => error);
export const retrieveUserPaAccess = (body) => http.post(retrieveUsersApiUrl + '/get-client-account-user', body).then(response => response.data).catch(error => error);
export const uploadAvatarMetadata = (id, key) => http.post(retrieveUsersApiUrl + '/postAvatar', { 'uploadKey': key, 'userId': id, 'crop': { 'width': 240, 'height': 240, 'x': 0, 'y': 0 } }).then(response => response.data).catch(error => 'error');
export const scanAvatar = (body) => http.post(uploadFileStatusApiUrl, body).then(response => response).catch(error => 'error');