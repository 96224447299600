import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { dismissNotification } from '@/redux/actions/notifications';
import { undoAction } from '@/redux/sagas/common/actions';

import NotificationsWrapperAtom from 'components/atoms/Notifications/NotificationsWrapper';
import NotificationAtom from 'components/atoms/Notifications/Notification';

const NotificationsComponent = () => {
    const activeNotifications = useSelector((state) => state.notifications.activeNotifications);
    const dispatch = useDispatch();
    if (!activeNotifications.length) return null;

    const clickAway = (id) => {
        dispatch(dismissNotification({ id }));
    };

    const handleUndo = (id) => {
        dispatch(undoAction({ id }));
    };

    return (
        <NotificationsWrapperAtom>
            {activeNotifications.map((n, i) => (
                <NotificationAtom
                    key={i}
                    type={n.type}
                    remove={n.remove}
                    handleUndo={() => handleUndo(n.id)}
                    onClick={() => clickAway(n.id)}>
                    {n.message}
                </NotificationAtom>
            ))}
        </NotificationsWrapperAtom>
    );
};

export default NotificationsComponent;
