import { createReducer } from 'redux-act';
import states from 'data/reduxstates';

import {
    login,
    logout,
    fetchUser,
    backToLogin,
    resetPassword,
    forgotPassword,
    failFetchingUser,
    changePasswordStatus,
    newPasswordRequired,
    confirmResetPassword,
    resetPasswordCompleted,
    failFetchingLoginState
} from '../actions';

import { createLoginObject } from '../sagas/Auth';

const authReducer = createReducer(
    {
        [failFetchingLoginState]: (state, payload) => {
            return Object.assign({}, states.auth, {
                isPrepared: true,
                pathname: payload.pathname
            });
        },
        [fetchUser]: state => {
            return Object.assign({}, state, {
                isFetching: true,
                error: undefined
            });
        },
        [failFetchingUser]: (state, err) => {
            return Object.assign({}, state, {
                error: err,
                isFetching: false
            });
        },
        [changePasswordStatus]: (state, payload = {}) => {
            return Object.assign({}, state, {
                code: payload.code,
                error: payload.error
            });
        },
        [login]: (state, payload) => {
            payload.user = payload.user || state.user;
            console.log(payload.differenceInDays, "from redux")
            const differenceInDays = payload?.differenceInDays;
            return Object.assign({}, state, createLoginObject(payload, differenceInDays));
        },
        [logout]: () =>
            Object.assign({}, states.auth, {
                isPrepared: true
            }),
        [forgotPassword]: state =>
            Object.assign({}, state, {
                error: undefined,
                resetPassword: false,
                resetPasswordConfirm: false
            }),
        [resetPassword]: (state, payload) => {
            return Object.assign({}, state, {
                emailForgot: payload.email
            });
        },
        [confirmResetPassword]: (state, email) => {
            return Object.assign({}, state, {
                error: undefined,
                emailForgot: email,
                resetPassword: true
            });
        },
        [resetPasswordCompleted]: (state, payload) => {
            return Object.assign({}, state, {
                resetPasswordConfirm: true
            });
        },
        [newPasswordRequired]: (state, payload) => {
            return Object.assign({}, state, {
                username: payload.email,
                newPasswordRequired: true,
                isFetching: false,
                password: payload.password
            });
        },
        [backToLogin]: (state) => {
            return Object.assign({}, state, {
                error: undefined
            });
        }
    },
    states.auth
);

export default authReducer;