/* eslint-disable no-unused-vars */
/* eslint-disable no-console */

/*********************************************************************/
/*                                                                   */
/*             SWAGGER DOCUMENTATION ON USER ENDPOINTS               */
/*        https://nn-ip.docs.aws.insim.biz/csp-user-accounts/        */
/*                                                                   */
/*********************************************************************/

import http from './httpService';
import settings from 'src/settings';

const userApiAvatar = settings.DOMAIN + '/csp-accounts-api/v1/user-avatar/';
const userApiDetail = settings.DOMAIN + '/csp-accounts-api/v1/user-details';
const userListUrl = settings.DOMAIN + '/management/csp-accounts-api/v1/users/';

export const retrieveUsersList = () => http.get(userListUrl).then(response => response.data);
export const retrieveUserAvatar = () => http.get(userApiAvatar).then(response => response.data);
export const retrieveCurrentUser= () => http.get(userApiDetail).then(response => response.data);