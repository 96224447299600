require('assets/favicon/android-chrome-192x192.png');
require('assets/favicon/android-chrome-512x512.png');
require('assets/favicon/apple-touch-icon.png');
require('assets/favicon/apple-touch-icon-57x57.png');
require('assets/favicon/apple-touch-icon-60x60.png');
require('assets/favicon/apple-touch-icon-72x72.png');
require('assets/favicon/apple-touch-icon-76x76.png');
require('assets/favicon/apple-touch-icon-114x114.png');
require('assets/favicon/apple-touch-icon-120x120.png');
require('assets/favicon/apple-touch-icon-144x144.png');
require('assets/favicon/apple-touch-icon-152x152.png');
require('assets/favicon/apple-touch-icon-180x180.png');
require('assets/favicon/favicon.ico');
require('assets/favicon/favicon-16x16.png');
require('assets/favicon/favicon-32x32.png');
require('assets/favicon/mstile-70x70.png');
require('assets/favicon/mstile-144x144.png');
require('assets/favicon/mstile-150x150.png');
require('assets/favicon/mstile-310x150.png');
require('assets/favicon/mstile-310x310.png');
require('assets/favicon/safari-pinned-tab.svg');