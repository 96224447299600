/* eslint-disable no-console */
import { CognitoUser, CognitoUserPool, CognitoRefreshToken } from 'amazon-cognito-identity-js';
import settings from '@/settings';
import cognitoStorage from '../redux/sagas/SessionStorage';

const promisify = (method) => {
    return function (...args) {
        return new Promise((resolve, reject) => {
            args.push((err, data) => {
                if (err)
                    reject(err);
                else
                    resolve(data);
            });
            this[method](...args);
        });
    };
};

// Make Cognito calls into promises
CognitoUser.prototype.getSessionP = promisify('getSession');
CognitoUser.prototype.getUserAttributesP = promisify('getUserAttributes');
CognitoUser.prototype.refreshSessionP = promisify('refreshSession');

const getUserPool = () => new CognitoUserPool({
    UserPoolId: settings.USER_POOL_ID,
    ClientId: settings.CLIENT_ID,
    Storage: cognitoStorage
});

/**
 * Returns the jwt-idToken of the currently logged in user
 */
export const jwtToken = async () => {
    const userPool = getUserPool();
    const user = userPool.getCurrentUser();
    if (!user) return null;

    const session = await user.getSessionP();
    if (!session.isValid()) {
        const refreshToken = session.getRefreshToken();
        const newSession = await user.refreshSessionP(refreshToken);
        return newSession.getIdToken().getJwtToken();
    }

    return session.getIdToken().getJwtToken();
};


function getCognitoUser(user) {

    const userPool = getUserPool();
    const userData = {
        Username: user,
        Pool: userPool,
        Storage: cognitoStorage
    };
    return new CognitoUser(userData);
}

export function replaceRefreshToken(refreshToken) {

    const cognitoUser = getCognitoUser('adfs-user');
    cognitoUser.signOut();
    console.log('SignOut performed', cognitoUser);

    const rt = new CognitoRefreshToken({ RefreshToken: refreshToken });

    return cognitoUser.refreshSessionP(rt);
}
