import http from './httpService';
import settings from '../settings';

const uploadMetadataApiUrl = settings.DOMAIN + '/csp-doc-management-api/v1/upload/document-request';
const uploadClientDocumentMetadataApiUrl =
    settings.DOMAIN + '/csp-doc-management-api/v1/upload/client-document-request';
const submitBulkKYCRequestApiUrl = settings.DOMAIN + '/csp-doc-management-api/v1/upload/bulk-document-request';
export const uploadRequest = (body) => http.post(uploadMetadataApiUrl, body);
export const uploadClientDocument = (body) => http.post(uploadClientDocumentMetadataApiUrl, body);
export const submitBulkKYCRequest = (body) => http.post(submitBulkKYCRequestApiUrl, body);
export const uploadEditedRequest = (docId, body) =>
    http.patch(`${uploadMetadataApiUrl}/${docId}`, body).then((response) => response);
