import { put } from 'redux-saga/effects';
import * as commonActions from '@/redux/sagas/common/actions';

export function* genericErrorMessage(e) {
    const message =
        (e && e.response && e.response.data && e.response.data.message) ||
        (e && e.response && e.response.message) ||
        (e && e.message) ||
        'Something went wrong when retrieving data from the server. Please try again or contact support if the problem persists.';

    yield put(
        commonActions.createNotification({
            type: 'error',
            message,
        })
    );
}

export function* genericSuccessMessage(message = 'Success') {
    yield put(
        commonActions.createNotification({
            type: 'success',
            message,
        })
    );
}

export function* genericUndoMessage(message = 'Success') {
    yield put(
        commonActions.createNotification({
            type: 'undo',
            message,
        })
    );
}

export function* genericMessage(message = '', type = 'error') {
    yield put(
        commonActions.createNotification({
            type: type,
            message,
        })
    );
}
