import { createReducer } from 'redux-act';
import states from 'data/reduxstates';
import { fetchProcessDetails, uploadProcess } from '../sagas/api/actions';

import {
    startEditWizard,
    updateEditProcess,
    addNewProcess,
    receiveProcess,
    addProcessUploadFailed,
    addProcessUploadCompleted
} from '@/redux/actions/index';

// Content
import dictionary from 'data/dictionary.json';
const { process: { editWizard } } = dictionary;

const editProcessReducer = createReducer(
    {
        [fetchProcessDetails]: (state) => ({ ...state, loadingStatus: 'loading' }),
        [uploadProcess]: (state) => ({ ...state, loadingStatus: 'loading' }),
        [startEditWizard]: (state, payload) => {
            const defaultState = {
                loadingStatus: 'loading',
                processUploadCompleted: false,
                data: {
                    type: 'ONBOARDING',
                    description: '',
                    workstreams: [
                        {
                            type: 'legal',
                            active: true,
                            startDate: null,
                            endDate: null,
                            description: editWizard.legal.defaultValue
                        },
                        {
                            type: 'operational',
                            active: true,
                            startDate: null,
                            endDate: null,
                            description: editWizard.operational.defaultValue
                        }
                    ],
                    dealCaptains: []
                },
            };

            return { ...state, ...defaultState, clientId: payload };
        },
        [addNewProcess]: (state, payload) => {
            return { ...state, loadingStatus: 'loaded' };
        },
        [updateEditProcess]: (state, payload) => {
            return { ...state, ...payload };
        },
        [receiveProcess]: (state, payload) => {
            payload.workstreams = payload.workstreams.map((stream) => {
                const { type, description, startDate, endDate } = stream;
                stream.description = description ? description : editWizard[type.toLowerCase()].defaultValue;
                stream.startDate = startDate ? new Date(startDate) : null;
                stream.endDate = endDate ? new Date(endDate) : null;
                return stream;
            });
            return ({ ...state, data: payload, loadingStatus: 'loaded' });
        },
        [addProcessUploadFailed]: (state) => {
            return { ...state, loadingStatus: 'error' };
        },
        [addProcessUploadCompleted]: (state) => {
            return { ...state, processUploadCompleted: true };
        }
    },
    states.editProcess
);

export default editProcessReducer;
