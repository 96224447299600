import React from 'react';
import { Route } from 'react-router-dom';
import HelmetHead from '../molecules/Head/HelmetHead';

const PublicRoute = ({ component: Component, title, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) => {
                return (
                    <>
                        <HelmetHead title={title} />
                        <Component {...props} />
                    </>
                );
            }}
        />
    );
};

export default PublicRoute;
