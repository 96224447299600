import React from 'react';
import RoleBasedRoute from 'components/_routes/RoleBasedRoute';

const AdminRoleRoute = ({ component: Component, auth, ...rest }) => {
    const { isLoggedIn, user: { isAdmin, clientId, multipleClients } } = auth;

    return (
        <RoleBasedRoute
            hasRole={ isAdmin }
            isLoggedIn={ isLoggedIn }
            clientId={ clientId }
            multipleClients={ multipleClients }
            component={ Component }
            {...rest}
        />
    );
};

export default AdminRoleRoute;
