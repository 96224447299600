/* eslint-disable no-unused-vars */
/* eslint-disable no-console */

import { call, put, take, takeEvery } from 'redux-saga/effects';
import {
    retrieveUploadUrls,
    uploadDocument as uploadDoc,
    uploadMetadata as uploadDocumentMetadata,
    uploadKYCMetadata,
    uploadEditedKYCMetadataApi,
    scanDocument
} from '../../services/addDocumentApiService';
import { map, assoc, range } from 'ramda';
import {
    addDocumentStepMetadata,
    addKYCDocumentData,
    addKYCDocumentUpload,
    addKYCDocumentCompleted,
    addKYCDocumentFailed,
    addDocumentUpload,
    addDocumentFailed,
    addDocumentCompleted,
    generateUploadLinks,
    generateKYCUploadLinks,
    clearKYCDocumentData,
    scanDocumentAction,
    scannedDocuments,
    scanKYCDocumentAction,
    scannedKYCDocuments
} from '../actions';

export const setProgress = (max, currentWidth) => {
    let i = currentWidth,
        start = setInterval(progress, 10);

    function progress() {
        if (i >= max) {
            clearInterval(start);
        } else {
            i++;
            document.querySelector('.progress__bar').style.width = Math.trunc(i) + '%';
            document.querySelector('.progress__bar__label').innerHTML = Math.trunc(i) + '%';
        }
    }

    return i;

};
const handleLinkGeneration = (actionPayload, downloadPayload) => {
    const { files, documents } = actionPayload;
    files.forEach((file, i) => {
        file.url = downloadPayload.upload[i].url;
    }); // Need to preserve instance of File
    const arrUploads = files.map((file) => ({
        file,
        url: file.url,
    }));
    const docsWithKey = map(i => assoc('key', downloadPayload.upload[i].key, documents[i]), range(0, downloadPayload.upload.length));
    return { files, documents: docsWithKey, upload: arrUploads };

};
export const getDownloadUrls = async (amount) => {
    try {
        const result = await retrieveUploadUrls({ numberOfUploads: amount });
        if (result) {
            const payload = {
                upload: result
            };
            return { payload };
        }
    } catch (err) {
        return { payload: null, err };
    }
};

export const uploadDocument = (current, array, i) => {
    let totalProgress = ((100 / (array.length + 1)) * i),
        blob = new Blob([current.file], { type: current.file.type });
    return uploadDoc(blob, current.url).then(response => totalProgress).catch(error => 'error');
};

export const uploadMetadata = async (array, isKYCMetadata, isEditMode, documentID,clientId) => {
    if (isEditMode && isKYCMetadata) {
        return uploadEditedKYCMetadataApi(array, documentID).then(response => 100).catch(error => 'error');
    }

    return isKYCMetadata ?
        uploadKYCMetadata(array,clientId).then(response => 100).catch(error => 'error')
        : uploadDocumentMetadata(array,clientId).then(response => 100).catch(error => 'error');
};

export const uploadSelectedDocuments = async (array) => {
    let i = 1,
        j = 0,
        payload = {},
        failed = false;
    for (let current of array) {
        const result = await uploadDocument(current, array, i++);

        if (result !== 'error') {
            //setProgress(result, ((100 / array.length) * j++));
        } else {
            failed = true;
        }

        payload['arrayLength'] = array.length;
    }

    payload['documentsUploadFailed'] = failed;
    payload['documentsUploaded'] = !failed;

    return payload;
};

export const scanUploadedDocument = async (documents) => {
    let maxApiCall = 4;
    let payload = [];

    for (let current of documents) {
        const result = await scanDocument({ key: current.key });
        if (result !== 'error') {
            if (result.data.status === 'INPROGRESS') {
                for (let i = 0; i < maxApiCall; i++) {
                    const result = await new Promise(function (resolve) {
                        setTimeout(async function () {
                            let output = await scanDocument({ key: current.key });
                            resolve(output);
                        }, 2000);
                    });
                    if (result?.data?.status !== 'INPROGRESS') {
                        payload.push({
                            filename: current.filename,
                            status: result?.data ? result.data.status : 'API_ERROR',
                            key: current.key
                        });
                        break;
                    } else if (i === maxApiCall - 1) {
                        payload.push({ filename: current.filename, status: 'TIMEOUT', key: current.key });
                    }
                }
            } else {
                payload.push({ filename: current.filename, status: result.data.status, key: current.key });
            }
        }
        else {
            payload.push({ filename: current.filename, status: 'API_ERROR', key: current.key });
        }
    }

    return payload;
};

export const uploadSelectedMetadata = async (array, amount = 0, isKYCMetadata, isEditMode, documentID, clientId) => {
    let payload = {},
        failed = false;
    const result = await uploadMetadata(array, isKYCMetadata, isEditMode, documentID, clientId);

    if (result !== 'error') {
        setProgress(result, ((100 / (amount + 1)) * amount));
    } else {
        failed = true;
    }

    payload['documentsUploadFailed'] = failed;
    payload['documentUploadCompleted'] = !failed;

    return payload;
};

export function* handleGenerateUploadLinks() {
    while (true) {
        //const action = yield take(`${generateUploadLinks}`);
        const action = yield take(`${scanDocumentAction}`);
        const { payload } = yield call(getDownloadUrls, action.payload.amount);
        const object = handleLinkGeneration(action.payload, payload);
        const { files, documents, upload } = object;
        const { arrayLength, documentsUploaded, documentsUploadFailed } = yield call(uploadSelectedDocuments, upload);
        //yield put(addDocumentStepMetadata({ files, documents }));
        if (documentsUploaded) {
            const scanDocumentResponse = yield call(scanUploadedDocument, documents);
            const correctFileData = [];
            const correctDocumentData = [];
            scanDocumentResponse.forEach(val => {
                if (val.status === 'SUCCESS') {
                    files.forEach(obj => {
                        if (obj.name === val.filename) {
                            correctFileData.push(obj);
                        }
                    });
                }
            }
            );

            scanDocumentResponse.forEach(val => {
                if (val.status === 'SUCCESS') {
                    documents.forEach(obj => {
                        if (obj.filename === val.filename) {
                            correctDocumentData.push(obj);
                        }
                    });
                }
            });
            const malwareData = scanDocumentResponse.filter(obj => obj.status === 'FAILED');
            const apiErrorData = scanDocumentResponse.filter(obj => obj.status === 'API_ERROR');
            const timeoutError = scanDocumentResponse.filter(obj => obj.status === 'TIMEOUT');
            yield put(scannedDocuments({ correctFileData, correctDocumentData, malwareData, apiErrorData, timeoutError }));
        } else if (documentsUploadFailed) {
            yield put(addDocumentFailed());
        }
    }

}

function* handleKYCLinks(action) {
    const { payload } = yield call(getDownloadUrls, action.payload.amount);
    const object = handleLinkGeneration(action.payload, payload);
    const { files, documents, upload } = object;
    const { arrayLength, documentsUploaded, documentsUploadFailed } = yield call(uploadSelectedDocuments, upload);
    yield put(addKYCDocumentData({ files, documents }));
    if (documentsUploaded) {
        const scanDocumentResponse = yield call(scanUploadedDocument, documents);
        const correctKYCFileData = [];
        const correctKYCDocumentData = [];
        scanDocumentResponse.forEach(val => {
            if (val.status === 'SUCCESS') {
                files.forEach(obj => {
                    if (obj.path === val.filename) {
                        correctKYCFileData.push(obj);
                    }
                });
            }
        }
        );
        scanDocumentResponse.forEach(val => {
            if (val.status === 'SUCCESS') {
                documents.forEach(obj => {
                    if (obj.filename === val.filename) {
                        correctKYCDocumentData.push(obj);
                    }
                });
            }
        }
        );

        const malwareKYCData = scanDocumentResponse.filter(obj => obj.status === 'FAILED');
        const apiErrorData = scanDocumentResponse.filter(obj => obj.status === 'API_ERROR');
        const timeoutError = scanDocumentResponse.filter(obj => obj.status === 'TIMEOUT');
        yield put(scannedKYCDocuments({ correctKYCFileData, correctKYCDocumentData,malwareKYCData, apiErrorData,timeoutError }));
    }else if (documentsUploadFailed) {
        yield put(addKYCDocumentFailed());
    }
}

export function* handleKYCGenerateUploadLinks() {
    //yield takeEvery(`${generateKYCUploadLinks}`, handleKYCLinks);
    yield takeEvery(`${scanKYCDocumentAction}`, handleKYCLinks);
}

export function* handleAddDocumentUpload() {
    while (true) {
        const action = yield take(`${addDocumentUpload}`);
        const { meta, upload, clientId, isEditMode = false, documentID = '' } = action.payload;
        const { documentsUploadFailed, documentUploadCompleted } = yield call(uploadSelectedMetadata, meta, upload.length, false, isEditMode, documentID, clientId);
        if (documentUploadCompleted) {
            yield put(addDocumentCompleted());
        } else if (documentsUploadFailed) {
            yield put(addDocumentFailed());
        }
    }
}

export function* handleAddKYCDocumentUpload() {
    while (true) {
        const action = yield take(`${addKYCDocumentUpload}`);
        const { meta, upload = [], isEditMode = false, documentID = '', clientId } = action.payload;
        const { documentsUploadFailed, documentUploadCompleted } = yield call(uploadSelectedMetadata, meta, upload.length, true, isEditMode, documentID, clientId);

        if (documentUploadCompleted) {
            yield put(addKYCDocumentCompleted());
        } else if (documentsUploadFailed) {
            yield put(addKYCDocumentFailed());
        }
    }
}
export function* clearKycDocumentData() {
    yield put(clearKYCDocumentData);
}