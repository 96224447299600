import { all, put, call, takeEvery } from 'redux-saga/effects';

import { getVideoContent, getAllVideosIds, getOnePagerContent, getfetchGetInTouch } from './actions';
import {
    updateVideoContent,
    updateAllVideosIds,
} from '../../actions/contentful';
import { setDashboardWidgets } from '../../actions/clients';
import * as contentfulApiService from 'services/contentfulApiService';
import { genericErrorMessage } from './util';

function* fetchVideoById(action) {
    try {
        const entryId = action.payload.id;
        const {
            data: { items },
        } = yield call(contentfulApiService.findEntryById, entryId);
        yield put(updateVideoContent(items[0]));
    } catch (e) {
        yield genericErrorMessage(e);
    }
}

function* fetchAllVideosIds(action) {
    try {
        const { data: { items } } = yield call(contentfulApiService.findEntryByContentType, 'videoElementEntry');
        yield put(
            updateAllVideosIds(
                items.map((item) => ({
                    id: item.sys.id,
                    label: item.fields.title || item.fields.label,
                }))
            )
        );
    } catch (e) {
        yield genericErrorMessage(e);
    }
}
/*
 * Chained action, we need the payload from setDashboardWidgets action
 */
function* fetchVideoContent(action) {
    try {
        const videoCard = action.payload.widgetCodes.find((widget) => widget.widgetCode === 'VIDEOCARD');
        if (videoCard) {
            yield put(getVideoContent({ id: videoCard.widgetProperty }));
        }
    } catch (e) {
        yield genericErrorMessage(e);
    }
}

export default function* contentfulApiSagas() {
    yield all([
        takeEvery(`${getVideoContent}`, fetchVideoById),
        takeEvery(`${getAllVideosIds}`, fetchAllVideosIds),
        takeEvery(`${setDashboardWidgets}`, fetchVideoContent),
    ]);
}
