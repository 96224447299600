import http from './httpService';
import settings from 'src/settings';

const fundsApiUrl = settings.DOMAIN + '/csp-funds-api/v1/funds/';
const fundsApiUrlV2 = settings.DOMAIN + '/csp-funds-api/v2/funds/';
const fundsApiUrlClientV2 = fundsApiUrlV2 + 'client/';
const fundsApiUrlClient = fundsApiUrl + 'client/';
const fundsFavorites = settings.DOMAIN + '/csp-funds-api/v1/fund-favourites/';

const downloadFundSubscriptionUrl = settings.DOMAIN + '/DocumentsApi/v2/zip?zipContentIds=';
//const sfdrUrl = '/sfdr-article/';

// new



export const addFundToWatchlist = (isin, clientId) => http.post(`${fundsFavorites}${isin}?clientId=${clientId}`).then(response => response);

export const putMyFundsAccess = (clientId, body) => http.patch(`${fundsApiUrlClient}my`, { ...body, clientId: clientId });
export const downloadFundSubscriptionDocuments = (params) => http.get(downloadFundSubscriptionUrl + params).then((response) => response).catch((error) => error);

export const retrieveFundFilters = (body) => http.post(`${fundsApiUrlClient}funds-class`, body).then(response => response);
export const retrieveMyWatchlist = (clientId, attributes) => http.post(`${fundsApiUrlClient}favourite?sortBy=${attributes.sortBy}&sortType=${attributes.sortType}`, { clientId: clientId }).then(response => response);
export const retrieveAllFunds = (clientId, attributes) => http.post(`${fundsApiUrlClientV2}all?assetClass=${attributes?.assetClass || ''}&shareClass=${attributes?.shareClass || ''}&search=${attributes?.search || ''}&sortBy=${attributes?.sortBy}&sortType=${attributes?.sortType}`, { clientId: clientId }).then(response => response);
export const retrieveFundDetails = (clientId, fundIsIn) => http.post(`${fundsApiUrl}${fundIsIn}/getFundDetails`, { clientId: clientId }).then(response => response);
export const retrieveFundDocuments = (fundIsIn, body) => http.post(`${fundsApiUrl}${fundIsIn}/documents/getLatestDocuments`, { clientId: body.clientId }).then(response => response);
export const retrieveSfdrData = (fundIsIn, subfundUPI, clientId) => http.post(`${fundsApiUrl}${fundIsIn}/getSfdrArticle`, { clientId: clientId, subFundUPI: subfundUPI }).then(response => response);
export const retrieveMyFunds = (clientId, params) => http.post(`${fundsApiUrlClient}getMyFunds?sortBy=${params.sortBy}&sortType=${params.sortType}`, { clientId: clientId }).then(response => response);

export const removeFundFromWatchlist = (isin, clientId) => http.post(`${fundsFavorites}${isin}/removeFundFavourite`, { clientId: clientId }).then(response => response);

export const retrieveAltisFundDetails = (clientId, fundIsIn) => http.post(`${fundsApiUrl}client/altis-factsheets/${fundIsIn}`, { clientId: clientId }).then(response => response);
export const retrieveWholesaleMasterlist = (clientId, params) => http.post(`${fundsApiUrlClient}altis-wholesale-masterlist`, { clientId: clientId }).then(response => response);
