import { all, fork } from 'redux-saga/effects';
import userApiSagas from './api/userApiSagas';
import usersManagementApiSagas from './api/usersManagementApiSagas';
import clientApiSagas from './api/clientApiSagas';
import documentApiSagas from './api/documentApiSagas';
import documentInformationApiSagas from './api/documentInformationApiSagas';
import notificationsApiSagas from './api/notificationsApiSagas';
import contentfulApiSagas from './api/contentfulApiSagas';
import processApiSagas from './api/ongoingProcessApiSagas';
import fundsApiSagas from './api/fundsApiSagas';
import preferencesApiSagas from './api/preferencesApiSagas';

export default function* apiSagas() {
    yield all([
        fork(userApiSagas),
        fork(usersManagementApiSagas),
        fork(clientApiSagas),
        fork(documentApiSagas),
        fork(documentInformationApiSagas),
        fork(notificationsApiSagas),
        fork(contentfulApiSagas),
        fork(processApiSagas),
        fork(fundsApiSagas),
        fork(preferencesApiSagas),
    ]);
}
