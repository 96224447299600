import { createReducer } from 'redux-act';
import {
    updateVideoContent,
    updateAllVideosIds,
    updateOnePagerContent,
    updateGetInTouchDetail,
} from '../actions/contentful';
import states from 'data/reduxstates';

const contentfulReducer = createReducer(
    {
        [updateVideoContent]: (state, payload) => ({
            ...state,
            videoInDashboard: payload,
        }),
        // Admin area
        [updateAllVideosIds]: (state, payload) => ({
            ...state,
            videosIds: payload,
        }),
        [updateOnePagerContent]: (state, payload) => ({
            ...state,
            onePager: payload,
        }),
        [updateGetInTouchDetail]: (state, payload) => ({
            ...state,
            getInTouch: payload,
        }),
    },
    states.contentful
);

export default contentfulReducer;
