import { createReducer } from 'redux-act';
import states from 'data/reduxstates';
import removeUniqueElements from 'components/_helpers/removeUniqueElements';

import {
    addDocumentStepClientAccounts,
    addDocumentStepDocSecurity,
    addDocumentStepMetadata,
    addDocumentUploader,
    addDocumentStepSummary,
    addDocumentUpload,
    addDocumentFailed,
    addDocumentCompleted,
    moveToMetaDataStep,
    scannedDocuments,
    UpdateDocumentPostDelete,
    selectAccountChange
} from '../actions';
import { updateDocumentWizardState } from '@/redux/actions/index';

const addDocumentReducer = createReducer(
    {
        [updateDocumentWizardState]: (state, payload) => {
            return { ...state, ...payload };
        },
        [addDocumentStepDocSecurity]: (state, payload = new Set()) => {
            return {
                ...state,
                stepId: 0,
                data: {},
                documentUploadFailed: false,
                documentUploadCompleted: false,
                selectedClientIds: payload,
                correctFileData: [],
                correctDocumentData: {
                    documents: []
                },
                malwareDocuments: [],
                apiError: false,
                timeoutError: false
            };
        },
        [addDocumentStepClientAccounts]: (state, payload) => {
            return {
                ...state,
                ...payload,
                stepId: 1,
            };
        },
        [addDocumentUploader]: (state, payload) => {
            return {
                ...state,
                ...payload,
                stepId: 2,
                documentUploadFailed: false,
                documentUploadCompleted: false,
            };
        },
        [addDocumentStepMetadata]: (state, payload) => {
            return {
                ...state,
                //data: payload,
                //stepId: 3,
                correctDocumentData: payload
            };
        },
        [moveToMetaDataStep]: (state, payload) => {
            return {
                ...state,
                stepId: 3
            };
        },
        [addDocumentStepSummary]: (state, payload) => {
            return {
                ...state,
                data: payload,
                stepId: 4,
            };
        },
        [addDocumentUpload]: (state, payload) => {
            return {
                ...state,
                data: payload,
                stepId: 5,
            };
        },
        [addDocumentFailed]: (state, payload) => {
            return {
                ...state,
                documentUploadFailed: true,
                malwareDocuments: payload ? [...state.malwareDocuments, payload.filename] : state.malwareDocuments,
                apiError: payload ? state.apiError : true
            };
        },
        [addDocumentCompleted]: (state, payload) => {
            return {
                ...state,
                documentUploadCompleted: true
            };
        },
        [scannedDocuments]: (state, payload) => {
            return {
                ...state,
                correctFileData: payload.correctFileData ? [...state.correctFileData, ...payload.correctFileData] : state.correctFileData,//check this condition
                correctDocumentData: payload.correctDocumentData ? { documents: [...state.correctDocumentData.documents, ...payload.correctDocumentData] } : state.correctDocumentData,
                malwareData: payload.malwareData.map(obj => obj.filename),
                apiError: payload.apiErrorData.length > 0 ? true : false,
                timeoutError: payload.timeoutError.length > 0 ? true : false
            };
        },
        [UpdateDocumentPostDelete]: (state, payload) => {
            return {
                ...state,
                //correctFileData: state.correctFileData.filter(obj => obj.name === payload[0]?.name),//what is 2 documents get deleted then [0] will fail
                //correctDocumentData: { documents : state.correctDocumentData.documents.filter(obj => obj.filename === payload[0]?.name) }
                correctFileData: payload,
                correctDocumentData: payload.length > 0 ? { documents : removeUniqueElements(payload,state.correctDocumentData.documents) } : { documents : [] }
            };
        },
        [selectAccountChange]: (state, payload) => {
            return {
                ...state,
                correctFileData: [],
                correctDocumentData: {
                    documents: []
                },
                malwareData: [],
                apiError: false,
                timeoutError: false
            };
        }
    },
    states.adddocument
);

export default addDocumentReducer;