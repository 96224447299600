// Base
import React from 'react';
import classNames from 'classnames';

// Style
import style from 'assets/stylesheets/atoms/LoadingIndicator/Default';

const LoadingIndicator = ({ hasBackground = false, isRelative = false, isWhite = false }) => {
    const classes = classNames(style['loading-indicator'], {
        [style['loading-indicator--relative']]: isRelative,
        [style['loading-indicator--background']]: hasBackground,
        [style['loading-indicator--white']]: isWhite,
    });

    return (
        <div className={classes}>
            <div className={style['loading-indicator__spinner']}>Loading&hellip;</div>
        </div>
    );
};

export default LoadingIndicator;
