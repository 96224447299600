import fileTypes from 'data/filetypes.json';

export default function setFileTitle(title, isClientSpecific) {
    let types = fileTypes.map((value) => value.variants),
        arrTypes = new RegExp([].concat.apply([], types).toString().replace(/,/g, '|'), 'g'),
        newTitle = title.replace(arrTypes, '');

    if(isClientSpecific){
        // remove _[mandateCode] from the title
        return newTitle.slice(0, newTitle.indexOf('_['));
    }

    return newTitle;
}