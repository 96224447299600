import { createReducer } from 'redux-act';
import states from 'data/reduxstates';
import {
    setAppliedDocFilters,
    setPagination
} from '../actions';

const documentsFilters = createReducer({
    [setAppliedDocFilters]: (state, payload) => {
        return {
            ...state,
            appliedFilters: payload
        };
    },
    [setPagination]: (state, payload) => ({
        ...state,
        [payload.page]: {
            startFrom: payload.startFrom,
            currentPageNumber: payload.currentPageNumber
        }
    })
}, states.documentsFilters);

export default documentsFilters;