import { all, delay, takeLatest, call, put } from 'redux-saga/effects';
import * as actions from './actions';
import * as processActions from '../../actions';
import * as documentsApiService from 'services/documentsApiService';
import * as ongoingProcessApiService from 'services/ongoingProcessApiService';

function* fetchProcessDocuments(action) {
    const { clientId } = action.payload;
    const response = yield call(documentsApiService.retrieveDocuments, {
        clientAccountId: clientId,
        type: 'DOCUMENT_REQUEST',
    });
    response.data.map((item) => (item.url = `/document-store/${item.documentId}/${clientId}`));
    yield put(processActions.receiveProcessDocuments(response.data));
}

function* fetchProcessDocumentsTotal(action) {
    const { clientId } = action.payload;
    const response = yield call(documentsApiService.retrieveDocumentsTotal, { clientId: clientId });
    yield put(processActions.receiveProcessDocumentsTotal(response));
}

function* fetchActiveProcessDetails(action) {
    const { clientId, source } = action.payload;
    const response = yield call(ongoingProcessApiService.retrieveProcess, clientId, { activeWorkstreamsOnly: true });
    if (response.status === 404) {
        yield put(processActions.receiveEmptyProcessDetails());
    }
    if (response.status === 200) {
        yield put(processActions.receiveActiveProcessDetails(response.data));
        if (source === 'widget') {
            yield call(fetchProcessDocumentsTotal, action);
        } else {
            yield call(fetchProcessDocuments, action);
        }
    }
}

function* fetchProcessDetails(action) {
    const { clientId } = action.payload;
    const response = yield call(ongoingProcessApiService.retrieveProcess, clientId);
    if (response.status === 404) {
        yield put(processActions.addNewProcess());
    }
    if (response.status === 200) {
        yield put(processActions.receiveProcess(response.data));
    }
}

function* uploadProcess(action) {
    const { clientId, data } = action.payload;
    const response = yield call(ongoingProcessApiService.putProcess, clientId, data);
    if (response.status === 200) {
        yield put(processActions.updateProcessState({ isNewProcess: false }));
        // yield put(
        //     processActions.addAnalyticsEvent({
        //         eventName: 'newProcessStarted',
        //         data: {
        //             processType: data.type,
        //             clientId,
        //         },
        //     })
        // );
        yield delay(1000);
        yield put(processActions.addProcessUploadCompleted());
    } else {
        yield put(processActions.addProcessUploadFailed());
    }
}

export default function* clientApiSagas() {
    yield all([
        takeLatest(`${actions.fetchProcessDetails}`, fetchProcessDetails),
        takeLatest(`${actions.fetchActiveProcessDetails}`, fetchActiveProcessDetails),
        takeLatest(`${actions.fetchProcessDocuments}`, fetchProcessDocuments),
        takeLatest(`${actions.uploadProcess}`, uploadProcess),
    ]);
}
