/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { createStore, applyMiddleware, compose } from 'redux';
import rootSaga from './sagas';
import rootReducer from './rootReducer';

const composeEnhancers =
    typeof window === 'object' &&
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        : compose);

export function configureStore(preloadedState) {
    const persistedAuthState = sessionStorage.getItem('auth')
        ? JSON.parse(sessionStorage.getItem('auth'))
        : {};

    const persistedSelectedClientState = sessionStorage.getItem('currentClient')
        ? JSON.parse(sessionStorage.getItem('currentClient'))
        : {};

    const sagaMiddleware = createSagaMiddleware();

    let store = createStore(
        rootReducer,
        { ...preloadedState, ...{ auth: persistedAuthState, clientAccounts: { currentClient: persistedSelectedClientState } } },
        composeEnhancers(applyMiddleware(thunk, sagaMiddleware))
    );

    sagaMiddleware.run(rootSaga);

    return store;
}

export default configureStore;