/* eslint-disable no-unused-vars */
/* eslint-disable no-console */

import { dissoc } from 'ramda';
import { call, put, take } from 'redux-saga/effects';
import { addPortalAccount, generateUploadLink, scanPortalAvatar, uploadAvatar as uploadAvatarService } from '../../services/addPortalAccountApiService';
import { fetchPortalAccounts } from '@/redux/sagas/api/actions';

import {
    addPortalAccountFinish,
    addPortalAccountFailed,
    addPortalAccountCompleted,
    scannedPortalAvatar,
    portalAvatarUploadFailed,
    scanPortalAvatarAction
} from '../actions';

export const setProgress = (max, currentWidth) => {
    let i = currentWidth,
        start = setInterval(progress, 10);

    function progress() {
        if (i >= max) {
            clearInterval(start);
        } else {
            i++;
            document.querySelector('.progress__bar').style.width = Math.trunc(i) + '%';
            document.querySelector('.progress__bar__label').innerHTML = Math.trunc(i) + '%';
        }
    }

    return i;

};

export const addAccount = (data) => {
    // return new Promise(r => setTimeout(r, 1000, 100));
    // return new Promise(r => setTimeout(r, 150, 'error'));
    return addPortalAccount(data);
};

export const addSelectedAccount = async (array) => {
    let payload = {},
        failed = false;

    const result = await addAccount(array);

    if (result !== 'error') {
        setProgress(100, 0);
    } else {
        failed = true;
    }

    payload['portalAccountAddFailed'] = failed;
    payload['portalAccountAddCompleted'] = !failed;

    return payload;
};

export function* putNewPortalAccount() {
    while (true) {
        const action = yield take(`${addPortalAccountFinish}`);
        const data = action.payload;

        let newData = dissoc('usersSalesforceNames', data);

        const { portalAccountAddFailed, portalAccountAddCompleted } = yield call(addSelectedAccount, newData);

        if (portalAccountAddCompleted) {
            yield put(addPortalAccountCompleted());
            yield put(fetchPortalAccounts({ state: null }));
        } else if (portalAccountAddFailed) {
            yield put(addPortalAccountFailed());
        }
    }

}

export const scanUploadedPortalAvatar = async (avatars) => {
    let maxApiCall = 4;
    let payload = [];

    for (let current of avatars) {
        const result = await scanPortalAvatar({ key: current.key });
        if (result !== 'error') {
            if (result?.data?.status === 'INPROGRESS') {
                for (let i = 0; i < maxApiCall; i++) {
                    const result = await new Promise(function (resolve) {
                        setTimeout(async function () {
                            let output = await scanPortalAvatar({ key: current.key });
                            resolve(output);
                        }, 2000);
                    });
                    if (result?.data?.status !== 'INPROGRESS') {
                        payload.push({
                            filename: current.filename,
                            status: result?.data ? result.data.status : 'API_ERROR',
                            key: current.key
                        });
                        break;
                    } else if (i === maxApiCall - 1) {
                        payload.push({ filename: current.filename, status: 'TIMEOUT', key: current.key });
                    }
                }
            } else {
                payload.push({ filename: current.filename, status: result?.data?.status, key: current.key });
            }
        }
        else {
            payload.push({ filename: current.filename, status: 'API_ERROR', key: current.key });
        }
    }

    return payload;
};

export function* handleGenerateUploadPortalAvatarLinks() {
    while (true) {
        try {
            const action = yield take(`${scanPortalAvatarAction}`);
            const file = action.payload
            const avatar = file[0];
            const uploadFile = new Blob([avatar], { type: avatar.type });
            const getUploadLinks = yield generateUploadLink(1);
            const uploadKey = getUploadLinks[0].key;
            const uploadUrl = getUploadLinks[0].url;
            const uploadPortalAvatar = yield uploadAvatarService(uploadUrl, uploadFile);
            const avatarFile = [{ 
                key: uploadKey,
                filename: file[0]?.name 
            }]
    
            if (uploadPortalAvatar !== 'error') {
                const scanAvatarResponse = yield call(scanUploadedPortalAvatar,avatarFile);
                const correctFileData = [];
                const correctFileWithKey = [];
                scanAvatarResponse.forEach(val => {
                    if (val.status === 'SUCCESS') {
                        file.forEach(obj => {
                            if (obj.name === val.filename) {
                                correctFileData.push(obj);
                                correctFileWithKey.push({
                                    ...obj,
                                    key: uploadKey
                                })
                            }
                        });
                    }
                }
                );
    
                const malwareData = scanAvatarResponse.filter(obj => obj.status === 'FAILED');
                const apiErrorData = scanAvatarResponse.filter(obj => obj.status === 'API_ERROR');
                const timeoutError = scanAvatarResponse.filter(obj => obj.status === 'TIMEOUT');
                yield put(scannedPortalAvatar({ correctFileData, correctFileWithKey, malwareData, apiErrorData, timeoutError }));
            }  else {
                yield put(portalAvatarUploadFailed());
            }
        } catch {
            yield put(portalAvatarUploadFailed());
        }
    }
}