import http from './httpService';
import settings from '@/settings';

const preferencesApiUrl = settings.DOMAIN + '/csp-accounts-api/v1/';
const categoriesApiUrl = preferencesApiUrl + 'preference-categories';
const userPreferencesApiUrl = preferencesApiUrl + 'user-preferences';

export const retrievePreferenceCategories = () => http.get(categoriesApiUrl).then((response) => response.data);

export const retrieveUserPreferences = () => http.get(userPreferencesApiUrl).then((response) => response.data);
export const patchUserPreferences = (body) => http.patch(userPreferencesApiUrl, body);
