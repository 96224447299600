import { delay } from 'redux-saga/effects';
import { all, put, takeEvery } from 'redux-saga/effects';
import * as actions from './actions';
import * as notificationActions from '../../actions/notifications';

const randomId = () => Math.floor(Math.random()*1E12).toString();
const defaultTimeout = 4000;

function* handleCreateNotification ({ payload }) {
    const notificationId = randomId();
    const timeout = payload.timeout || defaultTimeout;

    yield put(notificationActions.displayNotification({
        id: notificationId,
        message: payload.message,
        type: payload.type || 'warning', // one of: ['success', 'info', 'warning', 'error', 'undo']
    }));
    yield delay(timeout);
    yield put(notificationActions.fadeoutNotification({ id: notificationId }));
    yield delay(1000); // Wait for remainder of timeout + some extra
    yield put(notificationActions.dismissNotification({ id: notificationId }));
}

function* handleUndoFromNotification ({ payload }) {
    yield put(notificationActions.dismissNotification({ id: payload.id }));
}

export default function* notificationSagas() {
    yield all([
        takeEvery(`${actions.createNotification}`, handleCreateNotification),
        takeEvery(`${actions.undoAction}`, handleUndoFromNotification),
    ]);
}
