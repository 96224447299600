import { createReducer } from 'redux-act';
import {
    receiveDocuments,
    receiveKYCDocuments,
    receiveDocumentsCategories,
    addDownloadingDocument,
    removeDownloadingDocument,
    addDeletingDocument,
    setDocumentDeleted,
} from '../actions/documents';
import { fetchDocuments, fetchDocumentsWithCategories } from '../sagas/api/actions';
import state from 'data/reduxstates';
import { assoc, curry, difference, merge, pipe, lensProp, over, reject, contains } from 'ramda';

const setToLoading = assoc('status', 'loading');
const setToBusy = assoc('status', 'busy');
const setToLoaded = assoc('status', 'loaded');
const addKeys = curry((keys, set) => new Set([...set, ...keys]));
const removeKeys = curry((keys, set) => new Set(difference([...set], keys)));

const documentsReducer = createReducer(
    {
        [fetchDocuments]: setToLoading,
        [fetchDocumentsWithCategories]: setToLoading,
        [receiveDocuments]: (state, documents) => {
            const data = documents?.data?.map((doc) => {
                const isRequested = ['CLIENT_SUBMISSION_IN_PROGRESS', 'NNIP_REQUESTED'].includes(doc.state);
                return { ...doc, isRequested: isRequested };
            });
            return merge(state, {
                documents: data,
                status: 'loaded',
                documentsTotal: documents.total,
            });
        },
        [receiveKYCDocuments]: (state, documents) => {
            return merge(state, {
                KYCdocument: documents,
                documentsKYCTotal: documents.total,
            });
        },
        [receiveDocumentsCategories]: (state, { categories, externalRole }) => {
            let categoriesList =
                externalRole === 'FINANCIAL' ? categories.filter((cat) => cat.isFinancial) : categories;

            return {
                ...state,
                categoriesList: categoriesList,
            };
        },
        [addDownloadingDocument]: (state, documentIds) =>
            pipe(over(lensProp('downloading'), addKeys(documentIds)), setToBusy)(state),
        [removeDownloadingDocument]: (state, documentIds) =>
            pipe(over(lensProp('downloading'), removeKeys(documentIds)), setToLoaded)(state),
        [addDeletingDocument]: (state, documentIds) =>
            pipe(over(lensProp('deleting'), addKeys(documentIds)), setToBusy)(state),
        [setDocumentDeleted]: (state, documentIds) =>
            pipe(
                over(lensProp('deleting'), removeKeys(documentIds)),
                over(
                    lensProp('documents'),
                    reject((d) => contains(d.documentId, documentIds))
                ),
                setToLoaded
            )(state),
    },
    state.documentManagement
);

export default documentsReducer;
