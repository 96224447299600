import { call, put, take } from 'redux-saga/effects';
import {
    uploadRequest as uploadRequestService,
    uploadClientDocument,
    uploadEditedRequest,
    submitBulkKYCRequest,
} from '../../services/addRequestApiService';
import { retrieveUploadUrls, uploadDocument, scanDocument } from 'services/addDocumentApiService';
import setFileTitle from 'components/_helpers/setFileTitle';

import {
    addRequestUpload,
    addRequestUploadFailed,
    addRequestUploadCompleted,
    // addAnalyticsEvent,
    KYCBulkRequest,
    addKYCDocumentCompleted,
    addKYCDocumentFailed,
    scanRequestDocumentAction,
    scannedRequestDocuments
} from '../actions';

// import { addKYCDocumentCompleted, addKYCDocumentFailed } from '../reducers/AddKYCDocument';

const uploadRequest = async (params) => {
    return await uploadRequestService(params)
        .then((response) => ({ response }))
        .catch((error) => ({ error }));
};

const generateFileUploadLinks = async (arrFiles) => {
    return await retrieveUploadUrls({ numberOfUploads: arrFiles.length })
        .then((result) => {
            arrFiles.forEach((file, i) => {
                file.url = result[i].url;
            });
            const keys = result.map((link) => link.key);
            return { arrFiles, keys };
        })
        .catch((errorLinks) => {
            return { errorLinks };
        });
};

const uploadFile = async (files, clientUpload) => {
    if (clientUpload) {
        let payload = {};
        let failed = false;
        for (const file of files) {
            let blob = new Blob([file], { type: file.type });
            const result = await uploadDocument(blob, file.url)
                .then((response) => 'totalprogress')
                .catch((error) => 'error');
            if (result !== 'error') {
                //setProgress(result, ((100/array.length) * j++));
            } else {
                failed = true;
            }
            payload['arrayLength'] = files.length;
        }
        payload['documentsUploadFailed'] = failed;
        payload['documentsUploaded'] = !failed;

        return payload;
    } else {
        for (const file of files) {
            let blob = new Blob([file], { type: file.type });
            const result = await uploadDocument(blob, file.url)
                .then((response) => 'totalprogress')
                .catch((error) => 'error');

            if (result.status === 200) {
                return { result };
            } else {
                return { error: result };
            }
        }
    }
};

export const scanUploadedDocument = async (newFiles) => {
    let maxApiCall = 4;
    let payload = [];

    for (let current of newFiles) {
        const result = await scanDocument({ key: current.key });
        if (result !== 'error') {
            if (result.data.status === 'INPROGRESS') {
                for (let i = 0; i < maxApiCall; i++) {
                    const result = await new Promise(function (resolve) {
                        setTimeout(async function () {
                            let output = await scanDocument({ key: current.key });
                            resolve(output);
                        }, 2000);
                    });
                    if (result?.data?.status !== 'INPROGRESS') {
                        payload.push({
                            status: result?.data ? result.data.status : 'API_ERROR',
                            key: current.key,
                            name: current.name
                        });
                        break;
                    } else if (i === maxApiCall - 1) {
                        payload.push({ status: 'TIMEOUT', key: current.key, name: current.name });
                    }
                }
            } else {
                payload.push({ status: result.data.status, key: current.key, name: current.name });
            }
        }
        else {
            payload.push({ status: 'API_ERROR', key: current.key, name: current.name });
        }
    }

    return payload;
};

export function* handleUploadFiles() {
    while (true) {
        const action = yield take(`${scanRequestDocumentAction}`);
        const { arrFiles, keys, errorLinks } = yield call(generateFileUploadLinks, action.payload.files);
        if (arrFiles) {
            //     const newFilesParam = params.files.map((value, i) => ({
            //         key: keys[i],
            //         filename: value.name,
            //         title: setFileTitle(value.name),
            //     }));
            //     const newParams = { ...params, files: newFilesParam };
            const newFiles = action.payload.files.map((value, i) => ({
                key: keys[i],
                name: value.name
            }));

            const { result, errorFilesUpload } = yield call(uploadFile, arrFiles, action.payload.clientUpload);
            if (result === 'error') {
                yield put(addRequestUploadFailed(result));
            } else {
                const scanRequestDocumentResponse = yield call(scanUploadedDocument, newFiles);
                const correctRequestFileData = [];
                const correctRequestFileWithKey = [];
                scanRequestDocumentResponse.forEach(val => {
                    if (val.status === 'SUCCESS') {
                        arrFiles.forEach(obj => {
                            if (obj.name === val.name) {
                                correctRequestFileData.push(obj);
                                correctRequestFileWithKey.push({
                                    key: val.key,
                                    filename: val.name,
                                    title: setFileTitle(val.name)
                                });
                            }
                        });
                    }
                }
                );
                const malwareData = scanRequestDocumentResponse.filter(obj => obj.status === 'FAILED');
                const apiErrorData = scanRequestDocumentResponse.filter(obj => obj.status === 'API_ERROR');
                const timeoutError = scanRequestDocumentResponse.filter(obj => obj.status === 'TIMEOUT');
                yield put(scannedRequestDocuments({ correctRequestFileData, correctRequestFileWithKey, malwareData, apiErrorData, timeoutError }));
            }
            //     return { newParams, uploadError: errorFilesUpload };
        } else {
            //return { uploadError: errorLinks };
            yield put(addRequestUploadFailed(errorLinks));
        }
    }
}

export const setProgress = (max, currentWidth) => {
    let i = currentWidth,
        start = setInterval(progress, 10);

    function progress() {
        if (i >= max) {
            clearInterval(start);
        } else {
            i++;
            document.querySelector('.progress__bar').style.width = Math.trunc(i) + '%';
            document.querySelector('.progress__bar__label').innerHTML = Math.trunc(i) + '%';
        }
    }

    return i;
};

const requestBulk = async (arrFiles, amount = 0) => {
    let payload = {};
    let failed = false;
    const result = await submitBulkKYCRequest(arrFiles)
        .then((response) => 'totalprogress')
        .catch((error) => 'error');
    if (result !== 'error') {
        setProgress(100, (100 / (amount + 1)) * amount);
    } else {
        failed = true;
    }
    payload['documentsUploadFailed'] = failed;
    payload['documentsUploaded'] = !failed;
    return payload;
};

export function* handleSubmitBulkKYCRequest() {
    const action = yield take(`${KYCBulkRequest}`);
    const { meta } = action.payload;
    const result = yield call(requestBulk, meta);
    if (result?.documentsUploaded) {
        yield put(addRequestUploadCompleted(result?.documentsUploaded));
    } else if (result?.documentsUploadFailed) {
        yield put(addRequestUploadFailed(result?.documentsUploadFailed));
    }
}

export function* handleAddRequestUpload() {
    while (true) {
        const action = yield take(`${addRequestUpload}`);
        const { params, isEditMode, documentId = '', clientUpload, correctRequestFileWithKey } = action.payload;
        const { files } = params;
        let requestParams = params;
        let error = null;
        const newFilesParam = { ...params, files: correctRequestFileWithKey };
        requestParams = newFilesParam;
        // if (clientUpload) {
        //     const { newParams, uploadError } = yield handleUploadFiles(params, clientUpload);
        //     requestParams = newParams;
        //     error = uploadError;
        // } else if (files.length && files[0].path) {
        //     const { newParams, uploadError } = yield handleUploadFiles(params, clientUpload);
        //     requestParams = newParams;
        //     error = uploadError;
        // } else if (isEditMode) {
        //     delete requestParams['files'];
        // }
        if(!clientUpload && !(files?.length > 0) && isEditMode){
            delete requestParams['files'];
        }

        if (error) {
            yield put(addRequestUploadFailed(error));
        } else {
            try {
                const { data } = isEditMode
                    ? yield call(uploadEditedRequest, documentId, requestParams)
                    : clientUpload
                        ? yield call(uploadClientDocument, requestParams)
                        : yield call(uploadRequestService, requestParams);
                // yield put(
                //     addAnalyticsEvent({
                //         eventName: 'documentRequested',
                //         data: {
                //             clientId: params.clientAccountIds[0],
                //             documentTitle: params.title,
                //         },
                //     })
                // );
                yield put(addRequestUploadCompleted());
            } catch (error) {
                yield put(addRequestUploadFailed(error));
            }
        }
    }
}
