import { createReducer } from 'redux-act';
import states from 'data/reduxstates';

import {
    addKYCDocumentStepClientAccounts,
    addKYCDocumentData,
    addKYCDocumentUpload,
    addKYCDocumentCompleted,
    addKYCDocumentFailed,
    clearKYCDocumentData,
    scannedKYCDocuments
} from '../actions';
import { updateDocumentWizardState } from '@/redux/actions/index';

const addKYCDocumentReducer = createReducer(
    {
        [addKYCDocumentStepClientAccounts]: (state, payload = new Set()) => {
            return {
                ...state,
                stepId: 0,
                documentUploadFailed: false,
                documentUploadCompleted: false,
                selectedClientIds: payload
            };
        },
        [addKYCDocumentUpload]: (state, payload) => {
            return {
                ...state,
                kycData: payload,
            };
        },
        [addKYCDocumentData]: (state, payload) => {
            return {
                ...state,
                kycData: payload,
            };
        },
        [addKYCDocumentCompleted]: (state, payload) => {
            return {
                ...state,
                documentUploadCompleted: true
            };
        },
        [addKYCDocumentFailed]: (state, payload) => {
            return {
                ...state,
                documentUploadFailed: true
            };
        },
        [clearKYCDocumentData]: (state) => {
            return {
                ...state,
                kycData: {},
                correctKYCFileData : [],
                malwareKYCData : [],
                correctKYCDocumentData : { documents : [] },
                apiError : false,
                timeoutError: false
            };
        },
        [scannedKYCDocuments] : (state , payload) => {
            return {
                ...state,
                correctKYCFileData: payload.correctKYCFileData ? payload.correctKYCFileData : [],//check this condition
                correctKYCDocumentData: payload.correctKYCDocumentData ? { documents : [...state.correctKYCDocumentData.documents , ...payload.correctKYCDocumentData] } : [],
                malwareKYCData: payload.malwareKYCData.map(obj => obj.filename),
                apiError: payload.apiErrorData.length > 0 ? true : false,
                timeoutError: payload.timeoutError.length > 0 ? true : false
            };
        }
    },
    states.addkycdocument
);

export default addKYCDocumentReducer;