import { createAction } from 'redux-act';

export const receiveClients = createAction('Receive clients');
export const receiveClientWidgets = createAction('Receive client widgets');
export const setClientWidgetEnabled = createAction('Set client widget enabled');
export const setClientWidgetInternalOnly = createAction('Set client widget for internal use');
export const setDashboardWidgets = createAction('Set dashboard widgets');
export const setCurrentClient = createAction('Set current selected client');
export const setCurrentClientLoading = createAction('Set current selected client loading state');
export const setIsUnauthorised = createAction('Redirect to select-client because client is unauthorised');
