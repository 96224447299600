import { createReducer } from 'redux-act';
import {
    receiveClientWidgets,
    setClientWidgetEnabled,
    setClientWidgetInternalOnly,
    setDashboardWidgets,
} from '../actions/clients';
import states from 'data/reduxstates';
import { pipe, indexBy, assocPath, assoc, map, pick, prop } from 'ramda';
import partitionArray from 'components/_helpers/partitionArray';

const clientWidgetsReducer = createReducer(
    {
        [receiveClientWidgets]: (state, widgets) => {
            const widgetSettings = map(
                pick(['enabled', 'widgetProperty', 'internalOnly']),
                indexBy(prop('widgetCode'), widgets)
            );
            return assoc('widgetSettings', widgetSettings, state);
        },
        [setClientWidgetEnabled]: (state, { widgetCode, enabled }) =>
            pipe(
                assocPath(['widgetSettings', widgetCode, 'enabled'], enabled),
                assocPath(['widgetSettings', widgetCode, 'internalOnly'], enabled)
            )(state),
        [setClientWidgetInternalOnly]: (state, { widgetCode, internalOnly }) =>
            assocPath(['widgetSettings', widgetCode, 'internalOnly'], internalOnly, state),
        [setDashboardWidgets]: (state, { widgetCodes, externalRole }) => {
            // If user is financial, disabled some widgets for him
            let [enabledWidgets, disabledWidgets] =
                externalRole?.role === 'FINANCIAL'
                    ? partitionArray(widgetCodes, (widget) => widget.isFinancial)
                    : [widgetCodes, []];
            // get only the widget codes
            disabledWidgets = disabledWidgets.map((widget) => widget.widgetCode);
            return {
                ...state,
                enabledWidgets,
                disabledWidgets,
                enabledWidgetsLoaded: true,
                externalRole,
            };
        },
    },
    states.clientWidgets
);

export default clientWidgetsReducer;
