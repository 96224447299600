/* eslint-disable no-unused-vars */
/* eslint-disable no-console */

import http from './httpService';
import settings from '../settings';

const accountSelectionApiUrl = settings.DOMAIN + '/csp-accounts-api/v1/client-accounts';
const adminSelectionApiUrl = settings.DOMAIN + '/csp-accounts-api/v1/main-selection';

export const retrieveAccounts = (headers) => http.get(accountSelectionApiUrl, { headers }).then(response => response.data);
export const retrieveClientAccount = (clientId) => http.get(`${accountSelectionApiUrl}/${clientId}`).then(response => response.data);
export const retrieveAccountsAdmin = (headers) => http.get(adminSelectionApiUrl, { headers }).then(response => response.data);