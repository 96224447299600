import routes from 'data/routes.json';

export default function createPageName(url) {

   let pageName = url.split("/").slice(2).join(":").replace(/\.com|\?.*$/g, "") // to replace .com & query params from page name

   const urlSplit = window.location.href.replace(/\?.*$/g, "").split('/').filter(Boolean);

   const isLoginPage = urlSplit.length === 2;

   if (pageName.endsWith(':')) {
      pageName = pageName.slice(0, -1)
   }

   if (isLoginPage) {
      pageName = pageName + ":" + "login"
   }

   return pageName.toLowerCase();
}

//let pageTitle;

const findLength = (url) => {
   let urlSplit = url.split("/")
   let arr = urlSplit.slice(3);
   let lastIndex = arr.length - 1;
   if (arr[lastIndex] === "" || arr[lastIndex]?.startsWith("?")) arr.pop() //if url ends with / or /?

   return arr.length;
}

export const findPageTitle = (url) => {

   const loginUrlSplit = window.location.href.split('/').filter(Boolean);

   if( loginUrlSplit.length === 2 ) { // length 2 means its a login page and url ends with /
      pageTitle = "Login | My GSAM";
      return pageTitle;
   }

   const complexUrlPage = ["document-management", "document-store", "funds-overview"]; //url with multiple path param

   //Remove query paramaters from page title
      let urlPart = url.split("/")?.[3]?.replace(/\?.*$/g, "")

      if(urlPart === ""){
         pageTitle = "Login | My GSAM";
         return pageTitle;
      }

   let pageTitle;

   for (const obj in routes) {
      if (routes[obj]?.path?.split("/")?.[1] === urlPart) {
         if (complexUrlPage.find((value) => value === urlPart)) {
            let array = routes[obj]?.path?.split("/");
            array.shift(); // to remove empty string from 0th index
            if (array.length === findLength(url)) {
               pageTitle = `${routes[obj]?.title} | My GSAM`
               break;
            }
         } else {
            pageTitle = `${routes[obj]?.title} | My GSAM`
            break;
         }
      }
   }

   return pageTitle;

}