import http from './httpService';
import settings from 'src/settings';
import axios from 'axios';

const processApiUrl = settings.DOMAIN + '/csp-onboarding-and-transition-api/v1/client';

export const retrieveProcess = (clientId, body = {}) => {
    const { activeWorkStreamsOnly = false } = body;
    let queryString = `?activeWorkStreamOnly=${activeWorkStreamsOnly}`;
    return http.post(`${processApiUrl}/getOnboardingTransition${queryString}`, { clientId: clientId }).then(response => response).catch(error => error.response);
};
export const putProcess = (clientId, body) => http.put(`${processApiUrl}/putOnboardingTransition`, { clientId: clientId, ...body }).then(response => response).catch(error => error.response || 'error');
