import { createReducer } from 'redux-act';
import states from 'data/reduxstates';
import removeUniqueElements from 'components/_helpers/removeUniqueElements';

import {
    updateRequestWizardState,
    startRequestWizard,
    addRequestMetadata,
    addRequestSummary,
    addRequestUpload,
    KYCBulkRequest,
    addRequestUploadFailed,
    addRequestUploadCompleted,
    scannedRequestDocuments,
    UpdateScanFilePostDelete,
    clearRequestUploadLinks
} from '@/redux/actions/index';

const addRequestReducer = createReducer(
    {
        [updateRequestWizardState]: (state, payload) => {
            return { ...state, ...payload };
        },
        [startRequestWizard]: (state, payload) => {
            const defaultState = {
                step: 1,
                isEditMode: !!payload,
                metadata: payload
                    ? { ...payload, files: payload.revisions[0].files }
                    : {
                        title: '',
                        descriptionWhatAndHow: '',
                        descriptionWhy: '',
                        files: [],
                        // isSensitive: false,
                        isUrgent: false,
                    },
                sendNotifications: false,
                requestParams: {},
                requestUploadFailed: false,
                requestUploadCompleted: false,
                totalSteps: 2,
            };
            return { ...state, ...defaultState };
        },
        [addRequestMetadata]: (state, payload) => {
            return { ...state, metadata: payload, step: 1, requestUploadFailed: false };
        },
        [addRequestSummary]: (state, payload) => {
            return { ...state, metadata: payload, step: 2 };
        },
        [addRequestUpload]: (state, payload) => {
            return { ...state, requestParams: payload, step: 2 };
        },
        [KYCBulkRequest]: (state, payload) => {
            return { ...state, requestParams: payload };
        },
        [addRequestUploadFailed]: (state) => {
            return { ...state, requestUploadFailed: true };
        },
        [addRequestUploadCompleted]: (state) => {
            return { ...state, requestUploadCompleted: true };
        },
        [scannedRequestDocuments]: (state, payload) => {
            return {
                ...state,
                correctRequestFileData: payload.correctRequestFileData,
                correctRequestFileWithKey: payload.correctRequestFileWithKey,
                malwareData: payload.malwareData.map(obj => obj.name),
                apiError: payload.apiErrorData.length > 0 ? true : false,
                timeoutError: payload.timeoutError.length > 0 ? true : false,
                metadata: { ...state.metadata, files : payload.correctRequestFileData }
            };
        },
        [UpdateScanFilePostDelete]: (state, payload) => {
            return {
                ...state,
                correctRequestFileData: payload,
                correctRequestFileWithKey: payload.length > 0 ? removeUniqueElements(payload,state.correctRequestFileWithKey) : []
            };
        },
        [clearRequestUploadLinks]: (state) => {
            return {
                ...state,
                correctRequestFileData: [],
                correctRequestFileWithKey: [],
                malwareData: [],
                apiError: false,
                timeoutError: false,
                //metadata: { ...state.metadata, files : payload.correctRequestFileData }
            };
        }
    },
    states.addrequest
);

export default addRequestReducer;
