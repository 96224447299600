import validator from 'validator';
import settings from '@/settings';
import sessionStorage from './SessionStorage';
import { call, put, takeLatest, all } from 'redux-saga/effects';
import { retrieveAccounts, retrieveAccountsAdmin } from 'services/accountSelectionApiService';
import { receiveClients } from '../actions/clients';
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { CognitoIdentityServiceProvider } from 'aws-sdk';
import { replaceRefreshToken } from 'services/authenticationService';
import { genericSuccessMessage } from './api/util';
import axios from 'axios';
import { pathOr, range } from 'ramda';

import {
    appReset,
    activeDirectoryLogin,
    changePassword,
    changePasswordStatus,
    clickLogout,
    completeResetPassword,
    confirmResetPassword,
    failFetchingLoginState,
    failFetchingUser,
    fetchLoginState,
    fetchUser,
    login,
    logout,
    newPasswordRequired,
    resetPassword,
    resetPasswordCompleted,
    setOauthToken,
    // setAnalyticsUserInfo,
} from '../actions';
import { userLoginInformation, newUserLoginInformation } from '../../services/userLoggedInService';

const promisify = (method) => {
    return function (...args) {
        return new Promise((resolve, reject) => {
            args.push((err, data) => {
                if (err) reject(err);
                else resolve(data);
            });
            this[method](...args);
        });
    };
};

CognitoUser.prototype.getSessionP = promisify('getSession');
CognitoUser.prototype.getUserDataP = promisify('getUserData');

const getUserPool = () =>
    new CognitoUserPool({
        UserPoolId: settings.USER_POOL_ID,
        ClientId: settings.CLIENT_ID,
        Storage: sessionStorage,
    });

const transformAttributes = (attrs) => {
    const result = {};
    attrs && attrs.forEach((attr) => (result[attr.Name] = attr.Value));
    return result;
};

//TODO: Separate cognito api calls from transformation logic
export const getSession = async (cognitoUser) => {
    try {
        const result = await cognitoUser.getSessionP();
        if (result) {
            const { Username, UserAttributes } = await cognitoUser.getUserDataP();
            const idToken = result.getIdToken().getJwtToken();
            const clientsAccounts = await retrieveAccounts({ Authorization: idToken });
            const hasRole = await retrieveAccountsAdmin({ Authorization: idToken });
            const payload = {
                user: Object.assign({}, transformAttributes(UserAttributes), {
                    isAdmin: hasRole.isAdmin,
                    adminChoice: hasRole.adminChoice,
                    isContributor: hasRole.isContributer,
                    isAltis: hasRole.isAltis,
                    clientId: clientsAccounts.length === 1 ? clientsAccounts[0].id : '',
                    multipleClients: clientsAccounts.length > 1,
                    username: Username,
                }),
                jwt: idToken,
            };
            return { payload, clientsAccounts };
        }
    } catch (err) {
        return { payload: null, clientsAccounts: null, err };
    }
};

const cognitoSignIn = (params) =>
    new Promise((resolve) => {
        const email = params.email.toLowerCase().trim();

        const authenticationDetails = new AuthenticationDetails({
            Username: email,
            Password: params.password,
        });

        const cognitoUser = new CognitoUser({
            Username: email,
            Pool: getUserPool(),
            Storage: sessionStorage,
        });

        const newPassword = params.newPassword !== undefined ? params.newPassword : false;

        const onSuccessLoginHandler = (result) => {
            cognitoUser.getUserAttributes(async (err, attrs) => {
                const payload = {};
                const idToken = result.getIdToken().getJwtToken();
                const clientsAmount = await retrieveAccounts({ Authorization: idToken });
                const hasRole = await retrieveAccountsAdmin({ Authorization: idToken });
                payload.user = Object.assign({}, transformAttributes(attrs), {
                    isAdmin: hasRole.isAdmin,
                    adminChoice: hasRole.adminChoice,
                    isContributor: hasRole.isContributer,
                    clientId: clientsAmount.length && clientsAmount.length < 2 ? clientsAmount[0].id : '',
                    multipleClients: clientsAmount.length > 1,
                    username: cognitoUser.username,
                });
                payload.jwt = result.getIdToken().getJwtToken();
                resolve({ payload });
            });
        };

        const onSuccessNewPasswordLoginHandler = (result) => {
            cognitoUser.getUserAttributes(async (err, attrs) => {
                const payload = {};
                const idToken = result.getIdToken().getJwtToken();
                const clientsAmount = await retrieveAccounts({ Authorization: idToken });
                const hasRole = await retrieveAccountsAdmin({ Authorization: idToken });
                payload.user = Object.assign({}, transformAttributes(attrs), {
                    isAdmin: hasRole.isAdmin,
                    adminChoice: hasRole.adminChoice,
                    isContributor: hasRole.isContributer,
                    clientId: clientsAmount.length && clientsAmount.length < 2 ? clientsAmount[0].id : '',
                    multipleClients: clientsAmount.length > 1,
                    username: cognitoUser.username,
                });
                payload.jwt = result.getIdToken().getJwtToken();
                const body = { userName: cognitoUser.username, status: "success" }
                await newUserLoginInformation(body)
                resolve({ payload });
            });
        };

        const onFailureLoginHandler = (err) => {
            resolve({ payload: null, err });
        };

        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: onSuccessLoginHandler,
            newPasswordRequired: (userAttributes) => {
                if (newPassword) {
                    delete userAttributes.email_verified;
                    delete userAttributes.phone_number_verified;

                    cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, {
                        onSuccess: onSuccessNewPasswordLoginHandler,
                        onFailure: onFailureLoginHandler,
                    });
                } else {
                    let nprResponse = { payload: null, err: 'new_password_required' };
                    resolve(nprResponse);
                }
            },
            onFailure: onFailureLoginHandler,
        });
    });

const cognitoForgotPassword = (params) =>
    new Promise((resolve) => {
        const { email } = params;

        let emailToLowerCase = email.trim().toLowerCase();

        const cognitoUser = new CognitoUser({
            Username: emailToLowerCase,
            Pool: getUserPool(),
            Storage: sessionStorage,
        });

        cognitoUser.forgotPassword({
            onSuccess: (result) => {
                const payload = {};
                payload.emailDestination = result.CodeDeliveryDetails.Destination;
                resolve({ payload });
            },
            onFailure: (err) => {
                resolve({ payload: null, err });
            },
        });
    });

const cognitoResetPassword = (params) =>
    new Promise((resolve, reject) => {
        const { emailForgot, verification, passwordSecond } = params;

        let emailForgotToLowerCase = emailForgot.toLowerCase();

        const cognitoUser = new CognitoUser({
            Username: emailForgotToLowerCase,
            Pool: getUserPool(),
        });

        cognitoUser.confirmPassword(verification, passwordSecond, {
            onSuccess: () => {
                resolve({ payload: {} });
            },
            onFailure: (err) => {
                resolve({ payload: null, err });
            },
        });
    });

const getAccessToken = (cognitoUser) =>
    new Promise((resolve, reject) => {
        cognitoUser.getSession((err, result) => {
            if (result) {
                const token = result.getAccessToken().getJwtToken();
                resolve({ token });
            } else {
                sessionStorage.clear();
                resolve({ token: null, err });
            }
        });
    });

const globalSignOut = (cognitoUser) =>
    new Promise((resolve, reject) => {
        cognitoUser.globalSignOut({
            onSuccess: (result) => {
                sessionStorage.removeItem('auth');
                resolve({ result });
            },
            onFailure: (err) => {
                sessionStorage.clear();
                resolve({ result: null, err });
            },
        });
    });

const cognitoChangePassword = (params) =>
    new Promise((resolve, reject) => {
        const { password, passwordSecond, cognitoUser } = params;

        cognitoUser.getSession(function (err, session) {
            if (err) {
                resolve({ err });
            }
        });

        cognitoUser.changePassword(password, passwordSecond, function (err, result) {
            if (err) {
                resolve({ err });
            }
            resolve({ result });
        });
    });

export function createLoginObject(payload, maxLoginDay) {
    const { given_name, family_name, middle_name } = payload.user;
    const fullName = [given_name, middle_name, family_name].join(' ');
    return {
        isPrepared: true,
        isLoggedIn: true,
        error: undefined,
        isFetching: false,
        resetPassword: false,
        setNewPassword: false,
        pathname: payload.pathname,
        newPasswordRequired: false,
        resetPasswordConfirm: false,
        user: { ...payload.user, fullName, maxLoginDays: maxLoginDay },
    };
}

export function* handleFetchLoginState(action) {
    const cognitoUser = getUserPool().getCurrentUser();

    const pathname = action?.payload?.pathname;

    if (!sessionStorage.getItem('auth')) {
        const ignorePaths = ['login', 'saml'];
        const hasIgnorePath = ignorePaths.some((ignorePath) => pathname.includes(ignorePath));

        if (!hasIgnorePath) {
            sessionStorage.setItem('loginRedirect', pathname);
        }
    }

    if (cognitoUser) {
        const { payload, clientsAccounts, err } = yield call(getSession, cognitoUser);

        if (payload && !err) {
            const loginObj = createLoginObject(payload);
            sessionStorage.setItem('auth', JSON.stringify(loginObj));

            yield put(login(Object.assign({}, payload, action.payload)));
            // yield put(setAnalyticsUserInfo(payload));
            yield put(receiveClients(clientsAccounts));
            return;
        }

        yield put(failFetchingLoginState(action.payload));
        return;
    }

    yield put(failFetchingLoginState(''));
}

export function* handleLogout(isReset = false) {
    const cognitoUser = getUserPool().getCurrentUser();


    if (cognitoUser) {
        const { token, err } = yield call(getAccessToken, cognitoUser);

        if (token && !err) {
            const { result, err } = yield call(globalSignOut, cognitoUser);
            const cognitoIdentityServiceProvider = new CognitoIdentityServiceProvider();
            cognitoIdentityServiceProvider.globalSignOut({ AccessToken: token });

            if (result && !err && !isReset) {
                yield put(appReset());
                yield put(logout());
                window.location = '/login';
            }
            if (isReset) {
                yield put(appReset());
                yield put(logout());
            }
        }
    }
}

export function* handleLogin(action) {
    sessionStorage.removeItem('auth');
    const { email, password } = action.payload;
    // To string for validation
    let emailString = email === undefined ? '' : email.trim(),
        passWordString = password === undefined ? '' : password;

    if (validator.isEmpty(emailString) && validator.isEmpty(passWordString)) {
        yield put(failFetchingUser('Please enter your username and password'));
        return;
    }

    if (validator.isEmpty(emailString)) {
        yield put(failFetchingUser('Please enter your username'));
        return;
    }

    if (!validator.isEmail(emailString)) {
        yield put(failFetchingUser('Please enter a valid email address'));
        return;
    }

    if (validator.isEmpty(passWordString)) {
        yield put(failFetchingUser('Please enter your password'));
        return;
    }

    if (emailString && passWordString) {
        const { payload, err } = yield call(cognitoSignIn, action.payload);

        const body = { email: emailString, status: "failure", message: err } //this is for user loggin information

        if (!payload && err === 'new_password_required') {
            yield put(newPasswordRequired(action.payload));
            yield call(userLoginInformation, body)
            sessionStorage.setItem('newpasswordset', true);
            return;
        } else if (!payload && err) {
            const errMsg = createErrorMsg(err);
            yield put(failFetchingUser(errMsg));
            yield call(userLoginInformation, body)
            return;
        }

        if (payload.user["custom:passwordUpdatedDate"] !== null) {
            const updatedDate = new Date(payload.user["custom:passwordUpdatedDate"])
            const today = new Date();
            const differenceInTime = today - updatedDate
            const differenceInDays = Math.floor(differenceInTime / (1000 * 60 * 60 * 24))
            sessionStorage.setItem('auth', JSON.stringify(createLoginObject(payload, differenceInDays)));
            const body = { ...payload, differenceInDays: differenceInDays };
            yield put(login(body));
        }
        //     else{
        //         sessionStorage.removeItem('auth');
        //         sessionStorage.setItem('auth', JSON.stringify(createLoginObject(payload, differenceInDays)));
        //         const loginRedirect = sessionStorage.getItem('loginRedirect');
        //         sessionStorage.removeItem('loginRedirect');
        //         yield put(login(payload));
        //         window.location = loginRedirect || '/';
        //     }
        // } 

    }
}

export function* handleForgotPassword(action) {
    const { email } = action.payload;
    if (email) {
        // To string for validation
        let emailString = email === undefined ? '' : email.trim();

        if (validator.isEmpty(emailString)) {
            yield put(failFetchingUser('Please enter your e-mail address'));
            return;
        }
        if (!validator.isEmail(emailString)) {
            yield put(failFetchingUser('Please enter a valid email address'));
            return;
        }

        if (emailString) {
            const { payload, err } = yield call(cognitoForgotPassword, action.payload);
            const body = { email: emailString, status: "failure", message: err } //this is for user loggin information
            if (!payload && err) {
                const errMsg = createErrorMsg(err);
                yield put(failFetchingUser(errMsg));
                yield call(userLoginInformation, body)
                return;
            }
        }
        yield put(confirmResetPassword(emailString));
    }
}

function createErrorMsg(err) {
    const errMsgs = {
        403: 'The request has to contain a valid token',
        500: 'Your request has failed due to a unknown error of the server',
        503: 'Your request has failed due to a temporary failure of the server',
        UserNotFoundException: 'UserNotFound',
    };
    return errMsgs[err.statusCode || err.code] || err.message;
}

export function* handleResetPassword(action) {
    const { emailForgot, verification } = action.payload;

    if (verification.length === 0) {
        yield put(failFetchingUser('Six digit verification code missing.'));
        return;
    }

    if (emailForgot) {
        const { err } = yield cognitoResetPassword(action.payload);

        if (err) {
            yield put(failFetchingUser(err.message));
            return;
        }

        yield genericSuccessMessage('Password successfully changed!');
        yield sleep(2000);
        yield put(resetPasswordCompleted());
        window.location = '/login';
    }
}

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const parseQueryString = (queryString) => {
    const qs = queryString.startsWith('?') ? queryString.slice(1) : queryString;
    const params = {};
    const queries = qs.split('&');
    for (let i = 0, l = queries.length; i < l; i++) {
        const kv = queries[i].split('=');
        params[decodeURIComponent(kv[0])] = decodeURIComponent(kv[1]);
    }
    return params;
};

function* handleSAMLPost(action) {
    const qs = action.payload.queryString;
    const params = parseQueryString(qs);

    if (params.error) {
        const decode = (x) => decodeURIComponent(x).replace(/\+/g, ' ');
        yield put(failFetchingUser(`${decode(params.error)} - ${decode(params.error_description)}`));
    } else if (params.code) {
        const correctLogin = checkCsrfState(params.state);
        if (!correctLogin) {
            yield put(failFetchingUser('csrf Attack!'));
            return;
        }
        const code = params.code;
        const clientId = settings.CLIENT_ID;
        const redirectUrl = `${settings.APP_HOST_NAME}/saml`;
        const oauthHost = settings.OAUTH_HOST_NAME;
        const url = oauthHost + '/token';
        const body = `grant_type=authorization_code&client_id=${clientId}&code=${code}&redirect_uri=${redirectUrl}`;
        const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };

        try {
            const postResult = yield axios.request({ method: 'post', url, data: body, headers });
            const refreshToken = postResult.data.refresh_token;
            yield replaceRefreshToken(refreshToken);

            const loginRedirect = sessionStorage.getItem('loginRedirect');
            sessionStorage.removeItem('loginRedirect');

            window.location.replace(loginRedirect || '/');
        } catch (error) {
            const body = { email: "internalUser", status: "failure", message: error } //this is for user loggin information
            yield put(failFetchingUser(pathOr('Something went wrong', ['response', 'data', 'error'], error)));
            yield call(userLoginInformation, body)

        }
    } else {
        yield put(failFetchingUser('No code present in URL.'));

    }
}

function adLogin() {
    const clientId = settings.CLIENT_ID;
    const redirectUrl = `${settings.APP_HOST_NAME}/saml`;
    const oauthHost = settings.OAUTH_HOST_NAME;
    const state = generateCsrfState();
    window.location.replace(
        `${oauthHost}/authorize?response_type=code&client_id=${clientId}&identity_provider=NN-ADFS&redirect_uri=${redirectUrl}&state=${state}`
    );
}

function generateCsrfState() {
    const state = range(1, 5)
        .map(() => (Math.random().toString(36) + '0000000000000').slice(2, 14))
        .join('');
    localStorage.setItem('loginState', state);
    return state;
}

function checkCsrfState(state) {
    const usedState = localStorage.getItem('loginState');
    localStorage.removeItem('loginState');
    return state === usedState;
}

export function* handleChangePassword(action) {
    const { password, passwordSecond } = action.payload;

    const cognitoUser = getUserPool().getCurrentUser();

    const { result, err } = yield call(cognitoChangePassword, { password, passwordSecond, cognitoUser });

    if (result === 'SUCCESS') {
        yield put(changePasswordStatus({ code: 200 }));
    } else if (err.code === 'NotAuthorizedException') {
        yield put(changePasswordStatus({ code: 404 }));
    } else {
        yield put(changePasswordStatus({ code: 500, error: err.message }));
    }
}

export default function* authSagas() {
    yield all([
        takeLatest(`${fetchLoginState}`, handleFetchLoginState),
        takeLatest(`${fetchUser}`, handleLogin),
        takeLatest(`${resetPassword}`, handleForgotPassword),
        takeLatest(`${completeResetPassword}`, handleResetPassword),
        takeLatest(`${clickLogout}`, handleLogout),
        takeLatest(`${changePassword}`, handleChangePassword),
        takeLatest(`${activeDirectoryLogin}`, adLogin),
        takeLatest(`${setOauthToken}`, handleSAMLPost),
    ]);
}
