import { createReducer } from 'redux-act';
import states from 'data/reduxstates';
import { grantMyFundsAccess } from '../sagas/api/actions';
import manipulateFundsData from '../utils/manipulateFundsData';

import {
    receiveMyFunds,
    receiveAllFunds,
    receiveMyWatchlist,
    addToWatchlist,
    removeFromWatchlist,
    addToFundsData,
    setFundsSorters,
    receiveFundFilters,
    setFundsError,
    receiveWholesaleMasterlist,
    updateAllFundsIds,
} from '@/redux/actions/index';

const fundsReducer = createReducer(
    {
        [grantMyFundsAccess]: (state) => ({
            ...state,
            myFundsLoading: true,
        }),
        [receiveMyFunds]: (state, payload) => {
            const { data, location } = payload;
            const myFundsIds = data.map((fund) => fund.isin);
            return {
                ...state,
                myFundsIds: myFundsIds,
                myFundsLocation: location,
                myFundsLoading: false,
                errors: {
                    ...state.errors,
                    myFundsError: null,
                },
            };
        },
        // Receive data for My Watchlist section
        [receiveMyWatchlist]: (state, myWatchlist) => {
            const myWatchlistIds = myWatchlist.map((fund) => fund.isin);
            return {
                ...state,
                myWatchlistIds: myWatchlistIds,
                myWatchlistLoading: false,
                errors: {
                    ...state.errors,
                    myWatchlistError: null,
                },
            };
        },
        // Merge my funds or my watchlist data into allFunds.byId
        // allFunds.byId: combination on all data from All Funds, My Watchlist and My Funds sections
        [addToFundsData]: (state, payload) => {
            const normalizedAllFunds = manipulateFundsData(payload.data);
            const byId = state.allFundsData.byId || {};

            // Merge funds & fund props
            Object.keys(normalizedAllFunds.byId).forEach((isin) => {
                if (byId[isin]) {
                    byId[isin] = {
                        ...byId[isin],
                        ...normalizedAllFunds.byId[isin],
                    };
                } else {
                    byId[isin] = normalizedAllFunds.byId[isin];
                }
            });

            return {
                ...state,
                allFundsData: {
                    ...state.allFundsData,
                    byId,
                },
            };
        },
        // Receive data for All Funds section
        [receiveAllFunds]: (state, payload) => {
            const { response, startFrom } = payload;
            //const normalizedAllFunds = manipulateFundsData(response.data);
            // const allIds =
            //     startFrom === 0
            //         ? normalizedAllFunds.allIds
            //         : [...state.allFundsData.allIds, ...normalizedAllFunds.allIds];
            const normalizedAllFunds = manipulateFundsData(response.funds);
            const allIds = normalizedAllFunds.allIds;

            return {
                ...state,
                allFundsLoading: false,
                errors: {
                    ...state.errors,
                    allFundsError: null,
                },
                allFundsData: {
                    byId: {
                        ...normalizedAllFunds.byId,
                        ...state.allFundsData.byId,
                    },
                    allIds: allIds,
                    allFundsTotal: response.total,
                },
            };
        },
        // Reset pagination for All Funds section
        [updateAllFundsIds]: (state, payload) => {
            return {
                ...state,
                allFundsData: {
                    ...state.allFundsData,
                    //allIds: state.allFundsData.allIds.slice(0, 7),
                    allIds: state.allFundsData.allIds
                },
            };
        },
        [receiveFundFilters]: (state, filters) => {
            return { ...state, filters };
        },
        [addToWatchlist]: (state, isin) => {
            return {
                ...state,
                myWatchlistIds: [...state.myWatchlistIds, isin],
            };
        },
        [removeFromWatchlist]: (state, isin) => {
            return {
                ...state,
                myWatchlistIds: state.myWatchlistIds.filter((id) => id !== isin),
            };
        },
        [setFundsSorters]: (state, { key, sorters }) => {
            window.localStorage.setItem(key, JSON.stringify(sorters));
            return { ...state, [key]: [...sorters] };
        },
        [setFundsError]: (state, { fundType, error }) => {
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [`${fundType}Error`]: error,
                },
                [`${fundType}Loading`]: false,
            };
        },
        [receiveWholesaleMasterlist]: (state, payload) => {
            return { ...state, wholesaleMasterlistLoading: false, wholesaleMasterlist: payload };
        },
    },
    states.funds
);

export default fundsReducer;
