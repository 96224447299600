import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import NotificationIcon from 'assets/images/icons/notification';
import ReadMoreIcon from 'assets/images/icons/readmore-icon';
import CloseIcon from 'assets/images/icons/close-icon';
import ButtonAtom from 'components/atoms/Buttons/Button';

// Style
import style from 'assets/stylesheets/atoms/Notifications/Notification';

const Notification = ({ type, remove, showCloseIcon, children, handleUndo, handleClose }) => {
    const notificationType = `notification--${type}`;
    const { t } = useTranslation();

    return (
        <div
            className={`notification ${style.notification} ${style[notificationType]} ${
                remove ? style['notification--remove'] : ''
            }`}>
            <div className={style.notification__icon}>
                <NotificationIcon type={type} />
            </div>
            <p>{children}</p>
            {type === 'undo' && (
                <button className={style.notification__undo} onClick={handleUndo}>
                    {t('csp.undo')}
                </button>
            )}
            {showCloseIcon && (
                <ButtonAtom.Icon onClick={handleClose} customClasses={style.notification__close} icon={<CloseIcon />}>
                    {t('csp.dismissNotification')}
                </ButtonAtom.Icon>
            )}
        </div>
    );
};

const { oneOf, bool, func } = PropTypes;

Notification.propTypes = {
    type: oneOf(['info', 'success', 'warning', 'error', 'undo']),
    showCloseIcon: bool,
    handleClose: func,
    remove: bool,
};

Notification.defaultProps = {
    type: 'info',
    showCloseIcon: false,
    remove: false, //adds removing animation
};

export default Notification;
