import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RelationWidgetMolecule from 'components/molecules/Widgets/Relation';

const propTypes = {
    clientId: PropTypes.string,
};

const Relation = ({ relationData, dataLoaded }) => {
    const handleClick = () => {
        let container = document.getElementById('relation');

        window.addEventListener('click', function (event) {
            if (!container.contains(event.target)) {
                container.classList.remove('active');
            }
        });
    };

    const handleMouseOver = () => {
        let container = document.getElementById('relation');

        if (!container.classList.contains('active')) {
            container.classList.add('active');
            setTimeout(function () {
                container.classList.remove('active');
            }, 8000);
        }
    };

    return (
        <RelationWidgetMolecule
            dataLoaded={dataLoaded}
            relationData={relationData}
            handleClick={handleClick}
            handleMouseOver={handleMouseOver}
        />
    );
};

Relation.propTypes = propTypes;

const select = ({
    clientAccounts: {
        currentClient: { relationData },
    },
}) => ({
    relationData,
});

export default connect(select)(Relation);
