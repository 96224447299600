// Base
import React from 'react';
import PropTypes from 'prop-types';

// Components
import BottomMenuMolecule from 'components/molecules/Menus/Bottom';
import RelationBlockWidgetOrganism from 'components/organisms/Widgets/Relation';

// Style
import style from 'assets/stylesheets/molecules/Scaffolding/Footer';

const propTypes = {
    variant: PropTypes.string,
};

class Footer extends React.Component {
    render() {
        const { variant, clientId, isAdmin } = this.props;
        const footerVariants = {
            default: style.footer__default,
            article: style.footer__article,
            fixed: style.footer__fixed,
            selector: style.footer__selector,
        };

        return (
            <footer className={`footer ${variant ? footerVariants[variant] : style.footer__default}`}>
                {/* {clientId ? (
                    <BottomMenuMolecule clientId={clientId} />
                ) : isAdmin ? (
                    <BottomMenuMolecule isAdmin={isAdmin} />
                ) : (
                    <BottomMenuMolecule />
                )} */}

                {!isAdmin && clientId && <RelationBlockWidgetOrganism clientId={clientId} />}
            </footer>
        );
    }
}

Footer.propTypes = propTypes;

export default Footer;
