import { createReducer } from 'redux-act';
import states from 'data/reduxstates';
import { REMOVE_CONSENT, SET_CONSENT } from '../actions/GDPRTypes';

import {
    setConsent
} from '../actions';

const initialState = {
    gaveGDPRConsent: false,
    hasFunctionalGDPRConsent: false,
    hasFullGDPRConsent: false,
};

const gdprReducer = createReducer(
    {
        // [setConsent]: (state, payload) => {
        //     return { ...state, gaveGDPRConsent: payload.gaveGDPRConsent };
        // },
        [SET_CONSENT]:
            (state = initialState, { hasFunctionalGDPRConsent, hasFullGDPRConsent, type, gaveGDPRConsent }) => {
                return {
                    ...state,
                    gaveGDPRConsent,
                    hasFunctionalGDPRConsent,
                    hasFullGDPRConsent,
                };
            },
        [REMOVE_CONSENT]: () => initialState
    },
    states.gdpr
);

export default gdprReducer;