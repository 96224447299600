import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import LoadingIndicatorAtom from 'components/atoms/LoadingIndicator/Default';
import ChevronRightIcon from 'assets/images/icons/chevron-right';

import style from 'assets/stylesheets/atoms/Buttons/Button';

export const ButtonDefault = ({
    buttonStyle,
    buttonType,
    to,
    onClick,
    customClasses,
    icon,
    buttonSize,
    isRight,
    isLoading,
    isDisabled,
    isIconInactive,
    hasIcon,
    tstId,
    children,
    hasSkip,
    isSkipDisabled,
    onSkipClick,
    customFont,
    ...attributes
}) => {
    const buttonClasses = classNames(
        'button',
        style.button,
        style[`button--${buttonStyle}`],
        style[`${buttonSize}`],
        customClasses,
        style[`${buttonSize}`],
        style[`${customFont}`],
        {
            [style['button--right']]: isRight,
            [style['button__icon--inactive']]: isIconInactive,
        }
    );
    const skipButtonStyles = classNames(
        'button',
        'mr-2',
        style.button,
        style['button--secondary'],
        customClasses,
        style[`${customFont}`],
        style[`${buttonSize}`],
        {
            [style['button--right']]: isRight,
            [style['button__icon--inactive']]: isIconInactive,
        }
    );

    return (
        <>
            {!to ? (
                <>
                    {hasSkip && (
                        <button
                            type={buttonType}
                            disabled={isSkipDisabled}
                            onClick={onSkipClick}
                            className={skipButtonStyles}
                            style={{
                                width: '100px',
                                height: '40px',
                            }}
                            {...attributes}>
                            Skip
                        </button>
                    )}
                    <button
                        type={buttonType}
                        disabled={isDisabled || isLoading}
                        onClick={onClick}
                        data-test-id={tstId}
                        className={`${buttonClasses} ${hasSkip ? 'ml-1' : ''}`}
                        {...attributes}>
                        {children}
                        {hasIcon && <span className={style['button__icon']}>{icon}</span>}
                        {isLoading && <LoadingIndicatorAtom hasBackground isWhite />}
                    </button>
                </>
            ) : (
                <Link to={to} data-test-id={tstId} className={buttonClasses} {...attributes}>
                    {children}
                    {hasIcon && <span className={style['button__icon']}>{icon}</span>}
                </Link>
            )}
        </>
    );
};

const Submit = ({ children, onClick, isLoading, ...attributes }) => {
    const [isSubmitted, setIsSubmitted] = useState(false);
    // Prevents double click on the button and calls the onClick callback
    const submitOnClick = () => {
        if (onClick) {
            onClick();
            setIsSubmitted(true);
        } else {
            return null;
        }
    };

    return (
        <ButtonDefault
            buttonType="submit"
            hasIcon
            icon={<ChevronRightIcon />}
            onClick={submitOnClick}
            isLoading={isSubmitted || isLoading}
            {...attributes}>
            {children}
        </ButtonDefault>
    );
};

const Icon = ({ children, customClasses, ...attributes }) => {
    return (
        <ButtonDefault
            hasIcon
            title={children}
            customClasses={`${customClasses} ${style['button--is-icon']}`}
            {...attributes}>
            <span className="screenreader">{children}</span>
        </ButtonDefault>
    );
};

const Tag = ({ children, customClasses, ...attributes }) => {
    return (
        <ButtonDefault
            buttonStyle="secondary"
            hasIcon
            customClasses={`${customClasses} ${style['button--tag']}`}
            {...attributes}>
            {children}
        </ButtonDefault>
    );
};

ButtonDefault.Submit = Submit;
ButtonDefault.Icon = Icon;
ButtonDefault.Tag = Tag;

const { bool, oneOf, string, func, node } = PropTypes;

ButtonDefault.propTypes = {
    buttonStyle: oneOf(['primary', 'secondary', 'tertiary']),
    buttonType: string,
    to: string,
    onClick: func,
    customClasses: string,
    icon: node,
    isRight: bool,
    isLoading: bool,
    isDisabled: bool,
    hasIcon: bool,
    tstId: string,
    children: node,
};

ButtonDefault.defaultProps = {
    buttonStyle: 'primary',
    buttonType: 'button',
    to: null,
    onClick: () => {},
    customClasses: null,
    icon: null,
    isRight: false,
    isLoading: false,
    isDisabled: false,
    hasIcon: false,
    tstId: null,
    children: null,
};

export default ButtonDefault;
