export const contactName = (contact) => {
    const { givenName = '', familyName = '', middleName = '' } = contact;
    if (givenName === undefined && familyName === undefined) return `${middleName}`.replace(/  +/g, ' ');
    else if (givenName === undefined && middleName === undefined) return `${familyName}`.replace(/  +/g, ' ');
    else if (givenName === undefined) return `${middleName} ${familyName}`.replace(/  +/g, ' ');
    else if (middleName === undefined && familyName === undefined) return `${givenName}`.replace(/  +/g, ' ');
    else if (familyName === undefined) return `${givenName} ${familyName}`.replace(/  +/g, ' ');
    else if (middleName === undefined) return `${givenName} ${familyName}`.replace(/  +/g, ' ');
    else return `${givenName} ${middleName} ${familyName}`.replace(/  +/g, ' ');
};
