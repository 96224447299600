import { createReducer } from 'redux-act';
import { receiveClients, setCurrentClient, setCurrentClientLoading, setIsUnauthorised } from '../actions/clients';
import states from 'data/reduxstates';
import normalize from '../utils/normalize';
import { sortByKey } from '../utils/sort';

const clientAccountsReducer = createReducer(
    {
        [receiveClients]: (state, clients) => {
            const normalizedClients = normalize(sortByKey(clients, 'name'), 'id');
            return {
                ...state,
                state: 'loaded',
                ...normalizedClients,
            };
        },
        [setCurrentClient]: (state, payload) => {
            return { ...state, currentClient: payload };
        },
        [setCurrentClientLoading]: (state, payload) => {
            return { ...state, loading: payload };
        },
        [setIsUnauthorised]: (state, { unauthorised, reason = '' }) => {
            return { ...state, isUnauthorised: unauthorised, unauthorisedReason: reason };
        },
    },
    states.clientAccounts
);

export default clientAccountsReducer;
