import { all, takeEvery, takeLatest, call, put } from 'redux-saga/effects';
import * as actions from './actions';
import * as docActions from '../../actions';
import { genericErrorMessage, genericSuccessMessage } from './util';
import * as documentsApiService from 'services/documentsApiService';
import * as addDocumentApiService from 'services/addDocumentApiService';
import * as clientActions from '../../actions/clients';
import { ContextExclusionPlugin } from 'webpack';
import setFileTitle from 'components/_helpers/setFileTitle';

function* fetchDocumentInformation(action, setToLoaded = true) {
    const { documentId, clientId } = action.payload;
    try {
        const response = yield call(documentsApiService.retrieveDocumentInformation, { documentId, clientId });
        yield put(docActions.receiveDocumentInformation(response));
        if (setToLoaded) {
            yield put(docActions.setStatusToLoaded());
        }
    } catch {
        yield put(clientActions.setIsUnauthorised({ unauthorised: true }));
    }
}

//for ongoing documents
// function* fetchDocumentDisplayURL(action, setToLoaded = true) {
//     const { documentId } = action.payload;
//     const body = [documentId];
//     try {
//         const response = yield call(documentsApiService.retrieveDocumentDisplayInformation, body);
//         yield put(docActions.setDocumentPreviewDetail(response));
//     } catch (e) {
//         yield e;
//     }
// }

//for single document
// function* fetchRequestedDocURL(action, setToLoaded = true) {
//     const { documentId } = action.payload;

//     const body = [documentId];
//     try {
//         const response = yield call(documentsApiService.retrieveSingleDocumentDisplayInformation, body);
//         yield put(docActions.setSingleDocumentPreviewDetail(response));
//     } catch (e) {
//         yield e;
//     }
// }

function* fetchDocumentAccessList(action, setToLoaded = true) {
    const { document, documentId } = action.payload;
    try {
        const result = yield call(documentsApiService.retrieveAccessList, documentId);
        const { data, totalAccessOutsideRegion } = result.data;
        yield put(docActions.receiveDocumentAccessList({ document, data, totalAccessOutsideRegion }));
        if (setToLoaded) {
            yield put(docActions.setStatusToLoaded());
        }
    } catch (e) {
        yield genericErrorMessage(e);
    }
}

function* fetchFullDocumentInformation(action) {
    yield fetchDocumentInformation(action, false);
    // yield fetchDocumentDisplayURL(action, false); //for documenbt preview
    // yield fetchRequestedDocURL(action, false);
    yield fetchDocumentAccessList(action, false);
    yield put(docActions.setStatusToLoaded());
}

export const scanUploadedDocument = async (newFiles) => {
    let maxApiCall = 4;
    let payload = [];

    for (let current of newFiles) {
        const result = await addDocumentApiService.scanDocument({ key: current.key });
        if (result !== 'error') {
            if (result.data.status === 'INPROGRESS') {
                for (let i = 0; i < maxApiCall; i++) {
                    const result = await new Promise(function (resolve) {
                        setTimeout(async function () {
                            let output = await addDocumentApiService.scanDocument({ key: current.key });
                            resolve(output);
                        }, 2000);
                    });
                    if (result?.data?.status !== 'INPROGRESS') {
                        payload.push({
                            status: result?.data ? result.data.status : 'API_ERROR',
                            key: current.key,
                            name: current.name
                        });
                        break;
                    } else if (i === maxApiCall - 1) {
                        payload.push({ status: 'TIMEOUT', key: current.key, name: current.name });
                    }
                }
            } else {
                payload.push({ status: result.data.status, key: current.key, name: current.name });
            }
        }
        else {
            payload.push({ status: 'API_ERROR', key: current.key, name: current.name });
        }
    }

    return payload;
};

function* editDocumentInformation(action) {
    const { documentId, newCategories, clientIdsToUpdate } = action.payload;
    try {
        yield call(documentsApiService.putDocumentAccess, documentId, { clientIdsToUpdate });
        yield call(documentsApiService.patchCategories, documentId, newCategories);
        yield put(docActions.documentEditCompleted());
    } catch (e) {
        yield put(docActions.documentEditFailed());
    }
}

function* uploadDocumentRevision(action) {
    const { documentId, documentRevisionRequest, clientId } = action.payload;
    const response = yield call(
        documentsApiService.createDocumentRevision,
        documentId,
        documentRevisionRequest,
        clientId
    );
    const { status } = response;
    if (status === 200) {
        yield fetchDocumentInformation({ payload: { documentId } });
        yield genericSuccessMessage('Request has been updated');
    } else {
        yield genericErrorMessage('Your request could not be updated due to an error.');
    }
}

function* handleGenerateUploadLinks(action) {
    try {
        const result = yield call(addDocumentApiService.retrieveUploadUrls, { numberOfUploads: action.payload.length });
        const files = action.payload;
        files.forEach((file, i) => {
            file.url = result[i].url;
        });
        const keys = result.map((link) => link.key);
        const newFiles = action.payload.map((value, i) => ({
            key: keys[i],
            name: value.name
        }));
        //yield put(docActions.receiveUploadLinks({ files, keys }));
        let success = true;
        let error = false;
        if (files) {
            const { documentsUploaded, documentsUploadFailed } = yield call(uploadRequestedDoc, files);
            success = documentsUploaded;
            error = documentsUploadFailed;
            if (success) {
                const scanRequestDocumentResponse = yield call(scanUploadedDocument, newFiles);
                const correctFileData = [];
                const correctFileWithKey = [];
                scanRequestDocumentResponse.forEach(val => {
                    if (val.status === 'SUCCESS') {
                        files.forEach(obj => {
                            if (obj.name === val.name) {
                                correctFileData.push(obj);
                                correctFileWithKey.push({
                                    key: val.key,
                                    filename: val.name,
                                    title: setFileTitle(val.name)
                                });
                            }
                        });
                    }
                }
                );
                const malwareData = scanRequestDocumentResponse.filter(obj => obj.status === 'FAILED');
                const apiErrorData = scanRequestDocumentResponse.filter(obj => obj.status === 'API_ERROR');
                const timeoutError = scanRequestDocumentResponse.filter(obj => obj.status === 'TIMEOUT');
                yield put(docActions.scannedDocumentsInfo({ correctFileData, correctFileWithKey, malwareData, apiErrorData, timeoutError }));
            } else if (error) {
                yield genericErrorMessage('Upload failed');
            }
        }
    } catch {
        yield genericErrorMessage('Upload failed');
    }
}

const uploadRequestedDoc = async (files) => {
    let payload = {};
    let failed = false;
    for (const file of files) {
        let blob = new Blob([file], { type: file.type });
        const result = await addDocumentApiService
            .uploadDocument(blob, file.url)
            .then((response) => 'totalprogress')
            .catch((error) => 'error');
        if (result !== 'error') {
            //setProgress(result, ((100/array.length) * j++));
        } else {
            failed = true;
        }
        payload['arrayLength'] = files.length;
    }
    payload['documentsUploadFailed'] = failed;
    payload['documentsUploaded'] = !failed;

    return payload;
};

export function* handleUploadRequestedDoc(action) {
    const {
        correctFileWithKey,
        documentData: { documentId },
        type,
        clientId,
    } = action.payload;
    let success = true;
    let error = false;

    // if (files) {
    //     const { documentsUploaded, documentsUploadFailed } = yield call(uploadRequestedDoc, files);
    //     success = documentsUploaded;
    //     error = documentsUploadFailed;
    // }

    if (success) {
        const response = yield call(documentsApiService.uploadDocumentToRequest, documentId, type, { files: correctFileWithKey, clientId } );

        if (response.status === 200) {
            yield fetchDocumentInformation({ payload: { documentId } });
            yield genericSuccessMessage('Uploaded successfully');
            const { clientIds, title } = action.payload.documentData;
            yield put(docActions.clearUploadLinks());
            // yield put(
            //     docActions.addAnalyticsEvent({
            //         eventName: 'requestedDocUploaded',
            //         data: {
            //             clientId: clientIds[0],
            //             documentId: documentId,
            //             documentTitle: title,
            //         },
            //     })
            // );
        } else {
            yield genericErrorMessage('Upload failed');
        }
    } else if (error) {
        yield genericErrorMessage('Upload failed');
    }
}

function* deleteUploadedFile(action) {
    const { documentId, documentFileId, clientId } = action.payload;
    try {
        yield call(documentsApiService.deleteUploadedFiles, documentId, [documentFileId], clientId);
        yield fetchDocumentInformation({ payload: { documentId, clientId } });
    } catch (error) {
        yield genericErrorMessage(error);
    }
}

export default function* clientApiSagas() {
    yield all([
        takeLatest(`${actions.fetchDocumentInformation}`, fetchDocumentInformation),
        // takeLatest(`${actions.fetchDocumentDisplayURL}`, fetchDocumentDisplayURL),  //for document preview
        // takeLatest(`${actions.fetchRequestedDocURL}`, fetchRequestedDocURL),
        takeLatest(`${actions.fetchFullDocumentInformation}`, fetchFullDocumentInformation),
        takeLatest(`${actions.uploadDocumentRevision}`, uploadDocumentRevision),
        takeLatest(`${actions.generateRequestUploadLinks}`, handleGenerateUploadLinks),
        takeLatest(`${actions.uploadRequestedDoc}`, handleUploadRequestedDoc),
        takeLatest(`${actions.fetchDocumentAccessList}`, fetchDocumentAccessList),
        takeEvery(`${actions.editDocumentInformation}`, editDocumentInformation),
        takeLatest(`${actions.deleteUploadedFile}`, deleteUploadedFile),
    ]);
}
