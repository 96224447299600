const BlueConicHelper = () => {
    const initialize = async () => new Promise((resolve, reject) => {
        setTimeout(() => {
            if (window.blueConicClient
                  && window.blueConicClient.event
                  && window.blueConicClient.event.subscribe) {
                // BlueConic is loaded, now we can do API things
                resolve();// resolve because it's initialized
            } else {
                reject(new Error('BC Timed out'));
            }
        }, 500); // wait for half a second
    });

    return {
        gaveGDPRConsent: async () => {
            try {
                await initialize();
                const bcclient = window.blueConicClient;
                return bcclient.profile.getProfile().getConsentedObjectives().includes('objective_03');
            } catch(error) {
                return { error: true, message: error.message };
            }
        },
        giveGDPRConsent: async () => {
            try {
                await initialize();
                const bcclient = window.blueConicClient;
                bcclient.profile.getProfile().addConsentedObjective('objective_03');
                bcclient.profile.updateProfile();
            } catch(error) {
                return { error: true, message: error.message };
            }
        },
        removeConsent: async (objective) => {
            try {
                await initialize();
                const bcclient = window.blueConicClient;
                bcclient.profile.getProfile().addRefusedObjective(objective);
                bcclient.profile.updateProfile();
            } catch(error) {
                return { error: true, message: error.message };
            }
        },
    };
};

export default BlueConicHelper;
