/* eslint-disable no-console */
import { all, put, call, takeLatest, takeEvery, race, take, delay } from 'redux-saga/effects';
import * as actions from './actions';
import {
    addToFundsData,
    receiveMyFunds,
    setFundsError,
    receiveFundDetails,
    receiveAltisFundDetails,
    receiveMyWatchlist,
    receiveAllFunds,
    receiveFundFilters,
    receiveWholesaleMasterlist,
    addToWatchlist,
    // addAnalyticsEvent,
    removeFromWatchlist,
} from '../../actions';
import * as fundsApiService from 'services/fundsApiService';
import { genericUndoMessage, genericErrorMessage, genericSuccessMessage } from './util';
import { undoAction } from '../common/actions';
//import mockData from '../../../data/allFundMockData.json'

function* fetchMyFunds(action) {
    const { clientId, params = { sortBy: 'assetClass', sortType: 'asc' }, myFundsIds = [] } = action.payload;

    if (myFundsIds.length) return;
    try {
        const {
            data: { data = [], location = null },
        } = yield call(fundsApiService.retrieveMyFunds, clientId, params);
        if (data.length) {
            // Merge my funds data with all funds data for normalising
            yield put(addToFundsData({ data }));
        }

        yield put(receiveMyFunds({ data, location }));
    } catch (error) {
        yield put(setFundsError({ fundType: 'myFunds', error }));
    }
}

function getSfdrInfo(response) {
    return response?.data;
}

function* fetchFundDetails(action) {
    const { clientId, fundIsIn } = action.payload;
    try {
        const { data } = yield call(fundsApiService.retrieveFundDetails, clientId, fundIsIn);
        const docResponse = yield call(fundsApiService.retrieveFundDocuments, fundIsIn, { clientId });
        const sfdrResponse = yield call(fundsApiService.retrieveSfdrData, fundIsIn, data.subFundUPI, clientId);
        data.sfdrInfo = getSfdrInfo(sfdrResponse);
        yield put(receiveFundDetails({ fund: data, fundDocuments: docResponse.data }));
    } catch (error) {
        yield genericErrorMessage(error);
    }
}

function* fetchAltisFundDetails(action) {
    const { clientId, fundIsIn } = action.payload;
    try {
        const { data } = yield call(fundsApiService.retrieveAltisFundDetails, clientId, fundIsIn);
        yield put(receiveAltisFundDetails({ fund: data }));
    } catch (error) {
        yield genericErrorMessage(error);
    }
}

function* fetchMyWatchlist(action) {
    const { clientId, params, myWatchlistIds = [] } = action.payload;
    if (myWatchlistIds.length <= 0) {
        try {
            const { data } = yield call(fundsApiService.retrieveMyWatchlist, clientId, params);
            if (data.length) {
                // Merge watchlist data with all funds data for normalising
                yield put(addToFundsData({ data }));
            }
            yield put(receiveMyWatchlist(data));
        } catch (error) {
            yield put(setFundsError({ fundType: 'myWatchlist', error }));
        }
    }
}

function* fetchAllFunds(action) {
    const { clientId, params } = action.payload;
    // debounce the call for 500ms
    yield delay(500);
    try {
        //const { data } = yield call(fundsApiService.retrieveAllFunds, clientId, params);
        //yield put(receiveAllFunds({ response: data, startFrom: params.startFrom }));
        // yield put(receiveFundFilters({ shareClass : mockData.shareClasses, assetClass: mockData.assetClasses }))
        // yield put(receiveAllFunds({ response: mockData, startFrom: params.startFrom }));
        const { data } = yield call(fundsApiService.retrieveAllFunds, clientId, params);
        yield put(receiveFundFilters({ shareClass : data.shareClasses, assetClass: data.assetClasses }))
        yield put(receiveAllFunds({ response: data, startFrom: params.startFrom }));
        
    } catch (error) {
        yield put(setFundsError({ fundType: 'allFunds', error }));
    }
}

// function* fetchFundFilters(action) {
//     const { clientId, filters } = action.payload;
//     const filtersEmpty = Object.values(filters).every((array) => !array.length);
//     if (filtersEmpty) {
//         try {
//             const { data } = yield call(fundsApiService.retrieveFundFilters, { clientId: clientId });
//             yield put(receiveFundFilters(data));
//         } catch (error) {
//             yield genericErrorMessage(error);
//         }
//     }
// }

function* addFundToWatchlist(action) {
    const { isin, clientId, eventSource } = action.payload;

    try {
        yield genericUndoMessage('Added to \'fund watchlist\'');
        yield put(addToWatchlist(isin));
        // yield put(addAnalyticsEvent({
        //     eventName: 'manageWatchlist',
        //     data: {
        //         isin,
        //         eventSource,
        //         categoryName: 'add'
        //     }
        // }));

        const { undo, add } = yield race({
            undo: take(undoAction),
            add: delay(5000),
        });

        if (undo) {
            // revert watchlist to previous state
            yield put(removeFromWatchlist(isin));
        } else if (add) {
            // make the API call to add the changes remotely
            const response = yield call(fundsApiService.addFundToWatchlist, isin, clientId);
        }
    } catch (error) {
        yield put(removeFromWatchlist(isin));
        yield genericErrorMessage(error);
    }
}

function* removeFundFromWatchlist(action) {
    const { isin, clientId, eventSource } = action.payload;

    try {
        yield genericUndoMessage('Removed from \'fund watchlist\'');
        yield put(removeFromWatchlist(isin));
        // yield put(
        //     addAnalyticsEvent({
        //         eventName: 'manageWatchlist',
        //         data: {
        //             isin,
        //             eventSource,
        //             categoryName: 'remove',
        //         },
        //     })
        // );
        const { undo, remove } = yield race({
            undo: take(undoAction),
            remove: delay(5000),
        });

        if (undo) {
            // revert watchlist to previous state
            yield put(addToWatchlist(isin));
        } else if (remove) {
            // make the API call to remove the changes remotely
            const response = yield call(fundsApiService.removeFundFromWatchlist, isin, clientId);
        }
    } catch (error) {
        yield put(addToWatchlist(isin));
        yield genericErrorMessage(error);
    }
}

function* grantMyFundsAccess(action) {
    const { clientId, isinsToUpdate } = action.payload;
    try {
        yield call(fundsApiService.putMyFundsAccess, clientId, { isinsToUpdate });
        yield fetchMyFunds(action);
        yield genericSuccessMessage('My Funds are successfully updated');
    } catch (e) {
        yield genericErrorMessage(e);
    }
}

function* fetchWholesaleMasterlist(action) {
    const { clientId, fundIsIn } = action.payload;
    try {
        const { data } = yield call(fundsApiService.retrieveWholesaleMasterlist, clientId, fundIsIn);
        yield put(receiveWholesaleMasterlist(data));
    } catch (error) {
        yield genericErrorMessage(error);
    }
}

export default function* fundsApiSagas() {
    yield all([
        takeLatest(`${actions.fetchMyFunds}`, fetchMyFunds),
        takeLatest(`${actions.fetchMyWatchlist}`, fetchMyWatchlist),
        takeLatest(`${actions.fetchAllFunds}`, fetchAllFunds),
        takeEvery(`${actions.addFundToWatchlist}`, addFundToWatchlist),
        takeEvery(`${actions.removeFundFromWatchlist}`, removeFundFromWatchlist),
        takeLatest(`${actions.grantMyFundsAccess}`, grantMyFundsAccess),
        takeLatest(`${actions.fetchFundDetails}`, fetchFundDetails),
        takeLatest(`${actions.fetchAltisFundDetails}`, fetchAltisFundDetails),
        //takeLatest(`${actions.fetchFundFilters}`, fetchFundFilters),
        takeLatest(`${actions.fetchWholesaleMasterlist}`, fetchWholesaleMasterlist),
    ]);
}
