import { createReducer } from 'redux-act';
import states from 'data/reduxstates';

import { fetchPreferenceData } from '../sagas/api/actions';
import { setPreferenceData } from '@/redux/actions/index';

const preferencesReducer = createReducer(
    {
        [fetchPreferenceData]: (state) => ({ ...state, loadingStatus: 'loading' }),
        [setPreferenceData]: (state, payload) => {
            const orderOfFilters = ['region', 'responsible investing', 'products', 'topic'];
            const sortFilters = (unsorted) => {
                const sorted = unsorted.sort(
                    (a, b) =>
                        orderOfFilters.indexOf(a.categoryName.toLowerCase()) -
                        orderOfFilters.indexOf(b.categoryName.toLowerCase())
                );
                return sorted;
            };

            return {
                ...state,
                preferenceCategories: sortFilters(payload.categories),
                userPreferences: payload.userPreferences || [],
                loadingStatus: 'loaded',
            };
        },
    },
    states.preferences
);

export default preferencesReducer;
