/* eslint-disable no-unused-vars */

// Base
import React from 'react';
import { Link } from 'react-router-dom';

// Style
import style from 'assets/stylesheets/atoms/Links/Default';

export default class LinkDefault extends React.Component {
    render() {
        const { to, children, disabled, customClasses } = this.props;

        let linkBack = this.props.back ? true : false,
            linkClick = this.props.click ? this.props.click : false;

        return !linkClick ? (
            <Link
                to={to}
                className={`link ${style.link} ${style.link__default}${disabled ? ' ' + style['link--disabled'] : ''}${
                    customClasses ? ' ' + customClasses : ''
                }`}>
                <span className={style.link__default__label}>{children}</span>
            </Link>
        ) : (
            <button
                onClick={linkClick}
                className={`link csp-reseted-button ${style.link} ${style.link__default}${
                    disabled ? ' ' + style['link--disabled'] : ''
                }${customClasses ? ' ' + customClasses : ''}`}>
                <span className={style.link__default__label}>{children}</span>
            </button>
        );
    }
}
