import React from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';

import style from 'assets/stylesheets/atoms/Stack/Stack';

/**
 * Stack layout primitive component used for stacking its children using flexbox. Parameters decide on the final flex layout.
 */
const Stack = ({
    direction,
    justify,
    alignItems,
    nowrap,
    noTopMargin,
    mediumTopMargin,
    spacing,
    children,
    customClasses,
}) => {
    const stackClasses = classNames(
        'stack',
        style.stack,
        style[`stack--${direction}`],
        style[`stack--justify-${justify}`],
        style[`stack--align-${alignItems}`],
        customClasses,
        {
            [style['stack--nowrap']]: nowrap,
            [style['stack--notopmargin']]: noTopMargin,
            [style['stack--topmargin-m']]: mediumTopMargin,
        }
    );

    return <div className={stackClasses}>{children}</div>;
};

const { oneOf, node, bool } = PropTypes;

Stack.propTypes = {
    direction: oneOf(['row', 'column']),
    justify: oneOf(['flex-start', 'center', 'flex-end', 'space-between', 'space-around', 'space-evenly']),
    alignItems: oneOf(['flex-start', 'center', 'flex-end']),
    nowrap: bool,
    noTopMargin: bool,
    mediumTopMargin: bool,
    children: node,
};

Stack.defaultProps = {
    direction: 'row',
    justify: 'space-between',
    alignItems: 'center',
    nowrap: false,
    noTopMargin: false,
    mediumTopMargin: false,
    children: node,
};

export default Stack;
