import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import NotificationsWrapperAtom from 'components/atoms/Notifications/NotificationsWrapper';
import NotificationAtom from 'components/atoms/Notifications/Notification';

const OutdatedBrowser = () => {
    const { t } = useTranslation();
    const [isVisible, setIsVisible] = useState(true);
    const handleClose = () => {
        window.sessionStorage.removeItem('isIE11', 'true');
        setIsVisible(false);
    };

    return (
        <NotificationsWrapperAtom>
            <NotificationAtom type="warning" showCloseIcon handleClose={handleClose} remove={!isVisible}>
                {t('csp.outdatedBrowser')}
            </NotificationAtom>
        </NotificationsWrapperAtom>
    );
};

export default OutdatedBrowser;
